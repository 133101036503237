import {Component} from "react";
import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link"
import Divider from "@material-ui/core/es/Divider/Divider";
import Messages from "./messages/Messages";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import FacebookIcon from "./icons/FacebookIcon";

const styles = theme => ({
    card: {
        minWidth: 320,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    actions: {
        marginLeft: theme.spacing(),
        marginRight: theme.spacing(),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    facebookIcon: {
        marginRight: theme.spacing(),
    },
    button: {
        width: '100%',
    }

});


class RegisterBox extends Component {
    state = {
        email: '',
        password: '',
        passwordRepeat: '',
        error: this.props.error,
        passwordError: false,
        errorMessage: this.props.errorMessage,
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
        this.resetErrors();
    }

    resetErrors() {
        this.setState({
            error: false,
            passwordError: false,
        });
    }

    handleSubmit(event) {
        if (this.state.password !== this.state.passwordRepeat) {
            this.setState({
                passwordError: true,
                errorMessage: "Parola introdusa este diferita!"
            });
        }
        else {
            this.props.userRegister(this.state.email, this.state.password);
        }
    }

    responseFacebook = (response) => {
        this.props.facebookLogin(response);
    };

    handleErrorClose = () => {
        this.props.dismissMessages();
    };

    handleSwitchView = () => {
        this.props.switchView('login');
        this.resetErrors();
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({error: nextProps.error, errorMessage: nextProps.errorMessage});
    }

    render() {
        const {classes} = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <FacebookLogin
                        appId="2412884222079741"
                        fields="name,email,picture,birthday"
                        callback={this.responseFacebook}
                        disableMobileRedirect={true}
                        render={renderProps => (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={renderProps.onClick}
                                className={classes.button}>
                                <FacebookIcon className={classes.facebookIcon} />
                                Înregistrare cu Facebook
                            </Button>
                        )}
                    />
                    <Divider variant="middle" className={classes.divider} />
                    <Typography variant="h6" color="textPrimary" gutterBottom>
                        Înregistrare cu email
                    </Typography>
                    <TextField
                        id="email-input"
                        label="Email"
                        type="email"
                        name="email"
                        error={this.state.error}
                        autoComplete="email"
                        margin="dense"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                    <TextField
                        id="password-input"
                        label="Parola"
                        name="password"
                        error={this.state.error || this.state.passwordError}
                        type="password"
                        autoComplete="create-password"
                        margin="dense"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                    <TextField
                        id="password-repeat"
                        label="Repetă Parola"
                        name="passwordRepeat"
                        error={this.state.error || this.state.passwordError}
                        type="password"
                        autoComplete="repeat-password"
                        margin="dense"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button variant="contained" className={classes.button} size="small" color="primary" onClick={this.handleSubmit.bind(this)}>Înregistrare</Button>
                </CardActions>
                <Divider/>
                <CardActions className={classes.actions}>
                    <Typography>Ai deja cont? </Typography>
                    <Link component="button"  onClick={this.handleSwitchView}>Înapoi la Login</Link>
                </CardActions>
                <Messages
                    error={this.state.error || this.state.passwordError}
                    variant="error"
                    message={this.state.errorMessage}
                    onClose={this.handleErrorClose}
                />
            </Card>
        );
    }
}

RegisterBox.propTypes = {
    classes: PropTypes.object.isRequired,
    userRegister: PropTypes.func.isRequired,
    switchView: PropTypes.func.isRequired,
    dismissMessages: PropTypes.func.isRequired,
};

export default withStyles(styles)(RegisterBox);