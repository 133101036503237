import * as types from './actionTypes';
import StatisticsService from '../../services/statistics'
import TestsService from '../../services/tests'
import {STATISTICS_UNAVAILABLE} from "../../components/Constants";


export function getStatistics() {
    return async(dispatch, getState) => {
        // dispatch loading start
        let token = getState().authentication.token;
        let data
        if (STATISTICS_UNAVAILABLE) {
            data = [];
        } else {
            data = await StatisticsService.getStatistics(token);
        }
        if (data.error) {
            dispatch({type: types.STATISTICS_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.STATISTICS_LOAD_SUCCESS, statisticsData: data});
        }
    }
}

export function loadTermsData() {
    return async(dispatch, getState) => {
        let token = getState().authentication.token;
        let data = await TestsService.testsTerms(token);
        if (data.error) {
            dispatch({type: types.TERMS_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.TERMS_LOAD_SUCCESS, termsData: data});
        }
    }
}