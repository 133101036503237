import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withStyles } from "@material-ui/core";
import * as bugsActions from "../store/bugs/actions";
import * as testsActions from "../store/tests/actions";
import * as bugsSelectors from "../store/bugs/reducer";
import * as authenticationSelectors from "../store/authentication/reducer";
import Messages from "../components/messages/Messages";
import BugItem from "../components/BugItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import ReportBugForm from "./dialogs/ReportBugForm";
import Helmet from "react-helmet";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(),
    },
});

class Bugs extends Component {

    state = {
        testResultsOpen: false,
        premiumWarning: false,
        existingFreeTestDialogOpen: false,
    };

    handleMessageClose = () => {
        this.props.dispatch(testsActions.dismissMessages());
    };

    componentDidMount() {
        this.props.dispatch(bugsActions.getUserBugs(this.props.token));
    }

    render() {
        const { classes, error, errorMessage, messageType, userBugs } = this.props;
        let itemNo = userBugs.length;
        return(
            <div className={classes.root}>
                <Helmet>
                    <title>Probleme - Grile Politie</title>
                </Helmet>
                {userBugs.length === 0 &&
                    <Paper className={classes.paper}>
                        <Typography variant="h6">
                            Nu ai raportat niciun bug până acum.
                        </Typography>
                        <Typography variant="body1">
                            Poţi raporta o problemă în timpul unui test, apăsând pe iconita de bug din dreptul întrebării.
                        </Typography>
                    </Paper>
                }
                {userBugs.map((bug) => {
                    return (<BugItem key={bug.nid} itemNo={itemNo--} bugInfo={bug}/>);
                })}
                <ReportBugForm/>
                <Messages
                    error={error}
                    variant={messageType}
                    message={errorMessage}
                    onClose={this.handleMessageClose}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const [error, errorMessage, messageType]  = bugsSelectors.getError(state);
    return {
        userBugs: bugsSelectors.getUserBugs(state),
        token: authenticationSelectors.getAccessToken(state),
        error: error,
        errorMessage: errorMessage,
        messageType: messageType,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Bugs));