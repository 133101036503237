import * as types from './actionTypes';
import SettingsService from '../../services/settings'

export function setDarkTheme(darkTheme) {
    return async (dispatch, getState) => {
        dispatch({type: types.DARK_THEME_CHANGE, darkTheme: darkTheme});
    }
}

export function updateWindowSize(width, height) {
    return async (dispatch, getState) => {
        dispatch({type: types.UPDATE_WINDOW_SIZE, width: width, height: height})
    }
}

export function getAppSettings() {
    return async (dispatch, getState) => {
        let token = getState().authentication.token;
        let data = await SettingsService.getAppSettings(token);
        if (data.error) {
            dispatch({type: types.SETTINGS_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.SETTINGS_LOAD_SUCCESS, settings: data});
        }
    }
}

export function selectExam(selectedExam) {
    return async (dispatch, getState) => {
        dispatch({type: types.SELECT_EXAM, selectedExam: selectedExam});
    }
}