import React from 'react';
import {withStyles} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

const styles = theme => ({
    formControl: {
        margin: theme.spacing(1),
        marginTop: -12,
        minWidth: 120,
    },
});

class ChartFilter extends React.Component {
    state = {
        subject: '',
        chapter: '',
    };

    getSubjects() {
        const {statistics, termsData} = this.props;
        return Object.keys(statistics.user_statistics.subjects).map(key => {
            if (key !== '') {
                return <MenuItem key={key} value={key}>{termsData[key]}</MenuItem>
            }
            else return undefined;
        })
    }

    getChapters() {
        const {statistics, termsData} = this.props;
        return Object.keys(statistics.user_statistics.subjects[this.state.subject].chapters).map(key => {
            if (key !== '') {
                return <MenuItem key={key} value={key}>{termsData[key]}</MenuItem>
            }
            else return undefined;
        })
    }

    handleChange = (event, value) => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'subject') {
            this.setState({
                chapter: '',
            });
        }
        this.setState({}, () => this.updateParent())
    }

    updateParent() {
        this.props.handleChange(this.state);
    }

    render() {
        const {classes} = this.props;
        this.getSubjects();
        return (
            <>
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Materie</InputLabel>
                    <Select
                        value={this.state.subject}
                        onChange={this.handleChange}
                        label="Materie"
                        inputProps={{
                            name: 'subject',
                            id: 'subject',
                        }}
                    >
                        <MenuItem value="">
                            <em>Toate</em>
                        </MenuItem>
                        {this.getSubjects()}
                    </Select>
                </FormControl>
                {this.state.subject !== '' &&
                <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Capitol</InputLabel>
                    <Select
                        value={this.state.chapter}
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'chapter',
                            id: 'chapter',
                        }}
                        label="Capitol"
                    >
                        <MenuItem value="">
                            <em>Toate</em>
                        </MenuItem>
                        {this.getChapters()}
                    </Select>
                </FormControl>
                }
            </>
        );
    }
}

export default withStyles(styles)(ChartFilter);