import {Component} from "react";
import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Messages from "./messages/Messages";
import Link from "@material-ui/core/Link"
import Divider from "@material-ui/core/es/Divider/Divider";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import FacebookIcon from "./icons/FacebookIcon";

const styles = theme => ({
    card: {
        minWidth: 275,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    actions: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        // width: 200,
    },
    button: {
        // margin: theme.spacing.unit,
        width: '100%',
    },
    facebookIcon: {
        marginRight: theme.spacing(1),
    },
});


class LoginBox extends Component {
    state = {
        email: '',
        password: '',
        error: this.props.error,
    };

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
        this.resetErrors();
    }

    resetErrors() {
        this.setState({
            error: false,
        });
    }

    handleSubmit(event) {
        this.props.userLogin(this.state.email, this.state.password);
    }


    handleSwitchView = (event) => {
        this.props.switchView('register');
    };

    responseFacebook = (response) => {
        this.props.facebookLogin(response);
    };

    handleErrorClose = () => {
        this.props.dismissMessages();
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({error: nextProps.error})
    }

    render() {
        const {classes} = this.props;
        return (
            <Card className={classes.card}>
                <CardContent>
                    <FacebookLogin
                        appId="2412884222079741"
                        fields="name,email,picture,birthday"
                        callback={this.responseFacebook}
                        disableMobileRedirect={true}
                        render={renderProps => (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={renderProps.onClick}
                                className={this.props.classes.button}>
                                <FacebookIcon className={classes.facebookIcon} />
                                Login cu Facebook
                            </Button>
                        )}
                    />
                    <Divider variant="middle" className={classes.divider} />
                    <Typography color="textPrimary" gutterBottom variant="h6">
                        Login cu email
                    </Typography>
                    <TextField
                        id="email-input"
                        label="Email"
                        className={classes.textField}
                        type="email"
                        name="email"
                        error={this.state.error}
                        autoComplete="email"
                        margin="dense"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                    <TextField
                        id="password-input"
                        label="Parola"
                        name="password"
                        error={this.state.error}
                        className={classes.textField}
                        type="password"
                        autoComplete="current-password"
                        margin="dense"
                        fullWidth
                        onChange={this.handleInputChange.bind(this)}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    <Button className={classes.button} variant="contained" size="small" color="primary" onClick={this.handleSubmit.bind(this)}>Login</Button>
                </CardActions>
                <Divider variant="middle" />
                <CardActions className={classes.actions}>
                    <Typography>Nu ai cont? </Typography>
                    <Link component="button" onClick={this.handleSwitchView}>Înregistrare</Link>
                </CardActions>
                <CardActions className={classes.actions}>
                    <Typography>Ai uitat parola? </Typography>
                    <Link href={process.env.REACT_APP_API_URL + '/user/password'}>Recuperează</Link>
                </CardActions>

                <Messages
                    variant="error"
                    message={this.props.errorMessage}
                    onClose={this.handleErrorClose}
                    error={this.state.error}
                />
            </Card>
        );
    }
}

LoginBox.propTypes = {
    classes: PropTypes.object.isRequired,
    userLogin: PropTypes.func.isRequired,
    switchView: PropTypes.func.isRequired,
    dismissMessages: PropTypes.func.isRequired,
};

export default withStyles(styles)(LoginBox);