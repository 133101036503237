import React, {Component} from 'react';
import {
    Card,
    Grid,
    CardContent,
    CardMedia,
    Typography,
    withStyles, FormControlLabel, Divider, Checkbox, TextField
} from "@material-ui/core";
import PropTypes from "prop-types";
import settings from '../images/settings.jpg'
import Button from "@material-ui/core/Button";
import LinkButton from "./linkComponents/LinkButton";
import {EXAMS} from "./Constants";

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    card: {
        height: '100%',
        color: theme.palette.text.secondary,
    },
    button: {
        marginTop: theme.spacing(),
    },
    control: {
        padding: theme.spacing(2),
    },
    media: {
        height: 0,
        paddingTop: '22%',
    },
    actions: {
        display: 'flex',
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    checkbox: {
        width: '100%',
    }
});


class SettingsBox extends Component {

    state = {
        fields: {
            firstName: '',
            lastName: '',
            mail: '',
        },
    };

    componentDidMount() {
        if (this.props.userData.mail !== undefined) {
            this.setState({
                fields: {
                    firstName: this.props.userData.firstName,
                    lastName: this.props.userData.lastName,
                    mail: this.props.userData.mail,
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userData.mail === undefined && this.props.userData.mail !== undefined) {
            this.setState({
                fields: {
                    firstName: this.props.userData.firstName,
                    lastName: this.props.userData.lastName,
                    mail: this.props.userData.mail,
                }
            });
        }
    }

    handleChange = event => {
        this.setState({fields: {...this.state.fields, [event.target.name]: event.target.value}});
    };

    updateUser = (e) => {
        e.preventDefault();
        this.props.updateUser(this.state.fields);
    };

    render() {
        const {classes, userData} = this.props;
        const emailDisabled = (userData.facebookId !== null);
        const helperText = (userData.facebookId !== null) ? "Nu poţi edita adresa de mail, pentru că te-ai logat cu facebook!" : false;

        let expirationDates = {};
        if (userData.subscriptionExpiration !== undefined) {
            Object.keys(userData.subscriptionExpiration).map(function(key) {
                expirationDates[key] = userData.subscriptionExpiration[key] ? new Intl.DateTimeFormat('ro', {}).format(new Date(userData.subscriptionExpiration[key] * 1000)): false;
                return false;
            });
        }
        return(
            <Grid item>
                <Card
                    className={classes.card}
                >
                    <CardMedia
                        className={classes.media}
                        image={settings}
                        title="Settings"
                    />
                    <CardContent>
                        <Typography variant="body1" gutterBottom>
                            Afisaj
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.darkMode}
                                    onChange={this.props.toggleDarkMode}
                                    color="primary"
                                />
                            }
                            label="Mod întunecat (Dark mode)"
                        />
                        <Divider className={classes.divider}/>
                        <Typography variant="body1" gutterBottom>
                            Cont
                        </Typography>
                        { userData.mail !== undefined &&
                        <form noValidate autoComplete="off" onSubmit={this.updateUser}>
                            <TextField id="firstName" name="firstName" label="Prenume" value={this.state.fields.firstName} onChange={this.handleChange} fullWidth margin="normal"/>
                            <TextField id="lastName" name="lastName" label="Nume" value={this.state.fields.lastName} onChange={this.handleChange} fullWidth margin="normal"/>
                            <TextField id="mail" name="mail" label="Mail" helperText={helperText} value={this.state.fields.mail} onChange={this.handleChange} disabled={emailDisabled} fullWidth margin="normal"/>
                            <Button color="primary" variant="contained" type="submit">Salvează</Button>
                        </form>
                        }

                        <Divider className={classes.divider}/>
                        <Typography variant="body1" gutterBottom>
                            Premium
                        </Typography>
                        {userData.mail !== undefined &&
                            <>
                                {
                                    Object.keys(expirationDates).map(function(key) {
                                    return(
                                        <FormControlLabel key={key} className={classes.checkbox}
                                            control={
                                                <Checkbox
                                                    checked={expirationDates[key] !== false}
                                                    color="primary"
                                                    disabled={true}
                                                />
                                            }
                                            label={EXAMS[key] + ': ' + (expirationDates[key] ? "Premium până în data de " + expirationDates[key] : "Nu ai abonament premium")}
                                        />
                                    );
                                    })
                                }
                                {userData.subscription === 'free' &&
                                    <LinkButton className={classes.button} variant="contained" to="/products">Fii Premium</LinkButton>
                                }
                                {userData.subscription === 'pro' &&
                                <LinkButton className={classes.button} variant="contained" to="/products">Prelungeşte</LinkButton>
                                }
                                <br />
                                <LinkButton color="primary" className={classes.button} variant="contained" to="/settings/orders">Vezi comenzile tale</LinkButton>
                            </>
                        }
                    </CardContent>
                </Card>
            </Grid>
        );
    }
}

SettingsBox.propTypes = {
    classes: PropTypes.object.isRequired,
    darkMode: PropTypes.bool.isRequired,
};

export default (withStyles(styles)(SettingsBox));