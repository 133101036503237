import React from 'react';
import {Bar} from 'react-chartjs-2';

const options = {
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0,
                suggestedMax: 100,
            }
        }],
        xAxes: [{
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                    return index + 1;
                }
            }
        }],
    }
};

class TotalChart extends React.Component {

    dateLabel(i) {
        const month = this.props.month < 10 ? "0" + this.props.month : this.props.month;
        const day = i < 10 ? "0" + i : i;
        return day + "/" + month + "/" + this.props.year;
    }

    render() {
        const data = {
            labels: [ ...Array(this.props.generalData.length).keys() ].map( i => this.dateLabel(i + 1)),
            datasets: [
                {
                    label: 'Media Generală',
                    type: 'line',
                    fill: false,
                    backgroundColor: '#4B76C0',
                    borderColor: '#4B76C0',
                    hoverBackgroundColor: '#4B76C0',
                    hoverBorderColor: '#4B76C0',
                    data: this.props.generalData,
                    pointRadius: 2,
                    lineTension: 0.1,
                    pointHitRadius: 10,
                },
                {
                    label: 'Media ta',
                    type: 'line',
                    fill: false,
                    backgroundColor: '#4BC0C0',
                    borderColor: '#4bc0c0',
                    hoverBackgroundColor: '#4BC0C0',
                    hoverBorderColor: '#4BC0C0',
                    data: this.props.userData,
                    pointRadius: 2,
                    lineTension: 0.1,
                    pointHitRadius: 10,
                },
            ]
        };
        return (
            <Bar
                data={data}
                options={options}
            />
        );
    }
}

export default TotalChart;