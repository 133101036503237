import React from 'react';
import {connect} from "react-redux";
import {Box, Step, StepLabel, Stepper, TextField, withStyles} from "@material-ui/core";
import * as authenticationSelectors from "../store/authentication/reducer";
import * as reviewsSelectors from "../store/reviews/reducer";
import * as reviewsActions from "../store/reviews/actions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import Rating from '@material-ui/lab/Rating';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import RateReviewIcon from '@material-ui/icons/RateReview';
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

const styles = theme => ({
    root: {
        display: 'flex',
    },
    cardStepper: {
        width: '100%'
    },
    card: {
        maxWidth: 600,
        marginTop: theme.spacing(),
    },
    rating: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
});

class Review extends React.Component {

    state = {
        step: 0,
        rating: 0,
        firstName: this.props.userData.firstName,
        lastName: this.props.userData.lastName,
        review: '',
        touchedFields: {
            firstName: false,
            lastName: false,
            review: false,
        },
    }

    componentDidMount() {
        this.props.dispatch(reviewsActions.getUserReviews());
    }

    nextStep = () => {
        this.setState({
            step: this.props.userReviews.length > 0 ? 2 : this.state.step + 1,
        });
    }

    previousStep = () => {
        this.setState({
            step: 0,
        });
    }

    submit = () => {
        this.setState({
            step: 2,
        });
        let data = {
            rating: this.state.rating,
            reviewText: this.state.review,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        };
        this.props.dispatch(reviewsActions.submitReview(JSON.stringify(data)));
    }

    handleChange = (event, value) => {
        value = event.target.name === 'rating' ? value : event.target.value;
        this.setState({
            [event.target.name]: value,
        });
    }

    touchField = (event, value) => {
        let touchedFields = Object.assign({}, this.state.touchedFields);
        touchedFields[event.target.name] = true;
        this.setState({
            touchedFields: touchedFields,
        })
    }

    hasError = fieldName => {
        if (!this.state.touchedFields[fieldName]) {
            return false;
        }
        if (this.state[fieldName] === '') {
            return true;
        }
        if (fieldName === 'review' && this.state[fieldName].length < 50) {
            return true;
        }
        return false;
    };

    canSubmit = () => {
        if (this.state.firstName !== '' && this.state.lastName !== '' && this.state.review.length >= 50 && this.state.rating > 0) {
            return true;
        }
        return false;
    }

    render() {
        const {classes} = this.props;
        return (
            <>
                <Card className={classes.cardStepper}>
                    <CardContent>
                        <Stepper activeStep={this.state.step} alternativeLabel>
                            <Step>
                                <StepLabel>Follow us</StepLabel>
                            </Step>
                            <Step>
                                <StepLabel>Review</StepLabel>
                            </Step>
                        </Stepper>
                    </CardContent>
                </Card>
                <Card className={classes.card}>
                    <CardContent>
                        {this.state.step === 0 &&
                            <>
                                <Typography>Poţi obţine o lună de cont <strong>premium gratuit</strong>, urmând aceşti paşi simpli:</Typography>
                                <List>
                                    <ListItem button onClick={() => {window.open("https://facebook.com/grileexamenero")}}>
                                        <ListItemText
                                            primary="1. Like pe Facebook"
                                            secondary="Dă un like paginii noastre de Facebook"
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end" onClick={() => {window.open("https://facebook.com/grileexamenero")}}>
                                                <FacebookIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={() => {window.open("https://instagram.com/grile_examene")}}>
                                        <ListItemText
                                            primary="2. Follow pe Instagram"
                                            secondary="Dă-ne follow paginii de Instagram"
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton edge="end"  onClick={() => {window.open("https://instagram.com/grile_examene")}}>
                                                <InstagramIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={this.nextStep}>
                                        <ListItemText
                                            primary="3. Review în aplicaţie"
                                            secondary="Acordă o evaluare în pasul următor."
                                        />
                                        <ListItemSecondaryAction>
                                            <RateReviewIcon />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </>
                        }
                        {this.state.step === 1 &&
                            <>
                                <div className={classes.rating}>
                                    <Rating name="rating" value={this.state.rating} precision={0.5} onChange={this.handleChange} />
                                    <Box ml={2}>{this.state.rating > 0 ? "Ratingul tău: " + this.state.rating : "Nu ai dat rating"}</Box>
                                </div>
                                <TextField id="firstName" name="firstName" label="Prenume" value={this.state.firstName} onChange={this.handleChange} onBlur={this.touchField} required fullWidth margin="normal" error={this.hasError('firstName')} />
                                <TextField id="lastName" name="lastName" label="Nume" value={this.state.lastName} onChange={this.handleChange} onBlur={this.touchField} required fullWidth margin="normal" error={this.hasError('lastName')} />
                                <TextField id="review" name="review" label="Review" helperText="Review-ul tău trebuie să conţină cel puţin 50 de caractere." value={this.state.review} onChange={this.handleChange} onBlur={this.touchField} required multiline rowsMax={4} fullWidth margin="normal" error={this.hasError('review')} />
                            </>
                        }
                        {this.state.step === 2 &&
                            <Typography>Mulţumim. Evaluarea ta a fost salvată în sistem.</Typography>
                        }
                        </CardContent>
                    <CardActions>
                        {this.state.step === 0 && <Button onClick={this.nextStep}>Următorul pas</Button>}
                        {this.state.step === 1 && <Button onClick={this.previousStep}>Înapoi</Button>}
                        {this.state.step === 1 && <Button disabled={!this.canSubmit()} onClick={this.submit}>Trimite</Button>}
                        {this.state.step === 2 && <Button onClick={() => {window.location.href="/"}}>Mergi la pagina principală</Button>}
                    </CardActions>
                </Card>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        userData: authenticationSelectors.getUserData(state),
        userReviews: reviewsSelectors.getUserReviews(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Review));