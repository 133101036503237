import * as types from './actionTypes';
import Immutable from 'seamless-immutable';
import encryption from '../../helpers/encryption';

function decryptIfEncrypted(data) {
    try {
        return JSON.parse(data);
    } catch (e) {
        let decrypted = encryption.decrypt(data, 'n0T53cUr3');
        return JSON.parse(decrypted);
    }
}

function encryptData(data) {
    let string = JSON.stringify(data);
    return encryption.encrypt(string, 'n0T53cUr3');
}

const initialState = Immutable({
    freeTest: [],
    test: localStorage.getItem('currentTest') ? decryptIfEncrypted(localStorage.getItem('currentTest')) : [],
    testInProgress: localStorage.getItem('testInProgress') ? JSON.parse(localStorage.getItem('testInProgress')) : {empty: true},
    error: false,
    errorMessage: '',
    loading: false,
    testsLoading: false,
    messageType: 'error',
    testName: undefined,
    nextTestId: 0,
    testsTerms: localStorage.getItem('testsTerms') ? JSON.parse(localStorage.getItem('testsTerms')) : [],
    testsTermsStructure: localStorage.getItem('testsTermsStructure') ? JSON.parse(localStorage.getItem('testsTermsStructure')) : [],
    testsTermsChaptersStructure: localStorage.getItem('testsTermsChaptersStructure') ? JSON.parse(localStorage.getItem('testsTermsChaptersStructure')) : [],
    testsTermsYearsStructure: localStorage.getItem('testsTermsYearsStructure') ? JSON.parse(localStorage.getItem('testsTermsYearsStructure')) : [],
    questionResponses: localStorage.getItem('questionResponses') ? JSON.parse(localStorage.getItem('questionResponses')) : [],
    userTests: localStorage.getItem('userTests') ? JSON.parse(localStorage.getItem('userTests')) : [],
    testsQuestions: localStorage.getItem('testsQuestions') ? JSON.parse(localStorage.getItem('testsQuestions')) : [],
    lastTestParams: localStorage.getItem('lastTestParams') ? JSON.parse(localStorage.getItem('lastTestParams')) : false,
    totalQuestionsForCurrentTest: 10,
    currentTestAnswers: [],
});


export default function reduce(state = initialState, action = {}) {
    let error = '';
    let currentResponses = [];
    let userTests = [];
    let currentTest;
    let testInProgress;
    let questionResponses;

    switch (action.type) {
        case types.FREE_TEST_LOAD_SUCCESS:
            localStorage.setItem('freeTest', JSON.stringify(action.freeTest));
            return state.merge({freeTest: action.freeTest, error: false, loading: false});

        case types.PAID_TEST_LOAD_SUCCESS:
            localStorage.setItem('currentTest', encryptData(action.test));
            return state.merge({
                test: action.test,
                error: false,
                loading: false,
                testInProgress: {empty: true},
            });

        case types.PAID_TEST_LOAD_FAILURE:
        case types.TESTS_TERMS_LOAD_FAILURE:
        case types.TESTS_TERMS_STRUCTURE_LOAD_FAILURE:
        case types.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_FAILURE:
        case types.TESTS_TERMS_YEARS_STRUCTURE_LOAD_FAILURE:
        case types.FREE_TEST_LOAD_FAILURE:
        case types.USER_TESTS_ADD_FAILURE:
        case types.USER_TESTS_LOAD_FAILURE:
        case types.USER_TEST_QUESTIONS_LOAD_FAILURE:
        case types.USER_TESTS_DELETE_FAILURE:
            error = action.errorMessage;
            console.error(error.message);
            return state.merge({
                error: true,
                errorMessage: error.message,
                loading: false,
            });

        case types.DISMISS_MESSAGES:
            return state.merge({
                error: false,
            });

        case types.SET_MESSAGE:
            return state.merge({
                error: true,
                errorMessage: action.errorMessage,
                messageType: action.messageType,

            });

        case types.LOADING_START:
            return state.merge({
                loading: true,
            });
        case types.LOADING_STOP:
            return state.merge({
                loading: false,
            });
        case types.USER_TESTS_LOADING_START:
            return state.merge({
                testsLoading: true,
            });
        case types.USER_TESTS_LOADING_STOP:
            return state.merge({
                testsLoading: false,
            });
        case types.FINISH_TEST:
            // No question was answered, so we remove the test.
            if (state.testInProgress.questionResponses.length === 0) {
                localStorage.removeItem('testInProgress');
                localStorage.removeItem('lastTestParams');
                return state.merge({
                    testInProgress: {empty: true},
                    error: true,
                    errorMessage: 'Testul a fost eliminat.',
                    messageType: 'warning',
                });
            }
            // There were some answered questions, so we need to save the test.
            userTests = Object.assign({}, state.userTests);
            currentTest = Object.assign({}, state.testInProgress);
            currentTest['finished'] = true;
            currentTest['finishTimestamp'] = Date.now();

            // If the test was with timing, add the unanswered questions at the end.
            if (state.testInProgress.configurationOptions.timing) {
                questionResponses = Object.assign({}, currentTest.questionResponses);
                let questions = Object.assign([], currentTest.questions);
                state.test.map((question) => {
                    if (currentTest.questions.indexOf(question.nid) === -1) {
                        questionResponses[question.nid] = {
                            nid: question.nid,
                            responses: {
                                A: false,
                                B: false,
                                C: false,
                                D: false,
                            },
                            correct: false,
                            unanswered: true,
                            test:currentTest.reactId,
                        };
                        questions.push(question.nid);
                    }
                    return true;
                });

                currentTest.questionResponses = questionResponses;
                currentTest.questions = questions;
            }
            userTests[action.test] = currentTest;
            localStorage.setItem('userTests', JSON.stringify(userTests));
            localStorage.removeItem('testInProgress');
            localStorage.removeItem('lastTestParams');
            return state.merge({
                userTests: userTests,
                testInProgress: {empty: true},
                error: true,
                errorMessage: 'Testul a fost salvat.',
                messageType: 'success',
            });
        case types.START_TEST:
            testInProgress = Object.assign({}, state.testInProgress);
            if (testInProgress.empty) {
                testInProgress = {
                    title: (state.testName !== undefined) ? state.testName : 'Test ' + action.test,
                    selectedExam: action.selectedExam,
                    timestamp: Date.now(),
                    questions: [],
                    questionResponses: [],
                    reactId: action.test,
                    configurationOptions: state.lastTestParams,
                    finished: false,
                };
            }
            localStorage.setItem('testInProgress', JSON.stringify(testInProgress));
            return state.merge({
                testInProgress: testInProgress,
            });

        case types.NEW_ANSWER:
            testInProgress = Object.assign({}, state.testInProgress);
            currentResponses = (!testInProgress.empty) ? Object.assign({}, testInProgress.questionResponses) : Object.assign({}, []);
            currentResponses[action.nid] = {
                nid: action.nid,
                responses: action.responses,
                correct: action.correct,
                test: action.test,
            };
            if (testInProgress.empty) {
                testInProgress = {
                    title: (state.testName !== undefined) ? state.testName : 'Test ' + action.test,
                    timestamp: Date.now(),
                    questions: [],
                    questionResponses: currentResponses,
                    reactId: action.test,
                    configurationOptions: state.lastTestParams,
                    finished: false,
                };
            }
            testInProgress['questions'] = testInProgress['questions'].concat([action.nid]);
            testInProgress['questionResponses'] = currentResponses;
            localStorage.setItem('testInProgress', JSON.stringify(testInProgress));
            return state.merge({
                questionResponses: currentResponses,
                testInProgress: testInProgress,
            });

        // TODO: Refactor this to allow progressive loading.
        case types.USER_TESTS_LOAD_SUCCESS:
            let lastId = 0;
            Object.keys(action.userTests).map((key) => {
                userTests[action.userTests[key].reactId] = {
                    title: action.userTests[key].title,
                    selectedExam: action.userTests[key].selectedExam,
                    questions: action.userTests[key].questions,
                    timestamp: action.userTests[key].timestamp,
                    reactId: action.userTests[key].reactId,
                    nid: action.userTests[key].nid,
                    questionResponses: action.userTests[key].questionResponses,
                    correctAnswers: action.userTests[key].correctAnswers,
                    configurationOptions: action.userTests[key].configurationOptions,
                    finished: action.userTests[key].finished,
                    error: false,
                    testsLoading: false
                };
                let testId = Number(action.userTests[key].reactId);
                if (testId > lastId) {
                    lastId = testId;
                }
                return true;
            });
            localStorage.setItem('userTests', JSON.stringify(Object.assign({}, userTests)));
            return state.merge({
                loading: false,
                userTests: Object.assign({}, userTests),
                nextTestId: lastId + 1,
            });

        case types.USER_TEST_QUESTIONS_LOAD_SUCCESS:
            let testsQuestions = Object.assign({}, state.testsQuestions, action.testQuestions);
            return state.merge({
                loading: false,
                testsQuestions: testsQuestions,
            });

        case types.TESTS_TERMS_LOAD_SUCCESS:
            localStorage.setItem('testsTerms', JSON.stringify(action.terms));
            return state.merge({testsTerms: action.terms, loading: false});

        case types.TESTS_TERMS_STRUCTURE_LOAD_SUCCESS:
            localStorage.setItem('testsTermsStructure', JSON.stringify(action.termsStructure));
            return state.merge({testsTermsStructure: action.termsStructure, loading: false});

        case types.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_SUCCESS:
            localStorage.setItem('testsTermsChaptersStructure', JSON.stringify(action.termsStructure));
            return state.merge({testsTermsChaptersStructure: action.termsStructure, loading: false});

        case types.TESTS_TERMS_YEARS_STRUCTURE_LOAD_SUCCESS:
            localStorage.setItem('testsTermsYearsStructure', JSON.stringify(action.termsStructure));
            return state.merge({testsTermsYearsStructure: action.termsStructure, loading: false, error: false});

        case types.SET_TEST_NAME:
            return state.merge({testName: action.testName});

        case types.SET_LAST_TEST_PARAMS:
            localStorage.setItem('lastTestParams', JSON.stringify(action.params));
            return state.merge({lastTestParams: action.params});

        case types.SET_QUESTIONS_COUNT:
            return state.merge({totalQuestionsForCurrentTest: action.questionsCount});

        case types.SKIP_QUESTION:
            let test = Object.assign([], state.test);
            test.push(test.splice(action.questionPosition, 1)[0]);
            localStorage.setItem('currentTest', encryptData(test));
            return state.merge({test: test});

        case types.TIME_IS_UP:
            console.log('timeIsUp');
            return state;

        case types.REMOVE_LOCAL_TESTS:
            localStorage.removeItem('userTestsLoaded');
            localStorage.removeItem('userTests');
            localStorage.removeItem('testsTermsStructure');
            localStorage.removeItem('testsTermsYearsStructure');
            localStorage.removeItem('testsTermsChaptersStructure');
            return state.merge({
                userTests: [],
                testsTermsStructure: [],
                testsTermsChaptersStructure: [],
                testsTermsYearsStructure: [],
            });

        case types.SYNC_USER_TESTS:

            return state;

        case types.SET_CURRENT_TEST_ANSWERS:
            return state.merge({
                currentTestAnswers: action.currentTestAnswers,
            })

        default:
            return state;
    }
}

export function getFreeTest(state) {
    return state.tests.freeTest;
}

export function getCurrentTest(state) {
    return state.tests.test;
}

export function getError(state) {
    return [state.tests.error, state.tests.errorMessage, state.tests.messageType];
}

export function getLoading(state) {
    return state.tests.loading;
}

export function getTestsLoading(state) {
    return state.tests.testsLoading;
}

export function getCurrentUserTestId(state) {
    return state.tests.nextTestId;
}

export function getUserTests(state) {
    let userTests = [];
    userTests = Object.keys(state.tests.userTests).map((key) => {
        let questionResponses = Object.keys(state.tests.userTests[key]['questions']).map((i) => {
            let questionNid = state.tests.userTests[key]['questions'][i];
            let questionResponse = state.tests.userTests[key].questionResponses[questionNid];
            return questionResponse;
        });
        let date = state.tests.userTests[key].timestamp * 1000;
        return {
            testId: key,
            nid: state.tests.userTests[key].nid,
            testName: state.tests.userTests[key].title,
            timestamp: new Date(date),
            questions: state.tests.userTests[key]['questions'],
            responses: questionResponses,
            correctAnswers: state.tests.userTests[key].correctAnswers,
            finished: state.tests.userTests[key].finished,
            configurationOptions: state.tests.userTests[key].configurationOptions,
        };
    });
    return userTests;
}

export function getUnfinishedTest(state) {
    let unfinishedTest = false;
    if (state.tests !== undefined && !state.tests.testInProgress.empty) {
        unfinishedTest = state.tests.testInProgress;
    }
    return unfinishedTest;
}

export function getUserTestsToSync(state) {
    return Object.assign({}, state.tests.userTests);
}


export function getTestsTerms(state) {
    return state.tests.testsTerms;
}

export function getSubjects(state) {
    let subjects = [];
    for (let key = 0; key < state.tests.testsTerms.length; key++) {
        if (state.tests.testsTerms[key].vid === 'subject') {
            subjects.push(state.tests.testsTerms[key]);
        }
    }
    return subjects;
}

export function getSchools(state) {
    let schools = [];
    for (let key = 0; key < state.tests.testsTerms.length; key++) {
        if (state.tests.testsTerms[key].vid === 'scoli') {
            schools.push(state.tests.testsTerms[key]);
        }
    }
    return schools;
}

export function getTermsStructure(state) {
    return state.tests.testsTermsStructure;
}

export function getTermsChaptersStructure(state) {
    return state.tests.testsTermsChaptersStructure;
}

export function getTermsYearsStructure(state) {
    return state.tests.testsTermsYearsStructure;
}

export function getTestsQuestionsCount(state) {
    let questionsCount = {
        'chapters': 0,
        'years': 0,
        'checkKnowledge': 0,
        'simulate': 0,
    };
    for (let key = 0; key < state.tests.testsTerms.length; key++) {
        let term = state.tests.testsTerms[key];
        if (term.vid === 'chapter') {
            questionsCount['chapters'] += parseInt(term.questions);
        }
        if (term.vid === 'sub_category') {
            questionsCount['years'] += parseInt(term.questions);
        }
        if (term.vid === 'subject') {
            questionsCount['checkKnowledge'] += parseInt(term.questions);
            questionsCount['simulate'] += parseInt(term.questions);
        }
    }
    return questionsCount;
}

export function getTermsKeyedWithTids(state) {
    let terms = [];
    for (let key = 0; key < state.tests.testsTerms.length; key++) {
        terms[state.tests.testsTerms[key].tid] = state.tests.testsTerms[key];
    }
    return terms;
}

export function getTestQuestions(state) {
    return state.tests.testsQuestions;
}

export function freeTestFinished(state) {
    for (let key in state.tests.userTests) {
        if (state.tests.userTests[key].reactId === 'free') {
            return state.tests.userTests[key].nid;
        }
    }
    return false;
}

export function getTestName(state) {
    return state.tests.testName;
}

export function getTotalQuestionsForCurrentTest(state) {
    return state.tests.test.length;
}

export function getLastTestParams(state) {
    return state.tests.lastTestParams;
}

export function getTimeData(state) {
    return {
        isCountdown: state.tests.lastTestParams ? state.tests.lastTestParams.timing : false,
        startTime: state.tests.testInProgress.empty ? false : state.tests.testInProgress.timestamp,
        limit: state.tests.lastTestParams ? state.tests.lastTestParams.timeLimit : false,
    }
}

export function getCurrentTestAnswers(state) {
    return state.tests.currentTestAnswers;
}