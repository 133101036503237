import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/index';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Markdown from "../Markdown";
import ShareIcon from '@material-ui/icons/Share';

const styles = theme => ({
    root: {
        minWidth: 800,
    },
    container: {
        padding: theme.spacing(),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

class MarkdownContentDialog extends React.Component {

    render() {
        const { content, classes } = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <>
                {content !== undefined &&
                <Dialog
                    className="markdown-content"
                    maxWidth={"lg"}
                    fullScreen={isFull}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {content.title}
                            </Typography>
                            <div>
                                <IconButton color="inherit" onClick={this.props.toggleShareDrawer(true)}><ShareIcon/></IconButton>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className={classes.container}>
                        <Markdown>{content.body}</Markdown>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </>
        );
    }
}

export default withWidth()(withStyles(styles)(MarkdownContentDialog));