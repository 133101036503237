import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    username: '',
    password: '',
    isAuthenticated: localStorage.getItem('accessToken') !== null,
    token: localStorage.getItem('accessToken') !== null ? localStorage.getItem('accessToken') : '',
    refreshToken: localStorage.getItem('refreshToken') !== null ? localStorage.getItem('refreshToken') : '',
    uid: localStorage.getItem('uid') !== null ? localStorage.getItem('uid') : '',
    userData: [],
    errorMessage: '',
    error: false,
    errorType: 'error',
    loading: false,
});

let userData;

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.LOGIN_SUCCESS:
        case types.REGISTER_SUCCESS:
        case types.REFRESH_TOKEN_SUCCESS:
            localStorage.setItem('accessToken', action.accessToken);
            localStorage.setItem('refreshToken', action.refreshToken);

            return state.merge({
                token: action.accessToken,
                refreshToken: action.refreshToken,
                isAuthenticated: true,
                error: false,
                loading: false,
            });
        case types.USER_DETAILS_FAILURE:
            return state.merge({
                error: action.error,
                errorMessage: action.errorMessage,
                loading: false,
            });
        case types.LOGIN_FAILURE:
        case types.REGISTER_FAILURE:
            localStorage.clear();
            return state.merge({
                error: action.error,
                errorMessage: action.errorMessage,
                isAuthenticated: false,
                loading: false,
                token: '',
                refreshToken: '',
            });
        case types.REFRESH_TOKEN_FAILURE:
            localStorage.clear();
            return state.merge({
                error: true,
                errorMessage: action.errorMessage,
                isAuthenticated: false,
                loading: false,
                token: '',
                refreshToken: '',
            });
        case types.LOGOUT:
            localStorage.clear();
            window.location.reload();
            return state.merge({
                token: '',
                refreshToken: '',
                isAuthenticated: false,
                loading: false,
            });
        case types.USER_DETAILS_SUCCESS:
            localStorage.setItem('uid', action.userData.uid);
            userData = action.userData;
            userData['letter'] = userData.mail.charAt(0).toUpperCase();
            return state.merge({
               uid: action.userData.uid,
               userData: userData,
            });
        case types.UPDATE_USER_SUCCESS:
            localStorage.setItem('uid', action.userData.uid);
            userData = action.userData;
            userData['letter'] = userData.mail.charAt(0).toUpperCase();
            return state.merge({
                uid: action.userData.uid,
                userData: userData,
                error: true,
                errorMessage: "Datele tale au fost salvate",
                errorType: 'success',
                loading: false,
            });
        case types.UPDATE_USER_FAILURE:
            return state.merge({
                error: action.error,
                errorMessage: action.errorMessage,
                loading: false,
            });
        case types.LOADING_START:
            return state.merge({
                loading: true,
            });
        case types.LOADING_STOP:
            return state.merge({
                loading: false,
            });
        case types.DISMISS_MESSAGES:
            return state.merge({
                error: false,
                errorType: 'error',
            });
        default:
            return state;
    }
}

// selectors

export function getIsAuthenticated(state) {
    return state.authentication.isAuthenticated;
}
export function getAccessToken(state) {
    return state.authentication.token;
}
export function getError(state) {
    return {
        error: state.authentication.error,
        errorMessage: state.authentication.errorMessage,
        errorType: state.authentication.errorType,
    };
}
export function getUserData(state) {
    return state.authentication.userData;
}
export function getLoading(state) {
    return state.authentication.loading;
}
export function isUserPremium(state) {
    return state.authentication.userData.subscription === 'pro';
}

export function previewExpired(state) {
    if (state.authentication.userData.subscription !== 'pro') {
        // If now is in user's first 15 minutes of access access of today, grant access.
        return state.authentication.userData.todayAccess + 900 <= Date.now() / 1000;
    }
    return false;
}

export function getAccessRemainingTime(state) {
    return (state.authentication.userData.todayAccess + 900 - (Date.now() / 1000));
}

export function userHasName(state) {
    if (state.authentication.isAuthenticated) {
        return (state.authentication.userData.firstName !== '' && state.authentication.userData.lastName !== '');
    }
    return false;
}

export function getSubscriptions(state) {
    let subscriptions = [];
    if (state.authentication.userData.subscriptionExpiration !== undefined) {
        let keys = Object.keys(state.authentication.userData.subscriptionExpiration);
        subscriptions = keys.filter(function(key) {
            return state.authentication.userData.subscriptionExpiration[key];

        })
    }
    return subscriptions;
}
