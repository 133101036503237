import React from 'react';
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import CardActionArea from "@material-ui/core/CardActionArea";

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
});

class TestCard extends React.Component {

    render() {
        const { classes, testType, testTypeDescription, image, questionsCount } = this.props;
        return (
            <div className={classes.root}>
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cover}
                        image={image}
                        title={testType}
                        onClick={this.props.onClick}
                    />
                    <CardActionArea onClick={this.props.onClick}>
                        <div className={classes.details}>
                            <CardContent className={classes.content}>
                                <Typography component="h5" variant="h5">
                                    {testType}
                                </Typography>
                                <Typography variant="subtitle1" color="textSecondary">
                                    {testTypeDescription}
                                </Typography>
                                {questionsCount !== undefined &&
                                <Typography variant="subtitle1" color="textSecondary">
                                    Întrebări disponibile: {questionsCount}
                                </Typography>
                                }
                            </CardContent>
                        </div>
                    </CardActionArea>
                </Card>
            </div>
        );
    }
}

export default (withStyles(styles)(TestCard));