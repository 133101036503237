const API_URL = process.env.REACT_APP_API_URL;

class PaymentService {

    async request(token, service, method = 'GET', payloadData = undefined) {
        const url = '/grile_api/' + service;
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        let data = [];
        let payload = {
            method: method,
            headers: headers,
        }
        if (method !== 'GET') {
            payload.body = payloadData;
        }
        const response = await fetch(API_URL + url, payload);
        if (response.status === 200) {
            if (method !== 'DELETE') {
                data = await response.json();
            } else {
                data = true;
            }
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            }
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async getProducts(token) {
        return this.request(token, 'products');
    }

    async getOrders(token) {
        return this.request(token, 'payment');
    }

    async createOrder(token, productId, addressId) {
        return this.request(token, 'payment', 'POST', JSON.stringify({
            productId: productId,
            addressId: addressId,
        }));
    }

    async getAddresses(token) {
        return this.request(token, 'addresses')
    }

    async addNewAddress(token, address) {
        return this.request(token, 'addresses', 'POST', JSON.stringify(address))
    }

    async deleteAddress(token, addressId) {
        return this.request(token, 'addresses', 'DELETE', JSON.stringify({
            addressId: addressId,
        }));
    }
}

export default new PaymentService();