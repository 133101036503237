import React, {Component} from 'react';
import PropTypes from "prop-types";
import {
    Button,
    Card,
    CardActions,
    CardMedia,
    Grid,
    IconButton,
    Tooltip,
    CardContent,
    withStyles
} from "@material-ui/core";
import resultsImage from "../images/results.png";
import BugReportIcon from "@material-ui/icons/BugReport"
import SummaryBoxContent from "./SummaryBoxContent";
import {Link} from "react-router-dom";


const styles = theme => ({
    actions: {
        display: 'flex',
    },
    rightIcons: {
        marginLeft: 'auto',
    },
    button: {
        margin: theme.spacing(),
    },
    card: {
        height: '100%',
        maxWidth: 800,
        color: theme.palette.text.secondary,
    },
    media: {
        height: 0,
        paddingTop: 100,
    },
});

class SummaryBox extends Component {
    render() {
        const {classes, questions} = this.props;
        // let length = Object.keys(this.state.questions).length;
        return (
            <Grid item>
                <Card
                    className={classes.card}
                >
                    <CardMedia
                        className={classes.media}
                        image={resultsImage}
                        title="Results"
                    />
                    <CardContent>
                        <SummaryBoxContent
                            questions={questions}
                            answers={this.props.answers}
                            multipleChoice={this.props.multipleChoice}
                        />
                    </CardContent>

                    <CardActions>
                        <Button color="primary" className={classes.button} component={Link} to="/tests">
                            Gata
                        </Button>
                        <Tooltip title="Raportează un Bug" placement="left">
                            <IconButton aria-label="BugReport" className={classes.rightIcons} onClick={this.props.handleBugReport}>
                                <BugReportIcon />
                            </IconButton>
                        </Tooltip>
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}


SummaryBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(SummaryBox));