import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Grid, withStyles} from "@material-ui/core";
import * as lessonsSelectors from "../store/lessons/reducer";
import * as lessonsActions from '../store/lessons/actions';
import image from "../images/studying-ahead.jpg"
import Helmet from "react-helmet";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import LessonIcon from '@material-ui/icons/DescriptionOutlined';
import FolderIcon from '@material-ui/icons/FolderOpen';
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import LessonDialog from "../components/dialogs/LessonDialog";


const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    selected: {
        backgroundColor: 'rgba(125, 125, 125, 0.8)',
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '35%',
    },
    centered: {
        textAlign: 'center',
    }
});

class Lessons extends Component {

    state = {
        subject: undefined,
        chapter: undefined,
        lessonId: undefined,
        openLessonDialog: false,
    }

    componentDidMount() {
        this.props.dispatch(lessonsActions.getLessonsStructure());
    }

    subjectClick = (id) => () => {
        this.setState({
            subject: id,
            chapter: undefined,
            lessonId: undefined,
        })
    }

    chapterClick = (id) => () => {
        this.setState( {
            chapter: id,
            lessonId: undefined,
        })
    }

    lessonClick = (id) => () => {
        this.setState({
            lessonId: id,
            openLessonDialog: true,
        })
        if (this.props.loadedLessons[id] === undefined) {
            this.props.dispatch(lessonsActions.getLesson(id));
        }
    }

    closeLessonDialog = () => {
        this.setState({
            lessonId: undefined,
            openLessonDialog: false,
        })
    }

    getFeatured() {
        const { classes } = this.props;
        return Object.keys(this.props.lessonsStructure[this.state.subject].featured).map(key => {
            return (
                <Grid item key={key} xs={12} md={6}>
                    <Card className={classes.card}>
                        <CardActionArea onClick={this.lessonClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <LessonIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="body1">{this.props.lessonsStructure[this.state.subject]['featured'][key]}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    getChapters() {
        const { classes, termsData } = this.props;
        return Object.keys(this.props.lessonsStructure[this.state.subject]).map(key => {
            if (key === 'featured') {
                return false;
            }
            return (
                <Grid item key={key} xs={12} md={6} lg={3}>
                    <Card className={key === this.state.chapter ? classes.selected : ''}>
                        <CardActionArea onClick={this.chapterClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FolderIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="h6">{termsData[key] ? termsData[key] : 'Pe capitole'}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    getSubjects() {
        const { classes, termsData } = this.props;
        return Object.keys(this.props.lessonsStructure).map(key => {
            return (
                <Grid item key={key} xs={12} md={6} lg={3}>
                    <Card className={key === this.state.subject ? classes.selected : ''}>
                        <CardActionArea onClick={this.subjectClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FolderIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="h5">{termsData[key]}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    getLessonTitles() {
        const { classes } = this.props;
        return Object.keys(this.props.lessonsStructure[this.state.subject][this.state.chapter]).map(key => {
            return (
                <Grid item key={key} xs={12} md={6}>
                    <Card className={classes.card}>
                        <CardActionArea onClick={this.lessonClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <LessonIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="body1">{this.props.lessonsStructure[this.state.subject][this.state.chapter][key]}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    render() {
        const { classes, lessonsStructure } = this.props;
        return(
            <>
                <Helmet>
                    <title>Lecţii - Grile Politie</title>
                </Helmet>
                <Grid container justify="center" className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cover}
                                image={image}
                                title="Imagine lectii"
                                onClick={this.props.onClick}
                            />
                            <CardActionArea onClick={this.props.onClick}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography component="h5" variant="h5">
                                            Lecţii
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Acumulează cunoștințe aprofundând materiile pentru examen
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {lessonsStructure !== undefined &&
                        this.getSubjects()
                    }
                    {this.state.subject !== undefined &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Capitole</Typography></Paper>
                            </Grid>
                            {this.getChapters()}
                        </>
                    }
                    <Grid item xs={12}>
                    </Grid>
                    {this.state.subject !== undefined &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Lecţii Promovate</Typography></Paper>
                            </Grid>
                            {this.getFeatured()}
                        </>
                    }
                    <Grid item xs={12}>
                    </Grid>
                    {this.state.chapter !== undefined &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Lecţii</Typography></Paper>
                            </Grid>
                            {this.getLessonTitles()}
                        </>
                    }
                </Grid>
                <LessonDialog open={this.state.openLessonDialog}
                              handleClose={this.closeLessonDialog}
                              lesson={this.props.loadedLessons[this.state.lessonId]}
                />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        lessonsStructure: lessonsSelectors.getLessonsStructure(state),
        termsData: lessonsSelectors.getLessonsTerms(state),
        loadedLessons: lessonsSelectors.getLoadedLessons(state),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(Lessons));