import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/index';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from "@material-ui/core";
import SummaryBoxContent from "../SummaryBoxContent";
import CircularLoaderContent from "../CircularLoaderContent";
import TestResultsDialogMenu from "../../containers/menus/TestResultsDialogMenu";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import TestDetails from "../../containers/TestDetails";

const styles = theme => ({
    container: {
        padding: theme.spacing(),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
});

class TestResultsDialog extends React.Component {

    render() {
        const { test, classes } = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <div>
                {test !== undefined &&
                <Dialog
                    fullScreen={isFull}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.props.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                                {test.testName}
                            </Typography>
                            <TestResultsDialogMenu closeDialog={this.props.handleClose} testId={test.nid}/>
                        </Toolbar>
                    </AppBar>
                    <DialogContent className={classes.container}>
                        <Typography component="p">Dată: {test.timestamp.toLocaleString("ro-RO")}</Typography>
                        <Typography component="p">Notă: {Math.round(test.correctAnswers * 100 / test.questions.length) + '%'}</Typography>
                        {test.configurationOptions &&
                            <TestDetails
                                configurationOptions={test.configurationOptions}
                            />
                        }
                        {this.props.questions !== undefined &&
                        <SummaryBoxContent
                            questions={this.props.questions}
                            answers={test.responses}
                            multipleChoice={this.props.multipleChoice}
                        />
                        }
                        {this.props.questions === undefined &&
                        <CircularLoaderContent/>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(TestResultsDialog));