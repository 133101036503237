import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import Contact from "./legal/Contact";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsConditions from "./legal/TermsConditions";
import Helmet from "react-helmet";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});


class InfoPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Helmet>
                    <title>Contact - Grile Politie</title>
                </Helmet>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Contact />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PrivacyPolicy />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TermsConditions />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(InfoPage);