import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    userAddresses: [],
    userOrders: [],
    products: [],
    selectedProductId: localStorage.getItem('selectedProductId') !== null ? localStorage.getItem('selectedProductId') : undefined,
    selectedAddressId: localStorage.getItem('selectedAddressId') !== null ? localStorage.getItem('selectedAddressId') : undefined,
    orderCreation: undefined,
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.PAYMENT_ADDRESS_ADD_FAIL:
        case types.PAYMENT_ADDRESS_DELETE_FAIL:
        case types.PAYMENT_ADDRESSES_LOAD_FAIL:
        case types.PAYMENT_ORDER_CREATE_FAIL:
        case types.PAYMENT_ORDERS_LOAD_FAIL:
        case types.PAYMENT_PRODUCTS_LOAD_FAIL:
            return state;

        case types.PAYMENT_PRODUCTS_LOAD_SUCCESS:
            return state.merge({products: action.products});

        case types.PAYMENT_ORDERS_LOAD_SUCCESS:
            return state.merge({userOrders: action.userOrders})

        case types.PAYMENT_ORDER_CREATE_SUCCESS:
            // Remove data stored in local storage for the order as it is finished.
            localStorage.setItem('selectedProductId', null);
            localStorage.setItem('selectedAddressId', null);
            let orderCreation = {
                env_key: action.orderData.env_key,
                data: action.orderData.data,
                paymentUrl: action.orderData.paymentUrl,
            };
            return state.merge({
                orderCreation: orderCreation,
            })

        case types.PAYMENT_ADDRESSES_LOAD_SUCCESS:
        case types.PAYMENT_ADDRESS_ADD_SUCCESS:
            return state.merge({userAddresses: action.userAddresses});
        case types.PAYMENT_ADDRESS_DELETE_SUCCESS:
            localStorage.setItem('selectedAddressId', null);
            return state;

        case types.PAYMENT_SETTING_SET:
            localStorage.setItem(action.settingName, action.settingValue);
            return state.merge({
                [action.settingName]: action.settingValue,
            })

        default:
            return state;
    }
}

export function getUserAddresses(state) {
    return state.payment.userAddresses;
}

export function getUserOrders(state) {
    return state.payment.userOrders;
}

export function getProducts(state) {
    return state.payment.products;
}

export function getSelectedAddress(state) {
    for (let i in Object.keys(state.payment.userAddresses)) {
        let address = state.payment.userAddresses[i];
        if (address.id === state.payment.selectedAddressId) {
            return address;
        }
    }
    return false;
}


export function getSelectedAddressIndex(state) {
    for (let i in Object.keys(state.payment.userAddresses)) {
        let address = state.payment.userAddresses[i];
        if (address.id === state.payment.selectedAddressId) {
            return i;
        }
    }
    return false;
}

export function getSelectedProduct(state) {
    for (let i in Object.values(state.payment.products)) {
        let product = state.payment.products[i];
        if (product.id === state.payment.selectedProductId) {
            return product;
        }
    }
    return false;
}

export function getOrderCreation(state) {
    return state.payment.orderCreation;
}