import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    utilsStructure: {},
    termsData: {},
    loadedUtilsPages: {},
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.STRUCTURE_LOAD_SUCCESS:
            return state.merge({
                utilsStructure: action.utilsStructure,
                termsData: action.termsData,
            })

        case types.UTILS_PAGE_LOAD_SUCCESS:
            let loadedUtilsPages = Object.assign({}, state.loadedUtilsPages);
            loadedUtilsPages[action.utilsPageId] = action.utilsPageData;
            return state.merge({
                loadedUtilsPages: loadedUtilsPages,
            });

        case types.STRUCTURE_LOAD_FAIL:
        case types.UTILS_PAGE_LOAD_FAIL:
            return state;

        default:
            return state;
    }
}

export function getUtilsTerms(state) {
    return state.utils.termsData;
}

export function getUtilsStructure(state) {
    return state.utils.utilsStructure;
}

export function getLoadedUtilsPages(state) {
    return state.utils.loadedUtilsPages;
}

export function loadedUtilsPagesArrayKeys(state) {
    return Object.keys(state.utils.loadedUtilsPages);
}