import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as serviceWorker from '../serviceWorker';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import * as authenticationSelectors from '../store/authentication/reducer';
import * as settingsSelectors from '../store/settings/reducer';
import * as settingsActions from '../store/settings/actions';
import Grid from "@material-ui/core/Grid";
import AppNavigation from "./AppNavigation";
import {BrowserRouter} from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import Messages from "../components/messages/Messages";
import Helmet from "react-helmet";
import CustomRouter from "../components/CustomRouter";

const lightTheme = createMuiTheme({
    palette: {
        primary: blueGrey,
        secondary: {
            main: '#385f72',
        },
        success: {
            main: "#81c784",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        type: "light",
        tonalOffset: 0.3,
    },
    typography: {
        useNextVariants: true,
    },
});

const darkTheme = createMuiTheme({
    palette: {
        primary: {
            main: blueGrey[300],
        },
        secondary: {
            main: '#5d8295',
        },
        success: {
            main: "#388e3c",
            contrastText: "rgba(0, 0, 0, 0.87)",
        },
        type: "dark",
    },
    typography: {
        useNextVariants: true,
    },
});

const styles = theme => ({

    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        height: '100%',
        color: theme.palette.text.secondary,
    },
    control: {
        padding: theme.spacing(2),
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        minHeight: '64px',
        // ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 73 + theme.spacing(3),
        },
    },
});

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newUpdate: false,
        };
        serviceWorker.register({
            onUpdate: this.onUpdate,
        });
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    onUpdate = (registration) => {
        console.log('service-worker is going to be re-registered');
        this.setState({newUpdate: true});
    };

    updateNow = () => {
        localStorage.removeItem('testsTerms');
        window.location.reload();
    };

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.setState({hasUpdate: nextProps.hasUpdate})
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.props.dispatch(settingsActions.updateWindowSize(window.innerWidth, window.innerHeight));
    }


    render() {
        const {isAuthenticated, classes, proAccess, userHasName, selectedExam, subscriptions, isUserPremium } = this.props;
        const alignItems = 'flex-start';
        const theme = this.props.darkMode ? darkTheme : lightTheme;
        const justify = 'center';
        const direction = 'row';
        return (
            <BrowserRouter>
            <div className={classes.root}>
                <Helmet>
                    <title>Grile Politie</title>
                </Helmet>
                <MuiThemeProvider theme={theme}>
                <AppNavigation/>
                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                            <Grid
                                container
                                spacing={3}
                                alignItems={alignItems}
                                direction={direction}
                                justify={justify}
                            >
                                <CustomRouter
                                    isAuthenticated={isAuthenticated}
                                    userHasName={userHasName}
                                    proAccess={proAccess}
                                    subscriptions={subscriptions}
                                    selectedExam={selectedExam}
                                    isUserPremium={isUserPremium}
                                />
                                {/*<Switch>*/}
                                {/*    <Route path='/contact' component={InfoPage}/>*/}
                                {/*    <Route exact path='/utils' component={Utils}/>*/}
                                {/*    <Route path='/utils/:id' component={Utils}/>*/}
                                {/*    <Route exact path='/homepage' component={Homepage}/>*/}
                                {/*    {*/}
                                {/*        !isAuthenticated &&*/}
                                {/*            <>*/}
                                {/*                <Route exact path='/login' component={Login}/>*/}
                                {/*                <Route path='/' component={Homepage}/>*/}
                                {/*            </>*/}
                                {/*    }*/}
                                {/*    {*/}
                                {/*        isAuthenticated && !userHasName &&*/}
                                {/*        <Route path='/' component={RegistrationStepTwo}/>*/}
                                {/*    }*/}
                                {/*    {*/}
                                {/*        isAuthenticated && userHasName &&*/}
                                {/*        <>*/}
                                {/*            <Route exact path='/bugs' component={Bugs}/>*/}
                                {/*            <Route exact path='/settings' component={Settings}/>*/}
                                {/*            <Route path='/settings/orders' component={Orders}/>*/}
                                {/*            <Route path='/exam' component={ExamSelection}/>*/}
                                {/*            <Route path='/review' component={Review}/>*/}
                                {/*            <Route exact path='/utils' component={Utils}/>*/}
                                {/*            <Route path='/products' component={Products}/>*/}
                                {/*            <Route path='/addresses' component={Addresses}/>*/}
                                {/*            <Route path='/order' component={OrderSummary}/>*/}
                                {/*            <Route path='/order-finished' component={OrderFinished}/>*/}
                                {/*            {*/}
                                {/*                proAccess &&*/}
                                {/*                <>*/}
                                {/*                    <Route exact path='/tests' component={Tests}/>*/}
                                {/*                    <Route path='/tests/:type/:view/:id' component={Test}/>*/}
                                {/*                    <Route path='/tests/:type/:view' component={Test}/>*/}
                                {/*                    <Route path='/tests/:type/:view' component={Test}/>*/}
                                {/*                    <Route path='/statistics' component={Statistics}/>*/}
                                {/*                    <Route path='/lessons' component={Lessons}/>*/}
                                {/*                    <Route exact path='/' component={Tests}/>*/}
                                {/*                </>*/}
                                {/*            }*/}
                                {/*            {*/}
                                {/*                !proAccess &&*/}
                                {/*                <>*/}
                                {/*                    <Route path='/tests' component={NoAccess}/>*/}
                                {/*                    <Route path='/statistics' component={NoAccess}/>*/}
                                {/*                    <Route path='/lessons' component={NoAccess}/>*/}
                                {/*                    <Route path='/' component={NoAccess}/>*/}
                                {/*                </>*/}
                                {/*            }*/}
                                {/*        </>*/}
                                {/*    }*/}
                                {/*    <Route component={NotFound} />*/}
                                {/*</Switch>*/}
                            </Grid>
                </main>
                    <Messages
                        error={this.state.newUpdate}
                        variant="info"
                        message="Versiune nouă disponibilă"
                        button="Actualizare"
                        onClose={this.updateNow}
                    />
                </MuiThemeProvider>
            </div>
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: authenticationSelectors.getIsAuthenticated(state),
        userHasName: authenticationSelectors.userHasName(state),
        proAccess: !authenticationSelectors.previewExpired(state),
        subscriptions: authenticationSelectors.getSubscriptions(state),
        isUserPremium: authenticationSelectors.isUserPremium(state),
        darkMode: settingsSelectors.getDarkTheme(state),
        selectedExam: settingsSelectors.getSelectedExam(state),
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default connect(mapStateToProps)(withStyles(styles)(App));
