import React from 'react';
import {Card, Grid, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import * as paymentSelectors from '../../store/payment/reducer';
import * as settingsSelectors from '../../store/settings/reducer';
import * as paymentActions from '../../store/payment/actions';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Helmet from "react-helmet";
import Product from "../../components/payment/Product";
import CardContent from "@material-ui/core/CardContent";
import {EXAMS} from "../../components/Constants";

const styles = theme => ({
    root: {},
    emptyGrid: {
        padding: "0 !important",
    },
});

class Products extends React.Component {

    componentDidMount() {
        this.props.dispatch(paymentActions.getProducts());
    }

    renderProducts() {
        return Object.keys(this.props.products).map(key => {
            if (this.props.products[key].exam === this.props.selectedExam) {
                return (
                    <Product
                        key={key}
                        productInfo={this.props.products[key]}
                        selectProduct={this.selectProduct}
                    />
                );
            }
            return false;
        });
    }

    selectProduct = (id) => {
        this.props.dispatch(paymentActions.settingSet('selectedProductId', id));
        this.props.history.push('/addresses');
    }

    render() {
        const {classes, products, selectedExam} = this.props;
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                <Helmet>
                    <title>Cumpără - Grile Politie</title>
                </Helmet>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Alege produsul pentru {EXAMS[selectedExam]}
                            </Typography>
                            <Typography variant="body1" component="p">
                                Alege produsul care ţi se potriveşte cel mai bine în funcţie de perioadă sau preţ.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} className={classes.emptyGrid} />
                {products.length === 0 &&
                <Grid item xs={12}>
                    <Paper className={classes.centered}><Typography variant="body1">Momentan nu sunt produse disponibile.</Typography></Paper>
                </Grid>
                }
                {products.length > 0 && this.renderProducts()}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        products: paymentSelectors.getProducts(state),
        selectedExam: settingsSelectors.getSelectedExam(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Products));