import * as types from './actionTypes';
import AuthService from '../../services/authentication'
import * as settingsActions from "../../store/settings/actions";

export function login(credentals) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await AuthService.login(credentals);
        if (data.error) {
            let message = data.message;
            if (data.message === 'The user credentials were incorrect.') {
                message = 'Datele de logare nu sunt corecte.'
            }
            dispatch({type: types.LOGIN_FAILURE, error: true, errorMessage: message});
        }
        else {
            dispatch({type: types.LOGIN_SUCCESS, accessToken: data.access_token, refreshToken: data.refresh_token});
            // Also fetch user details after the login was successful.
            dispatch(getUserDetails(data.access_token));
        }

    }
}

export function facebookLogin(credentals) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await AuthService.facebookLogin(credentals);
        if (data.error) {
            dispatch({type: types.LOGIN_FAILURE, error: true, errorMessage: data.message});
        }
        else {
            dispatch({type: types.LOGIN_SUCCESS, accessToken: data.access_token, refreshToken: data.refresh_token});
            // Also fetch user details after the login was successful.
            dispatch(getUserDetails(data.access_token));
        }

    }
}

export function register(credentials) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await AuthService.register(credentials);
        if (data.error) {
            dispatch({type: types.REGISTER_FAILURE, error: true, errorMessage: data.message})
        }
        else {
            dispatch({type: types.REGISTER_SUCCESS, accessToken: data.access_token, refreshToken: data.refresh_token});
        }
    }
}

export function logout() {
    return async (dispatch, getState) => {
        dispatch({type: types.LOGOUT});
    }
}

export function refreshToken(refreshToken) {
    return async (dispatch, getState) => {
        let data = await AuthService.refreshToken(refreshToken);
        if (data.error) {
            console.error(data.message);
            dispatch({type: types.REFRESH_TOKEN_FAILURE, errorMessage: data.message});
        }
        else {
            await dispatch({type: types.REFRESH_TOKEN_SUCCESS, accessToken: data.access_token, refreshToken: data.refresh_token })
            await dispatch(getUserDetails(data.access_token));
        }
    }
}

export function getUserDetails() {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        let data = await AuthService.userDetails(token);
        if (data.error) {
            if (data.status === 500) {
                dispatch(logout());
            }
            dispatch({type: types.USER_DETAILS_FAILURE});
            dispatch(refreshToken(getState().authentication.refreshToken));
        }
        else {
            const now = Math.floor(Date.now() / 1000);
            if (data.mailConfirmationPending && now - data.mailConfirmationPending > 7 * 24 * 3600) {
                console.error("Logging out as email confirmation period expired.");
                dispatch(logout());
            } else {
                dispatch({type: types.USER_DETAILS_SUCCESS, userData: data});
                // Dispatch settings fetch after successful login and user data fetch.
                dispatch(settingsActions.getAppSettings());
            }
        }
    }
}

export function dismissMessages() {
    return async (dispatch, getState) => {
        dispatch({type: types.DISMISS_MESSAGES});
    }
}

export function updateUser(values) {
    return async(dispatch, getState) => {
        if (values.userSettings !== undefined) {
            let userSettings = { ...getState().authentication.userData.userSettings, ...values.userSettings };
            values.userSettings = userSettings;
        }
        let userData = { ...getState().authentication.userData, ...values };
        dispatch({type: types.LOADING_START});
        let data = await AuthService.updateUserDetails(getState().authentication.token, userData);
        if (data.error) {
            dispatch({type: types.UPDATE_USER_FAILURE, error: true, errorMessage: 'Salvarea datelor a eşuat.'});
        }
        else {
            dispatch({type: types.UPDATE_USER_SUCCESS, userData: data});
            // The user got updated so the token is not valid anymore. Refresh it.
            dispatch(refreshToken(getState().authentication.refreshToken));
        }
    }
}