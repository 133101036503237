import React from 'react';
import {Card, Grid, Tooltip, withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import * as paymentSelectors from '../../store/payment/reducer';
import * as paymentActions from '../../store/payment/actions';
import * as authenticationActions from '../../store/authentication/actions';
import Typography from "@material-ui/core/Typography";
import Helmet from "react-helmet";
import Address from "../../components/payment/Address";
import AddIcon from '@material-ui/icons/Add';
import Fab from "@material-ui/core/Fab";
import CardContent from "@material-ui/core/CardContent";
import NewAddressDialog from "../../components/payment/dialogs/NewAddressDialog";
import DeleteAddressDialog from "../../components/payment/dialogs/DeleteAddressDialog";
import CardActionArea from "@material-ui/core/CardActionArea";

const styles = theme => ({
    root: {},
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    emptyGrid: {
        padding: "0 !important",
    },
    emptyGridWithHeight: {
        height: 70,
    },
});

class Products extends React.Component {

    state = {
        addressAddDialog: false,
        addressDeleteDialog: false,
        addressForDeletion: ''
    }

    componentDidMount() {
        this.props.dispatch(paymentActions.getUserAddresses());
    }

    renderAddresses() {
        return Object.keys(this.props.addresses).map(key => {
            return(
                <Address
                    key={key}
                    addressData={this.props.addresses[key]}
                    selectAddress={this.selectAddress}
                    deleteAddress={this.deleteAddress}
                    showActions
                />
            );
        });
    }

    selectAddress = (id) => {
        this.props.dispatch(paymentActions.settingSet('selectedAddressId', id));
        this.props.history.push('/order');
    }

    confirmDelete = () => {
        this.props.dispatch(paymentActions.deleteAddress(this.state.addressForDeletion));
        this.closeDialogs();
    }

    deleteAddress = (id) => {
        this.setState({
            addressDeleteDialog: true,
            addressForDeletion: id,
        });
    }

    saveAddress = address => {
        this.props.dispatch(paymentActions.addNewUserAddress(address));
        this.props.dispatch(authenticationActions.getUserDetails())
        this.closeDialogs();

    }

    closeDialogs = () => {
        this.setState({
            addressAddDialog: false,
            addressDeleteDialog: false,
            addressForDeletion: '',
        })
    }

    render() {
        const {classes, addresses} = this.props;
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                <Helmet>
                    <title>Cumpără - Grile Politie</title>
                </Helmet>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Alege o adresă de facturare
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} className={classes.emptyGrid} />
                {addresses.length === 0 &&
                <Grid item xs={12}>
                    <Card>
                        <CardActionArea onClick={() => this.setState({addressAddDialog: true})}>
                            <CardContent>
                                <Typography variant="body1" component="p">
                                    Nu ai nicio adresă adăugată. Adaugă o adresă nouă pentru a putea continua.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                }
                {addresses.length > 0 && this.renderAddresses()}
                <Grid className={classes.emptyGridWithHeight} item xs={12} />
                <Tooltip title="Adaugă o adresă nouă" placement="left">
                    <Fab aria-label="Add" variant="extended" className={classes.fab} color="primary" onClick={() => this.setState({addressAddDialog: true})}>
                        <AddIcon /> Adaugă
                    </Fab>
                </Tooltip>
                <DeleteAddressDialog
                    open={this.state.addressDeleteDialog}
                    handleClose={this.closeDialogs}
                    deleteAddress={this.confirmDelete}
                />
                <NewAddressDialog
                    open={this.state.addressAddDialog}
                    closeDialog={this.closeDialogs}
                    saveAddress={this.saveAddress}
                />
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        addresses: paymentSelectors.getUserAddresses(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Products));