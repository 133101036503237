import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, withStyles} from "@material-ui/core";
import * as testsActions from "../store/tests/actions";
import * as testsSelectors from "../store/tests/reducer";
import * as settingsSelectors from "../store/settings/reducer";
import * as authenticationSelectors from "../store/authentication/reducer";
import Messages from "../components/messages/Messages";
import QuestionBox from "../components/QuestionBox";
import SummaryBox from "../components/SummaryBox";

const styles = theme => ({
    root: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            // paddingLeft: 73,
        },
    },
});

class Test extends Component {

    state = {
        currentQuestion: this.props.match.params.id ? this.props.match.params.id : 0,
        testId: this.props.match.params.type ? this.props.match.params.type : 'free',
        totalQuestions: 0,
    };

    handleMessageClose = () => {
        this.props.dispatch(testsActions.dismissMessages());
    };

    handleAnswer = (nid, responses, correct) => {
        if (correct) {
            if (typeof correct === 'boolean' || correct === 1) {
                this.props.dispatch(testsActions.setMessage('success', 'Răspuns corect!'));
            }
            else {
                this.props.dispatch(testsActions.setMessage('warning', 'Răspuns partial corect! (' + (correct * 100) + '%)'));
            }
        }
        else {
            this.props.dispatch(testsActions.setMessage('error', 'Răspuns greșit!'));
        }
        let answers = Object.assign([], this.props.answers);
        answers[this.state.currentQuestion] = {responses: responses, nid: nid, correct: correct};
        this.props.dispatch(testsActions.setCurrentTestAnswers(answers));
        this.props.dispatch(testsActions.newQuestionResponse(nid, responses, correct, this.state.testId))
    };

    nextQuestion = () => {
        let nextQuestion = this.state.currentQuestion;
        nextQuestion++;
        this.setState({currentQuestion: nextQuestion, totalQuestions: this.props.currentTest.length});
        if (this.props.totalQuestionsForCurrentTest === nextQuestion) {
            // Reached the last question. Save the test and show results.
            this.props.dispatch(testsActions.finishTest(this.state.testId));
            this.props.history.push('/tests/' + this.state.testId + '/results');
            return true;
        }
        if (this.props.currentTest.length === nextQuestion + 1 && this.props.totalQuestionsForCurrentTest !== nextQuestion + 1) {
            // Reached the last loaded question.
            // Load more questions and continue or show questions.
            this.props.dispatch(testsActions.continueCurrentTest(this.props.token));
            this.props.history.push('/tests/' + this.state.testId + '/question/' + nextQuestion);
        } else {
            this.props.history.push('/tests/' + this.state.testId + '/question/' + nextQuestion);
        }
    };

    componentDidMount() {

        if (this.props.match.params.view === 'results' && this.props.answers.length === 0) {
            this.props.dispatch(testsActions.getUserTestsAndQuestionsById(this.state.testId));
        }

        this.checkAlreadyAnswered();
        this.props.dispatch(testsActions.getFreeTest(this.props.token));
        // this.props.dispatch(testsActions.getParametersTest(this.props.token));
    }

    getTestById(id) {
        let test = false;
        this.props.userTests.map((testItem) => {
            // eslint-disable-next-line eqeqeq
            if (testItem.testId == id) {
                test = testItem;
            }
            return true;
        });
        return test;
    }

    getTestQuestions = () => {
        let test = this.getTestById(this.state.testId);
        let testQuestions = this.props.questionsResponses;
        if (testQuestions[test['nid']] !== undefined) {
            return testQuestions[test['nid']];
        }
    };

    handleBugReport = (data) => {
        this.props.dispatch(testsActions.setMessage('warning', 'Raportarea unui bug nu este disponibilă în această fereastră'));
    };

    checkAlreadyAnswered = () => {
        const {testInProgress} = this.props;
        if (testInProgress) {
            let alreadyAnswered = (this.state.currentQuestion !== testInProgress.questions.length);
            if (alreadyAnswered) {
                this.props.history.push('/tests/' + this.state.testId + '/question/' + testInProgress.questions.length);
                this.setState({
                    currentQuestion: testInProgress.questions.length,
                })
            }
        }
    };

    skipQuestion = () => {
        this.props.dispatch(testsActions.skipQuestion(this.state.currentQuestion));
    };

    render() {
        const { classes, error, errorMessage, messageType, freeTest, currentTest } = this.props;
        let test = currentTest;
        if (this.state.testId === 'free') {
            test = freeTest;
        }
        let userTest = this.getTestById(this.state.testId);
        return(
            <div>
                <Grid container justify="center" className={classes.root}>
                    <Grid item>
                        {this.props.match.params.view === 'question' &&
                        <QuestionBox
                            question={test[this.state.currentQuestion]}
                            questionNo={this.state.currentQuestion}
                            questionsCount={this.props.totalQuestionsForCurrentTest}
                            handleAnswer={this.handleAnswer}
                            nextQuestion={this.nextQuestion}
                            handleBugReport={this.handleBugReport}
                            skipQuestion={this.skipQuestion}
                            history={this.props.history}
                            multipleChoice={this.props.appExam !== 'police'}
                        />}
                        {this.props.match.params.view === 'results' && this.props.answers.length !== 0 &&
                        <SummaryBox
                            questions={test}
                            answers={this.props.answers}
                            handleBugReport={this.handleBugReport}
                        />}
                        {this.props.match.params.view === 'results' && this.props.answers.length === 0 &&this.getTestQuestions() !== undefined &&
                        <SummaryBox
                            questions={this.getTestQuestions()}
                            answers={userTest.responses}
                            handleBugReport={this.handleBugReport}
                            multipleChoice={this.props.appExam !== 'police'}
                        />}
                    </Grid>
                </Grid>
                <Messages
                    error={error}
                    variant={messageType}
                    message={errorMessage}
                    onClose={this.handleMessageClose}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const [error, errorMessage, messageType]  = testsSelectors.getError(state);
    return {
        freeTest: testsSelectors.getFreeTest(state),
        currentTest: testsSelectors.getCurrentTest(state),
        token: authenticationSelectors.getAccessToken(state),
        error: error,
        errorMessage: errorMessage,
        messageType: messageType,
        totalQuestionsForCurrentTest: testsSelectors.getTotalQuestionsForCurrentTest(state),
        testInProgress: testsSelectors.getUnfinishedTest(state),
        userTests: testsSelectors.getUserTests(state),
        questionsResponses: testsSelectors.getTestQuestions(state),
        appExam: settingsSelectors.getSelectedExam(state),
        answers: testsSelectors.getCurrentTestAnswers(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Test));