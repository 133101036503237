import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth/index';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import bfProductImage from '../../images/payment/product-bf.jpg'
import Product from "../payment/Product";
import Grid from "@material-ui/core/Grid";
import Markdown from "../Markdown";

const styles = theme => ({

});

const useStyles = props => makeStyles(theme => ({
    container: {
        padding: theme.spacing(),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    promotionColors: {
        backgroundColor: props.activePromotion !== undefined ? props.activePromotion.bgColor : '#FFF',
        color: props.activePromotion !== undefined ? props.activePromotion.textColor : '#000',
    }
}));

function PromoDialogContent(props) {
    const { activePromotion, isFull } = props;
    const classes = useStyles(props)();
    return (
        <Dialog
            fullScreen={isFull}
            maxWidth={"lg"}
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="form-dialog-title"
        >
            <AppBar className={classes.appBar + " " +classes.promotionColors}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        {activePromotion.name}
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.promotionColors}>
            <DialogContent className={classes.container }>
                <Typography variant="h4">Ofertă - {activePromotion.name}</Typography>
                <Markdown>
                    {activePromotion.description}
                </Markdown>
                <Grid container spacing={2}>
                    {props.renderedProducts}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.handleClose} variant="contained">
                    Închide
                </Button>
            </DialogActions>
            </div>
        </Dialog>
    );
}

class ActivePromotionDialog extends React.Component {

    renderProducts() {
        return Object.keys(this.props.products).map(key => {
            if (this.props.products[key].exam === this.props.selectedExam) {
                return (
                    <Product
                        key={key}
                        productInfo={this.props.products[key]}
                        selectProduct={this.selectProduct}
                        productImage={bfProductImage}
                    />
                );
            }
            return false;
        });
    }

    selectProduct = (id) => {
        // this.props.dispatch(paymentActions.settingSet('selectedProductId', id));
        this.props.history.push('/products');
    }

    render() {
        const { products } = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <>
                {products.length > 0 &&
                <PromoDialogContent
                    {...this.props}
                    isFull={isFull}
                    renderedProducts={this.renderProducts()}
                    selectProduct={this.selectProduct}
                />
                }
            </>
        );
    }
}

export default withWidth()(withStyles(styles)(ActivePromotionDialog));