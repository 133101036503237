import React from 'react';
import {connect} from 'react-redux';
import {Card, Grid, TextField, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Helmet from "react-helmet";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import * as authenticationActions from "../store/authentication/actions";
import * as testsSelectors from "../store/tests/reducer"
import * as testsActions from "../store/tests/actions"
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import CardActions from "@material-ui/core/CardActions";

const styles = theme => ({
    root: {},
    emptyGrid: {
        padding: "0 !important",
    },
    formControl: {
        width: '100%',
    },
});

class RegistrationStepTwo extends React.Component {
    state = {
        firstName: '',
        lastName: '',
        school: 0,
        touchedFields: {
            firstName: false,
            lastName: false,
        }
    };

    componentDidMount() {
        this.props.dispatch(authenticationActions.getUserDetails())
        this.props.dispatch(testsActions.getTestsTerms())
    }

    handleChange = event => {
        this.setState( {[event.target.name]: event.target.value});
    };

    updateUser = (e) => {
        e.preventDefault();
        let newValues = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
        };
        if (this.state.school !== 0) {
            newValues['userSettings'] = {
                school: this.state.school
            }
        }
        this.props.dispatch(authenticationActions.updateUser(newValues));
    };

    hasError = fieldName => {
        if (!this.state.touchedFields[fieldName]) {
            return false;
        }
        if (this.state[fieldName] === '') {
            return true;
        }
        return false;
    };

    canSubmit = () => {
        return this.state.firstName !== '' && this.state.lastName !== '';

    }

    touchField = (event, value) => {
        let touchedFields = Object.assign({}, this.state.touchedFields);
        touchedFields[event.target.name] = true;
        this.setState({
            touchedFields: touchedFields,
        })
    }

    render() {
        const {classes, schools} = this.props;
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                <Helmet>
                    <title>Pasul 2 - Grile Politie</title>
                </Helmet>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Datele tale
                            </Typography>
                            <Typography variant="body1" component="p">
                                Ne mai trebuie câteva informaţii despre tine. Acestea vor fi folosite în momentul facturării.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} className={classes.emptyGrid} />
                <Grid item xs={12} md={6}>
                    <Card>
                        <form noValidate autoComplete="off" onSubmit={this.updateUser}>
                            <CardContent>
                                <TextField id="firstName" name="firstName" label="Prenume" value={this.state.firstName} onChange={this.handleChange} onBlur={this.touchField} required fullWidth margin="normal" error={this.hasError('firstName')} />
                                <TextField id="lastName" name="lastName" label="Nume" value={this.state.lastName} onChange={this.handleChange} onBlur={this.touchField} required fullWidth margin="normal" error={this.hasError('lastName')}/>
                                <FormControl required className={classes.formControl}>
                                    <InputLabel id="county">Şcoala care te interesează</InputLabel>
                                    <Select
                                        value={this.state.school}
                                        onChange={this.handleChange}
                                        labelId="county"
                                        inputProps={{
                                            name: 'school',
                                            id: 'school',
                                        }}
                                    >
                                        <MenuItem key="0" value={0}>- Alege -</MenuItem>
                                        {schools.map(school => {
                                            return <MenuItem key={school.tid} value={school.tid}>{school.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </CardContent>
                            <CardActions>
                                <Button disabled={!this.canSubmit()} type="submit">Salvează</Button>
                            </CardActions>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        'schools': testsSelectors.getSchools(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RegistrationStepTwo));