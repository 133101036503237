import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    staticPages: localStorage.getItem('staticPages') ? JSON.parse(localStorage.getItem('staticPages')) : {},
    error: false,
    errorMessage: '',
    loading: false,
});

export default function reduce(state = initialState, action = {}) {
    let error = '';

    switch (action.type) {
        case types.STATIC_PAGE_LOAD_FAILURE:
            error = action.errorMessage;
            console.error(error.message);
            return state.merge({
                error: true,
                errorMessage: error.message,
                loading: false,
            });

        case types.DISMISS_MESSAGES:
            return state.merge({
                error: false,
            });

        case types.SET_MESSAGE:
            return state.merge({
                error: true,
                errorMessage: action.errorMessage,
                messageType: action.messageType,

            });

        case types.LOADING_START:
            return state.merge({
                loading: true,
            });
        case types.LOADING_STOP:
            return state.merge({
                loading: false,
            });

        case types.STATIC_PAGE_LOAD_SUCCESS:
            let staticPages = Object.assign({}, state.staticPages);
            staticPages[action.pageName] = action.staticPageContent;
            return state.merge({staticPages: staticPages});

        default:
            return state;
    }
}

export function getStaticPages(state) {
    return state.staticPages.staticPages;
}

export function getError(state) {
    return [state.bugs.error, state.tests.errorMessage, state.tests.messageType];
}

export function getLoading(state) {
    return state.bugs.loading;
}