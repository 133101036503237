export const PAID_TEST_LOAD_SUCCESS = 'tests.PAID_TEST_LOAD_SUCCESS';
export const PAID_TEST_LOAD_FAILURE = 'tests.PAID_TEST_LOAD_FAILURE';
export const FREE_TEST_LOAD_SUCCESS = 'tests.FREE_TEST_LOAD_SUCCESS';
export const FREE_TEST_LOAD_FAILURE = 'tests.FREE_TEST_LOAD_FAILURE';
export const TESTS_TERMS_LOAD_SUCCESS = 'tests.TESTS_TERMS_LOAD_SUCCESS';
export const TESTS_TERMS_LOAD_FAILURE = 'tests.TESTS_TERMS_LOAD_FAILURE';
export const TESTS_TERMS_STRUCTURE_LOAD_SUCCESS = 'tests.TESTS_TERMS_STRUCTURE_LOAD_SUCCESS';
export const TESTS_TERMS_STRUCTURE_LOAD_FAILURE = 'tests.TESTS_TERMS_STRUCTURE_LOAD_FAILURE';
export const TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_SUCCESS = 'tests.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_SUCCESS';
export const TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_FAILURE = 'tests.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_FAILURE';
export const TESTS_TERMS_YEARS_STRUCTURE_LOAD_SUCCESS = 'tests.TESTS_TERMS_YEARS_STRUCTURE_LOAD_SUCCESS';
export const TESTS_TERMS_YEARS_STRUCTURE_LOAD_FAILURE = 'tests.TESTS_TERMS_YEARS_STRUCTURE_LOAD_FAILURE';
export const DISMISS_MESSAGES = 'tests.DISMISS_MESSAGES';
export const LOADING_START = 'tests.LOADING_START';
export const LOADING_STOP = 'tests.LOADING_STOP';
export const USER_TESTS_LOADING_START = 'tests.USER_TESTS_LOADING_START';
export const USER_TESTS_LOADING_STOP = 'tests.USER_TESTS_LOADING_STOP';
export const SET_MESSAGE = 'tests.SET_MESSAGE';
export const NEW_ANSWER = 'tests.NEW_ANSWER';
export const USER_TESTS_ADD_FAILURE = 'tests.USER_TESTS_LOAD_FAILURE';
export const USER_TESTS_LOAD_FAILURE = 'tests.USER_TESTS_LOAD_FAILURE';
export const USER_TESTS_LOAD_SUCCESS = 'tests.USER_TESTS_LOAD_SUCCESS';
export const USER_TEST_QUESTIONS_LOAD_FAILURE = 'tests.USER_TEST_QUESTIONS_LOAD_FAILURE';
export const USER_TEST_QUESTIONS_LOAD_SUCCESS = 'tests.USER_TEST_QUESTIONS_LOAD_SUCCESS';
export const USER_TESTS_DELETE_FAILURE = 'tests.USER_TESTS_DELETE_FAILURE';
export const USER_TESTS_DELETE_SUCCESS = 'tests.USER_TESTS_DELETE_SUCCESS';
export const SET_TEST_NAME = 'tests.SET_TEST_NAME';
export const SET_LAST_TEST_PARAMS = 'tests.SET_LAST_TEST_PARAMS';
export const SET_QUESTIONS_COUNT = 'tests.SET_QUESTIONS_COUNT';
export const FINISH_TEST = 'tests.FINISH_TEST';
export const START_TEST = 'tests.START_TEST';
export const SKIP_QUESTION = 'tests.SKIP_QUESTION';
export const TIME_IS_UP = 'tests.TIME_IS_UP';
export const SYNC_USER_TESTS = 'tests.SYNC_USER_TESTS';
export const REMOVE_LOCAL_TESTS = 'tests.REMOVE_LOCAL_TESTS';
export const SET_CURRENT_TEST_ANSWERS = 'tests.SET_CURRENT_TEST_ANSWER';