import React from 'react';
import {CardHeader, withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CircularLoaderContent from "./CircularLoaderContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import StatisticsTop10Dialog from "./dialogs/StatisticsTop10Dialog";
import StatisticsInfoDialog from "./dialogs/StatisticsInfoDialog";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from '@material-ui/icons/Info';
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    avatar: {
        backgroundColor: theme.palette.primary[500],
        color: '#FFF',
        padding: theme.spacing(),
    },
});

class StatisticsTop extends React.Component {

    state = {
        top10DialogOpen: false,
        infoDialogOpen: false,
    }

    openTop10Dialog = () => {
        this.setState({
            top10DialogOpen: true,
        })
    }

    openInfoDialog = () => {
        this.setState({
            infoDialogOpen: true,
        })
    }

    closeDialogs = () => {
        this.setState({
            top10DialogOpen: false,
            infoDialogOpen: false,
        })
    }

    buildMonth() {
        const d = new Date();
        const month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
        return (d.getFullYear() + '/' + month);
    }

    render() {
        const { statistics, lightVariant } = this.props;
        let grade, points;
        let statisticsMonth = this.buildMonth();
        const loading = statistics.user_statistics === undefined;
        let hasTests = true;
        if (!loading) {
            if (statistics.user_statistics.userResults[statisticsMonth] !== undefined) {
                let rawGrade = statistics.user_statistics.userResults[statisticsMonth].correct * 100 / statistics.user_statistics.userResults[statisticsMonth].all
                grade = Math.round(rawGrade * 100) / 100;
                points = Math.round(rawGrade * statistics.user_statistics.userResults[statisticsMonth].all);
            }
            else {
                hasTests = false;
            }
        }
        const {classes} = this.props;
        return (
            <Card>
                { loading &&
                    <CircularLoaderContent small />
                }
                {!loading && hasTests &&
                    <>
                        <CardHeader
                            title={"Ai " + points + " puncte, care te plasează pe poziţia " + statistics.user_statistics.position[statisticsMonth].points}
                            subheader={"Media ta generală este " + grade + ". După medie, eşti pe locul " + statistics.user_statistics.position[statisticsMonth].grades}
                            avatar={
                                <Paper className={classes.avatar}>
                                    #{statistics.user_statistics.position[statisticsMonth].points}
                                </Paper>
                            }
                            action={ !lightVariant &&
                                <IconButton onClick={this.openInfoDialog}>
                                    <InfoIcon />
                                </IconButton>
                            }
                        />
                        {
                        !lightVariant &&
                            <>
                                    <CardActions disableSpacing>
                                        <Button onClick={this.openTop10Dialog}>Vezi Clasament</Button>
                                    </CardActions>
                                    <StatisticsTop10Dialog open={this.state.top10DialogOpen} handleClose={this.closeDialogs} statistics={statistics}/>
                                    <StatisticsInfoDialog open={this.state.infoDialogOpen} handleClose={this.closeDialogs} />
                            </>
                        }
                    </>
                }
                {!loading && !hasTests &&
                <>
                    <CardHeader
                        title={"Poziţia ta în clasament nu poate fi determinată încă. Trebuie să mai faci teste."}
                        avatar={
                            <Paper className={classes.avatar}>
                                #
                            </Paper>
                        }
                        action={ !lightVariant &&
                        <IconButton onClick={this.openInfoDialog}>
                            <InfoIcon />
                        </IconButton>
                        }
                    />
                    {
                        !lightVariant &&
                        <>
                            <CardActions disableSpacing>
                                <Button onClick={this.openTop10Dialog}>Vezi Clasament</Button>
                            </CardActions>
                            <StatisticsTop10Dialog open={this.state.top10DialogOpen} handleClose={this.closeDialogs} statistics={statistics}/>
                            <StatisticsInfoDialog open={this.state.infoDialogOpen} handleClose={this.closeDialogs} />
                        </>
                    }
                </>
                }
            </Card>
        );
    }
}

export default withStyles(styles)(StatisticsTop);