import React from 'react';
import {Card, Grid, withStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import defaultProductImage from '../../images/payment/product-default.jpg'
import Avatar from "@material-ui/core/Avatar";
import deepOrange from "@material-ui/core/colors/deepOrange";

const styles = theme => ({
    root: {},
    card: {
        display: 'flex',
    },
    media: {
        height: 140,
    },
    discounted: {
        textDecoration: 'line-through',
    },
    discount: {
        marginTop: theme.spacing(1),
        width: theme.spacing(6),
        height: theme.spacing(6),
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    }
});

class Product extends React.Component {

    selectProduct = (id) => {
        if (this.props.selectProduct !== undefined) {
            this.props.selectProduct(id);
        }
        else {
            this.props.editSelectionPage('/products');
        }
    }

    getDiscount(productInfo) {
        let discount = 100 - (productInfo.finalPrice * 100 / productInfo.fullPrice);
        return Math.round(discount);
    }

    render() {
        const {classes, productInfo, productImage} = this.props;
        return (
            <Grid item xs={12} sm={6} md={3}>
                <Card className={classes.root}>
                    <CardActionArea onClick={() => this.selectProduct(productInfo.id)}>
                        <CardMedia
                            className={classes.media}
                            image={productImage === undefined ? defaultProductImage : productImage}
                            title="Imagine Produs"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {productInfo.name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {productInfo.description}
                            </Typography>
                            <Grid container spacing={1}>
                                <Grid item xs={9}>
                                    <Typography gutterBottom variant="h6" component="h3" className={classes.discounted}>
                                        {productInfo.fullPrice} LEI
                                    </Typography>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {productInfo.finalPrice} LEI
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Avatar className={classes.discount}>
                                        <Typography>-{this.getDiscount(productInfo)}%</Typography>
                                    </Avatar>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(Product);