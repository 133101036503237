import encryption from '../helpers/encryption';

const API_URL = process.env.REACT_APP_API_URL;

class TestsService {

    async freeTest(token) {
        const url = '/tests/free';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            }
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async randomTest(token) {
        const url = '/tests/random';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            }
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async parametersTest(token, params) {
        let url = '/tests/get';
        url += (params.subject !== undefined && params.subject !== '') ? '/' + params.subject : '';
        url += (params.chapter !== undefined && params.chapter !== '') ? '/' + params.chapter : '';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            }
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async newConfiguredTest(token, params) {
        let url = '/grile_api/get-configured/?_format=json';
        const method = 'POST';
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: JSON.stringify(params),
        });
        if (response.status === 200) {
            data = await response.json();
            if (!Array.isArray(data)) {
                let decrypted = encryption.decrypt(data, 'n0T53cUr3');
                data = JSON.parse(decrypted);
            }
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
    
    async parametersPreviousYearsTest(token, params) {
        let url = '/tests/prev-years';
        url += (params.subject !== undefined && params.subject !== '') ? '/' + params.subject : '';
        url += (params.subCategory !== undefined && params.subCategory !== '') ? '/' + params.subCategory : '';
        url += (params.variant !== undefined && params.variant !== '') ? '/' + params.variant : '';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            }
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async testsTerms(token) {
        const url = '/grile_api/site/terms';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async testsTermsStructure(token) {
        const url = '/grile_api/terms_structure_resource';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async testsTermsChaptersStructure(token, exam) {
        // TODO: Normalize api urls.
        let url = '/grile_api/terms_chapters_structure_resource';
        if (exam === 'medschool') {
            url = '/grile-api/med-chapters-structure-resource';
        }
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async testsTermsYearsStructure(token, exam) {
        let url = '/grile_api/terms_years_structure_resource';
        if (exam === 'medschool') {
            url = '/grile-api/med-years-structure-resource';
        }
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async userTests(token, exam) {
        const url = '/grile_api/tests_resource/?exam=' + exam + '&&_format=json';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async testQuestions(token, nid = 0) {
        const url = '/grile_api/test_questions_resource/' + nid + '?_format=json';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async addUserTest(token, newTest) {
        const url = '/grile_api/tests_resource/?_format=json';
        const method = 'POST';
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        const body = newTest;
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: body,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async deleteUserTest(token, testId) {
        const url = '/grile_api/tests_resource/?_format=json';
        const method = 'POST';
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        const body = JSON.stringify({
            "testId": testId,
            "deleteTest": true
        });
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: body,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new TestsService();
