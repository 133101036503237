import React, {Component} from 'react';
import {
    withStyles,
    LinearProgress,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import PropTypes from "prop-types";
import { teal } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import QuestionBody from './QuestionBody';
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';


const styles = theme => ({

    choice: {
        borderRadius: 4,
    },
    questionText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: 'solid 1px ' + teal[500],
        borderRadius: 4,
    },
    choices: {
        margin: theme.spacing(2),
    },
    control: {
        padding: theme.spacing(2),
    },
    progress: {
        height: theme.spacing(),
        backgroundColor: theme.palette.error.dark,
    },
    choiceCorrect: {
        backgroundColor: green[600],
    },
    choiceIncorrect: {
        backgroundColor: theme.palette.error.dark,
    },
    correct: {
        color: green[600],
    },
    incorrect: {
        color: theme.palette.error.dark,
    },
    partialCorrect: {
        color: amber[700],
    },
    choiceNotImportant: {
        // backgroundColor: '#fafafa',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '80%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
});

const defaultOptions = {
        A: false,
        B: false,
        C: false,
        D: false,
    };

class SummaryBoxContent extends Component {

    state = {
        questions: [],
        expanded: null,
        correctAnswersCount: 0,
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    getCorrectAnswers(field) {
        let correctAnswers = Object.assign({}, defaultOptions);
        field.map((text, index) => {
            correctAnswers[text.toUpperCase()] = true;
            return true;
        });
        return correctAnswers;
    }

    componentDidMount() {
        let correctAnswersCount = 0;
        let answers = this.props.answers.map((answer, index) => {
            if (answer.correct) {
                if (typeof answer.correct === 'boolean') {
                    correctAnswersCount++;
                }
                else {
                    correctAnswersCount += answer.correct;
                }
            }
            return {
                correctAnswers: this.getCorrectAnswers(this.props.questions[index].field_correct_answer),
                choices: answer.responses,
                correct: answer.correct,
            }
        });
        this.setState({questions: answers, correctAnswersCount: correctAnswersCount})
    }

    handleClick = event => {
        this.props.handleClick(event);
    };

    wasAnswered = questionAnswer => {
        let answered = false;
        Object.values(questionAnswer.choices).map(selected => {
            answered = answered || selected;
            return true;
        });
        return answered;
    };

    getAnswerIcon = questionAnswer => {
        if (!this.wasAnswered(questionAnswer)) {
            return (
                <TimerOffIcon className={this.props.classes.incorrect}/>
            );
        }
        let correct = questionAnswer.correct;
        if (typeof correct === 'boolean') {
            // Means we have true and false as values.
            if (correct) {
                return (
                    <CheckCircleIcon className={this.props.classes.correct}/>
                );
            } else {
                return (<ErrorIcon className={this.props.classes.incorrect}/>);
            }
        }
        else {
            // Means we have percentage from the answer.
            if (correct) {
                if (correct < 1) {
                    return(
                        <strong className={this.props.classes.partialCorrect}>{Math.round(correct * 100)}%</strong>
                    );
                }
                return (
                    <strong className={this.props.classes.correct}>{Math.round(correct * 100)}%</strong>
                );
            } else {
                return (<strong className={this.props.classes.incorrect}>0%</strong>);
            }
        }
    };

    getTrimmedText = text => {
        const regex = /(<([^>]+)>)/ig;
        let result = text.replace(regex, '');
        result = result.replace('&nbsp', ' ');
        return result;
    };

    render() {
        const {classes, questions} = this.props;
        const { expanded } = this.state;
        let length = Object.keys(this.state.questions).length;
        let percentage = Math.round(this.state.correctAnswersCount * 100 / length);
        return(
            <div>
                <LinearProgress variant="determinate" value={percentage} className={classes.progress} />
                <Typography variant="body1" gutterBottom color="textSecondary">
                    Scorul tău la acest test este de {percentage}% ({length} întrebări).
                </Typography>
                {this.state.questions.map((questionAnswer, index) => {
                    return(
                        <Accordion key={'panel' + index} expanded={expanded === index} onChange={this.handleChange(index)}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography className={classes.heading}>{this.getTrimmedText(questions[index].body).substr(0, 50)}...</Typography>
                                <Typography className={classes.secondaryHeading}>{this.getAnswerIcon(questionAnswer)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <QuestionBody
                                    choices={questionAnswer.choices}
                                    correctAnswers={questionAnswer.correctAnswers}
                                    question={questions[index]}
                                    answered={true}
                                    handleChange={this.handleChange}
                                    multipleChoice={this.props.multipleChoice}
                                />
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </div>
        );
    }
}

SummaryBoxContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(SummaryBoxContent));