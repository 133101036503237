import React from 'react';
import {Checkbox, FormControl, FormControlLabel, Typography, withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import {teal} from "@material-ui/core/colors";

const API_URL = process.env.REACT_APP_API_URL;

const notLoadedText = '▒▒▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒▒▒▒▒▒▒▒ ▒▒▒▒▒▒▒ ▒▒ ▒▒▒▒▒▒▒▒▒▒▒▒';
const letterIndex = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

const styles = theme => ({

    choice: {
        borderRadius: 4,
    },
    questionText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: 'solid 1px ' + teal[500],
        borderRadius: 4,
    },
    choices: {
        margin: theme.spacing(2),
    },
    choiceCorrect: {
        backgroundColor: '#43a047',
        fontWeight: 'bolder',
    },
    choiceIncorrect: {
        backgroundColor: '#d32f2f',
        fontWeight: 'bolder',
    },
    choiceNotImportant: {
        // backgroundColor: '#fafafa',
    }
});

class QuestionBody extends React.Component {

    choiceResult = index => {
        let type = this.props.classes.choiceNotImportant;
        if (this.props.choices[letterIndex[index]] !== this.props.correctAnswers[letterIndex[index]] ) {
            type = this.props.classes.choiceIncorrect;
        }
        if (this.props.correctAnswers[letterIndex[index]]) {
            type = this.props.classes.choiceCorrect;
        }
        if (this.props.multipleChoice) {
            if (this.props.choices[letterIndex[index]] !== this.props.correctAnswers[letterIndex[index]] ) {
                type = this.props.classes.choiceIncorrect;
            }
            if (this.props.choices[letterIndex[index]] === this.props.correctAnswers[letterIndex[index]] ) {
                type = this.props.classes.choiceCorrect;
            }
        }
        return type;
    };

    parseImages(text) {
        let choice = text.split('src="');
        if (choice.length > 1) {
            let parsedText = choice[0];
            for (let i = 1; i < choice.length; i++) {
                parsedText += 'src="' + API_URL + choice[i];
            }
            return parsedText;
        }
        return text;
    }

    render() {
        const {question, classes} = this.props;
        return(
            <span>
                <Typography variant="caption" gutterBottom color="textSecondary">
                            { question &&
                            "(#" + question.nid + ") "
                            + question.field_exam
                            + ", " + question.field_subject
                            + (question.field_chapter ? ", " + question.field_chapter : '')
                            + (question.field_sub_category ? ", " + question.field_sub_category : '')
                            + (question.field_exam_variant ? ", " + question.field_exam_variant : '')
                            }
                        </Typography>
                        <Typography
                            className={classes.questionText}
                            variant="body1"
                            dangerouslySetInnerHTML={{ __html: question ? this.parseImages(question.body) : notLoadedText}}
                        />
                        <FormControl component="fieldset" className={classes.choices} fullWidth>
                            { question && question.field_answer.map((text, index) => (
                                    <FormControlLabel key={index}
                                                      control={
                                                          <Checkbox
                                                              checked={this.props.choices[letterIndex[index]]}
                                                              onChange={this.props.handleChange(index)}
                                                              disabled={this.props.answered}
                                                              value={letterIndex[index]}
                                                              color="primary"
                                                          />
                                                      }
                                                      className={(this.props.answered && this.choiceResult(index) ? this.choiceResult(index) : '') + ' ' + classes.choice}
                                                      label={
                                                          <Typography
                                                              className={this.props.answered && this.choiceResult(index) ? this.choiceResult(index) : ''}
                                                              dangerouslySetInnerHTML={{__html: letterIndex[index] + ": " + this.parseImages(text)}}
                                                          />
                                                      }
                                    />
                            ))}
                        </FormControl>
            </span>
        );
    }
}

QuestionBody.propTypes = {
    classes: PropTypes.object.isRequired,
    question: PropTypes.object,
    choices: PropTypes.object.isRequired,
    answered: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
};

export default (withStyles(styles)(QuestionBody));