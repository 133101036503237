import * as types from './actionTypes';
import StaticPagesService from '../../services/staticPages'

export function getStaticPage(page) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('staticPages') !== null && localStorage.getItem('staticPageLoaded') !== null && (parseInt(localStorage.getItem('staticPageLoaded')) + (600 * 1000) > Date.now())) {
            data = JSON.parse(localStorage.getItem('staticPages'));
            console.log('loading static page from local cache');
        }
        else {
            console.log('getting static page from server');
            data = await StaticPagesService.getStaticPage(page);
            localStorage.setItem('staticPageLoaded', Date.now());
        }
        if (data.error) {
            dispatch({type: types.STATIC_PAGE_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.STATIC_PAGE_LOAD_SUCCESS, staticPageContent: data, pageName: page});
        }
    }
}