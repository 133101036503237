import * as types from './actionTypes';
import BugsService from '../../services/bugs'

export function getUserBugs(token, forced = false) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (!forced && localStorage.getItem('userBugs') !== null && localStorage.getItem('userBugsLoaded') !== null && (parseInt(localStorage.getItem('userBugsLoaded')) + (600 * 1000) > Date.now())) {
            data = JSON.parse(localStorage.getItem('userBugs'));
            console.log('loading reported bugs from local storage');
        }
        else {
            console.log('getting user bugs from server');
            data = await BugsService.getUserBugs(token);
            localStorage.setItem('userBugsLoaded', Date.now());
        }
        if (data.error) {
            dispatch({type: types.USER_BUGS_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_BUGS_LOAD_SUCCESS, userBugs: data});
        }
    }
}

export function reportBug(token, bugData) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await BugsService.reportBug(token, bugData);
        if (data.error) {
            dispatch({type: types.USER_BUGS_REPORT_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_BUGS_LOAD_SUCCESS, userBugs: data});
        }
    }
}