import React from 'react';
import {Card} from "@material-ui/core";
import {withStyles} from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import image from "../images/testStart/test-in-progress.jpg"
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import TestDetails from "../containers/TestDetails";
import Timer from "../containers/Timer";
import TimerIcon from "@material-ui/icons/Timer";

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        position: 'relative',
    },
    progress: {
        height: 8,
    },
    right: {
        float: 'right',
        position: 'absolute',
        right: theme.spacing(3),

    },
});


class TestInProgress extends React.Component {

    getDetails = () => {
        let details = {};
        switch (this.props.test.configurationOptions.type) {
            case 'simulate':
                details.type = 'Simulare examen';
                break;

            case 'prevYears':
                details.type = 'Din anii precedenţi';
                break;

            case 'chapters':
                details.type =  'Testare pe capitole';
                break;

            case 'checkKnowledge':
                details.type = 'Verificare cunoştinţe';
                break;

            default:
                return '';
        }

        return(
            <Typography>Tip Test: {details.type}</Typography>
        );
    };

    handleContinue = () => {
        const {test} = this.props;
        this.props.history.push('/tests/' + test.reactId + '/question/' + test.questions.length);
    };

    render() {
        const {classes, test} = this.props;
        const progress = Math.round(test.questions.length * 100 / test.configurationOptions.questionsCount);
        return (
            <div >
                <Card className={classes.card}>
                    <CardMedia
                        className={classes.cover}
                        image={image}
                        title="In progress image"
                        onClick={this.props.onClick}
                    />
                        <div className={classes.details}>
                            <LinearProgress variant="determinate" value={progress} className={classes.progress}/>
                            <CardContent className={classes.content}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={4}>
                                        <Typography>
                                            Ai un test neterminat.
                                        </Typography>
                                        <Typography>
                                            Ai parcurs {progress}% din test.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TestDetails
                                            configurationOptions={test.configurationOptions}
                                        />
                                    </Grid>
                                </Grid>

                            </CardContent>
                            <div className={classes.controls}>
                                <IconButton aria-label="play" onClick={this.handleContinue}>
                                    <PlayArrowIcon className={classes.playIcon} />
                                </IconButton>
                                <IconButton aria-label="stop" onClick={this.props.finishTest}>
                                    <StopIcon className={classes.playIcon} />
                                </IconButton>
                                <IconButton className={classes.right} aria-label="remaining-time">
                                    <Timer><TimerIcon className={classes.playIcon} /></Timer>
                                </IconButton>
                                {/*<div className={classes.right}><TimerIcon/><Timer/></div>*/}
                            </div>
                        </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(TestInProgress);