import React from 'react';
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LinkGeneric from "./linkComponents/LinkGeneric";
import PriceTag from '@material-ui/icons/LocalOffer';
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import deepOrange from "@material-ui/core/colors/deepOrange";
import Markdown from "./Markdown";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = props => makeStyles(theme => ({
    offer: {
        backgroundColor: theme.palette.success.main,
        padding: theme.spacing(),
    },
    promoOfferClass: {
        backgroundColor: props.activePromotion !== undefined ? props.activePromotion.bgColor : '#FFF',
        color: props.activePromotion !== undefined ? props.activePromotion.textColor : '#000',
        padding: theme.spacing(),
    },
    discount: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
        padding: theme.spacing(1),
    }
}));

export default function Offer(props) {
    let activePromotion = props.activePromotion;
    const {userData, selectedExam} = props;
    const classes = useStyles(props)();
    const date = new Date();
    if (activePromotion) {
        return(
            <Card className={classes.promoOfferClass}>
                <CardActionArea onClick={() => props.openPopup('promotion')}>
                    <Grid container>
                        <Grid item xs={2} md={1}><PriceTag fontSize={"large"}/></Grid>
                        <Grid item xs={4} md={4}>
                            <Typography variant="h4">{activePromotion.name}</Typography>
                        </Grid>
                        <Grid item xs={6} md={7}>
                            <Markdown>{activePromotion.description}</Markdown>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Card>
        )
    }
    if (userData.subscriptionExpiration !== undefined && userData.subscriptionExpiration[selectedExam] && userData.subscriptionExpiration[selectedExam] * 1000 > date.getTime()) {
        const expirationDate = new Intl.DateTimeFormat('ro', {}).format(new Date(userData.subscriptionExpiration[selectedExam] * 1000));
        return (
            <Paper className={classes.offer}>
                <Typography variant="body2" color="textPrimary" component="p">
                    Contul tău este premium până în {expirationDate}.
                </Typography>
            </Paper>
        );
    }
    return (
        <LinkGeneric to="/products">
            <Paper className={classes.offer}>
                <Typography variant="body2" color="textPrimary" component="p">
                    Fii <strong>PREMIUM</strong>! Cumpără un abonament pentru a avea acces nelimitat!.
                </Typography>
            </Paper>
        </LinkGeneric>
    );
}