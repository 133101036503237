import * as types from './actionTypes';
import PaymentService from '../../services/payment';
import * as authenticationActions from "../../store/authentication/actions";

export function getProducts() {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        let data = await PaymentService.getProducts(token);
        if (data.error) {
            dispatch({type: types.PAYMENT_PRODUCTS_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.PAYMENT_PRODUCTS_LOAD_SUCCESS, products: data});
        }
    }
}

export function getUserOrders(retry = false) {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        const refreshToken = getState().authentication.refreshToken;
        let data = await PaymentService.getOrders(token);
        if (data.error) {
            if (!retry) {
                console.log('Refreshing token after user save.');
                await dispatch(authenticationActions.refreshToken(refreshToken));
                await dispatch(getUserOrders(true));
            } else {
                dispatch({type: types.PAYMENT_ORDERS_LOAD_FAIL, errorMessage: data.rawData});
            }
        }
        else {
            dispatch({type: types.PAYMENT_ORDERS_LOAD_SUCCESS, userOrders: data});
        }
    }
}

export function createOrder(productId, addressId) {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        let data = await PaymentService.createOrder(token, productId, addressId);
        if (data.error) {
            dispatch({type: types.PAYMENT_ORDER_CREATE_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.PAYMENT_ORDER_CREATE_SUCCESS, orderData: data});
        }
    }
}

export function getUserAddresses() {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        let data = await PaymentService.getAddresses(token);
        if (data.error) {
            dispatch({type: types.PAYMENT_ADDRESSES_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.PAYMENT_ADDRESSES_LOAD_SUCCESS, userAddresses: data});
        }
    }
}

export function addNewUserAddress(address) {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        let data = await PaymentService.addNewAddress(token, address);
        if (data.error) {
            dispatch({type: types.PAYMENT_ADDRESS_ADD_FAIL, errorMessage: data.rawData});
        }
        else {
            await dispatch(authenticationActions.getUserDetails());
            dispatch({type: types.PAYMENT_ADDRESS_ADD_SUCCESS, userAddresses: data});
        }
    }
}

export function deleteAddress(addressId) {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        const refreshToken = getState().authentication.refreshToken;
        let data = await PaymentService.deleteAddress(token, addressId);
        if (data.error) {
            dispatch({type: types.PAYMENT_ADDRESS_DELETE_FAIL, errorMessage: data.rawData});
        }
        else {
            await dispatch(authenticationActions.refreshToken(refreshToken));
            await dispatch(getUserAddresses());
            dispatch({type: types.PAYMENT_ADDRESS_DELETE_SUCCESS, addressId: addressId});
        }

    }
}

export function settingSet(settingName, settingValue) {
    return (dispatch, getState) => {
        dispatch({type: types.PAYMENT_SETTING_SET, settingName: settingName, settingValue: settingValue});
    }
}
