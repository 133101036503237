export const LOGIN_SUCCESS = 'authentication.LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'authentication.LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'authentication.REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'authentication.REGISTER_FAILURE';
export const LOGOUT = 'authentication.LOGOUT';
export const USER_DETAILS_SUCCESS = 'authentication.USER_DETAILS_SUCCESS';
export const USER_DETAILS_FAILURE = 'authentication.USER_DETAILS_FAILURE';
export const LOADING_START = 'authentication.LOADING_START';
export const LOADING_STOP = 'authentication.LOADING_STOP';
export const DISMISS_MESSAGES = 'authentication.DISMISS_MESSAGES';
export const REFRESH_TOKEN_FAILURE = 'authentication.REFRESH_TOKEN_FAILURE';
export const REFRESH_TOKEN_SUCCESS = 'authentication.REFRESH_TOKEN_SUCCESS';
export const UPDATE_USER_FAILURE = 'authentication.UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'authentication.UPDATE_USER_SUCCESS';