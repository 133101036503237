import React, {Component} from 'react';
import {connect} from 'react-redux';
import {default as PoliceNewTestBox} from "../components/police/NewTestBox";
import {default as MedschoolNewTestBox} from "../components/medschool/NewTestBox";
import {Grid, withStyles} from "@material-ui/core";
import * as testsActions from "../store/tests/actions";
import * as testsSelectors from "../store/tests/reducer";
import * as authenticationSelectors from "../store/authentication/reducer";
import * as authenticationActions from "../store/authentication/actions";
import Helmet from "react-helmet";
import Messages from "../components/messages/Messages";
import UserTests from "../components/UserTests";
import TestResultsDialog from "../components/dialogs/TestResultsDialog";
import PremiumDialog from "../components/dialogs/PremiumDialog";
import ExistingFreeTest from "../components/dialogs/ExistingFreeTest";
import TestInProgress from "../components/TestInProgress";
import FinishTestInProgressDialog from "../components/dialogs/FinishTestInProgressDialog";
import ChartWrapper from "../components/charts/ChartWrapper";
import * as statisticsActions from "../store/statistics/actions";
import * as statisticsSelectors from "../store/statistics/reducer";
import StatisticsTop from "../components/StatisticsTop";
import LinkGeneric from "../components/linkComponents/LinkGeneric";
import Offer from "../components/Offer";
import ActivePromotionDialog from "../components/dialogs/ActivePromotionDialog";
import * as paymentActions from "../store/payment/actions";
import * as paymentSelectors from "../store/payment/reducer";
import * as settingsSelectors from "../store/settings/reducer";
import {STATISTICS_UNAVAILABLE} from "../components/Constants";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class Tests extends Component {

    state = {
        testResultsOpen: false,
        premiumWarning: false,
        existingFreeTestDialogOpen: false,
        finishTestDialogOpen: false,
        activePromotionDialog: false,
        settingsLoaded: false,
    };

    handleMessageClose = () => {
        this.props.dispatch(testsActions.dismissMessages());
    };

    static getDerivedStateFromProps(props, state) {
        // Force update the component when the settings are loaded.
        if (!state.settingsLoaded && state.activePromotionDialog !== !!props.activePromotion) {
            let date = new Date(props.userData.todayAccess * 1000);
            let now = new Date();
            console.log(props.userData.todayAccess + 120);
            console.log(Date.now()/1000);
            // If the user accessed today the app and it was in the last 2 minutes, show the popup.
            if (date.getDate() === now.getDate() && (props.userData.todayAccess + 120 > Date.now() / 1000)) {
                return {
                    activePromotionDialog: !!props.activePromotion,
                    settingsLoaded: true,
                };
            }
            return {
                settingsLoaded: true,
            };
        }
        return null;
    }

    handleTestStart = (type, params = false, questions = 10) => {
        this.props.dispatch(testsActions.setQuestionsCount(questions));

        switch (type) {
            case 'chapters':
            case 'prevYears':
            case 'checkKnowledge':
            case 'simulate':
                this.props.dispatch(testsActions.getNewConfiguredTest(this.props.token, params, this.props.currentTestId));
                this.props.history.push('/tests/' + this.props.currentTestId + '/question/0');
                return;

            default:
                console.log(type);
                return;
        }
    };

    loadStatisticsData() {
        this.props.dispatch(statisticsActions.loadTermsData());
        this.props.dispatch(statisticsActions.getStatistics());
    }

    loadTestsData() {
        this.props.dispatch(authenticationActions.getUserDetails(this.props.token));
        this.props.dispatch(testsActions.getTestsTerms(this.props.token));
        this.props.dispatch(testsActions.getTestsTermsStructure(this.props.token));
        this.props.dispatch(testsActions.getTestsTermsChaptersStructure(this.props.token));
        this.props.dispatch(testsActions.getTestsTermsYearsStructure(this.props.token));
        this.props.dispatch(testsActions.getUserTests());
        this.props.dispatch(testsActions.syncUserTests())
    }

    componentDidMount() {
        this.loadTestsData();
        this.loadStatisticsData();
        this.props.dispatch(paymentActions.getProducts());
    }

    getTestQuestions = (test) => {
        if (test === undefined) {
            return [];
        }
        let testQuestions = this.props.testQuestions;
        if (testQuestions[test['nid']] !== undefined) {
            return testQuestions[test['nid']];
        }
    };

    getTestById(id) {
        let test = false;
        this.props.userTests.map((testItem) => {
            // eslint-disable-next-line eqeqeq
            if (testItem.testId == id) {
                test = testItem;
            }
            return true;
        });
        return test;
    }

    handleTestDetails = (test) => {
        let { testQuestions } = this.props;
        let selectedTest = this.getTestById(test.id);
        if (testQuestions[selectedTest['nid']] === undefined) {
            this.props.dispatch(testsActions.getTestQuestions(this.props.token, test.id));
        }
        this.setState({
            testResultsOpen: true,
            testResultsOpenedTestId: selectedTest.testId,
        });
    };
    closeTestDetails = () => {
        this.setState({
            testResultsOpen: false,
        });
    };
    closeDialog = () => {
        this.setState({
            premiumWarning: false,
            existingFreeTestDialogOpen: false,
            finishTestDialogOpen: false,
            activePromotionDialog: false,
        });
    };

    deleteFreeTest = () => {
        this.props.dispatch(testsActions.deleteUserTest(this.props.token, this.props.freeTestFinished));
        this.props.dispatch(testsActions.setMessage('success', 'Testul a fost şters. Încearcă din nou!'));
        this.setState({
            existingFreeTestDialogOpen: false,
        });
    };
    handlePremium = () => {
        this.props.dispatch(testsActions.setMessage('warning', 'Premium not implemented yet.'));
        this.setState({
            premiumWarning: false,
        });
    };

    openFinishTestDialog = () => {
        this.setState({finishTestDialogOpen: true});
    };

    openPopup = (type) => {
        if (type === 'promotion') {
            this.setState({activePromotionDialog: true});
        }
    };

    finishTest = () => {
        this.setState({finishTestDialogOpen: false});
        this.props.dispatch(testsActions.finishTest(this.props.unfinishedTest.reactId)).then(() => {
                this.loadTestsData();
            }
        );
    };

    render() {
        const { classes, error, errorMessage, messageType, userTests, subjects, testsTerms, termsStructure,
            termsChaptersStructure, termsYearsStructure, questionsCount, termsByTid, unfinishedTest,
            statistics, termsData, userData, selectedExam} = this.props;
        let finishedTests = userTests.filter(test => {
            return test.finished !== false;
        });
        let termsLoaded = (Object.keys(termsYearsStructure).length > 0) && (Object.keys(termsChaptersStructure).length > 0) ;
        return(
            <div className={classes.root}>
                <Helmet>
                    <title>Teste - Grile Politie</title>
                </Helmet>
                <Grid container spacing={3}>
                    {/*TODO: Make this configurable from drupal!*/}
                    <Grid item xs={12} md={12}>
                        <Offer
                            openPopup={this.openPopup}
                            userData={userData}
                            activePromotion={this.props.activePromotion}
                            selectedExam={selectedExam}
                        />
                    </Grid>
                    {!STATISTICS_UNAVAILABLE &&
                        <Grid item xs={12}>
                            <LinkGeneric to="/statistics"><StatisticsTop statistics={statistics} lightVariant /></LinkGeneric>
                        </Grid>
                    }
                    {   unfinishedTest &&
                        <Grid item xs={12} md={12}>
                            <TestInProgress
                                test={unfinishedTest}
                                history={this.props.history}
                                finishTest={this.openFinishTestDialog}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} md={6}>
                        {selectedExam === 'police' && termsLoaded &&
                        <PoliceNewTestBox
                            handleClick={this.handleTestStart}
                            subjects={subjects}
                            testsTerms={testsTerms}
                            termsStructure={termsStructure}
                            termsChaptersStructure={termsChaptersStructure}
                            termsYearsStructure={termsYearsStructure}
                            questionsCount={questionsCount}
                            termsByTid={termsByTid}
                            testInProgress={unfinishedTest}
                            finishTestInProgress={this.openFinishTestDialog}
                            nextTestId={this.props.currentTestId}
                            handleLessonsClick={() => this.props.history.push('lessons')}
                        />
                        }
                        {selectedExam === 'medschool' && termsLoaded &&
                        <MedschoolNewTestBox
                            handleClick={this.handleTestStart}
                            subjects={subjects}
                            testsTerms={testsTerms}
                            termsStructure={termsStructure}
                            termsChaptersStructure={termsChaptersStructure}
                            termsYearsStructure={termsYearsStructure}
                            questionsCount={questionsCount}
                            termsByTid={termsByTid}
                            testInProgress={unfinishedTest}
                            finishTestInProgress={this.openFinishTestDialog}
                            nextTestId={this.props.currentTestId}
                        />
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <UserTests
                                    testsData={finishedTests.reverse()}
                                    testsLoading={this.props.testsLoading}
                                    handleTestDetails={this.handleTestDetails}
                                    multipleChoice={this.props.selectedExam !== 'police'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ChartWrapper type="gradesEvolution" statistics={statistics} termsData={termsData} withReference />
                            </Grid>
                        </Grid>
                    </Grid>
                    {this.state.testResultsOpen &&
                    <TestResultsDialog open={this.state.testResultsOpen}
                                       handleClose={this.closeTestDetails}
                                       questions={this.getTestQuestions(this.getTestById(this.state.testResultsOpenedTestId))}
                                       test={this.getTestById(this.state.testResultsOpenedTestId)}
                                       multipleChoice={this.props.selectedExam !== 'police'}
                    />
                    }
                    <ExistingFreeTest open={this.state.existingFreeTestDialogOpen}
                                      handleClose={this.closeDialog}
                                      handleDeleteTest={this.deleteFreeTest}
                    />
                    <PremiumDialog     open={this.state.premiumWarning}
                                       handleClose={this.closeDialog}
                                       handlePremium={this.handlePremium}
                    />
                    <FinishTestInProgressDialog
                        open={this.state.finishTestDialogOpen}
                        handleClose={this.closeDialog}
                        finishTest={this.finishTest}
                    />
                    {this.props.activePromotion &&
                        <ActivePromotionDialog
                            open={this.state.activePromotionDialog}
                            activePromotion={this.props.activePromotion}
                            handleClose={this.closeDialog}
                            products={this.props.products}
                            history={this.props.history}
                            selectedExam={this.props.selectedExam}
                        />
                    }
                </Grid>
                <Messages
                    error={error}
                    variant={messageType}
                    message={errorMessage}
                    onClose={this.handleMessageClose}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    const [error, errorMessage, messageType]  = testsSelectors.getError(state);
    return {
        freeTest: testsSelectors.getFreeTest(state),
        token: authenticationSelectors.getAccessToken(state),
        premiumUser: authenticationSelectors.isUserPremium(state),
        error: error,
        errorMessage: errorMessage,
        messageType: messageType,
        userTests: testsSelectors.getUserTests(state),
        testsLoading: testsSelectors.getTestsLoading(state),
        testQuestions: testsSelectors.getTestQuestions(state),
        userTestsForSync: testsSelectors.getUserTestsToSync(state),
        currentTestId: testsSelectors.getCurrentUserTestId(state),
        testsTerms: testsSelectors.getTestsTerms(state),
        subjects: testsSelectors.getSubjects(state),
        termsStructure: testsSelectors.getTermsStructure(state),
        termsChaptersStructure: testsSelectors.getTermsChaptersStructure(state),
        termsYearsStructure: testsSelectors.getTermsYearsStructure(state),
        questionsCount: testsSelectors.getTestsQuestionsCount(state),
        lastTestParams: testsSelectors.getLastTestParams(state),
        termsByTid: testsSelectors.getTermsKeyedWithTids(state),
        freeTestFinished: testsSelectors.freeTestFinished(state),
        unfinishedTest: testsSelectors.getUnfinishedTest(state),
        statistics: statisticsSelectors.getStatistics(state),
        termsData: statisticsSelectors.getTermsKeyedWithTids(state),
        userData: authenticationSelectors.getUserData(state),
        products: paymentSelectors.getProducts(state),
        activePromotion: settingsSelectors.activePromotion(state),
        selectedExam: settingsSelectors.getSelectedExam(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Tests));