import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import coverImage from "../../../images/testStart/check-knowledge.jpg";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
        marginTop: theme.spacing(1),
    },
    dialogTitle: {
        backgroundImage: "url(" + coverImage + ")",
        backgroundSize: 'cover',
        height: '25vh',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    actions: {
        margin: theme.spacing(2),
    },
});

class ConfigureYearsTestDialog extends React.Component {

    state = {
        name: '',
        questionsCount: 30,
        timeLimit: 30,
        timing: false,
        subject: '',
        university: '',
        year: '',
        chapter: '',
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        switch (event.target.name) {
            case 'subject':
                this.setState({
                    university: '',
                    subCategory: '',
                    variant: '',
                });
                break;

            case 'university':
                this.setState({
                    subCategory: '',
                    variant: '',
                });
                break;

            case 'subCategory':
                this.setState({
                    variant: '',
                });
                break;

            default:
                break;
        }

    };

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleNumberChange = number => {
        this.setState({questionsCount: number});
    };

    getSubjects = () => {
        let subjects = [];
        let termsYearsStructureKeys = Object.keys(this.props.termsYearsStructure);
        for (let key in termsYearsStructureKeys) {
            let tid = termsYearsStructureKeys[key];
            let term = Object.assign({}, this.props.termsByTid[tid]);
            term.questions = this.props.termsYearsStructure[tid].questionsCount;
            subjects.push(term);
        }
        return subjects;
    };

    getUniversities = () => {
        let subject = this.state.subject;
        let universities = [];
        let termsYearsStructureKeys = Object.keys(this.props.termsYearsStructure[subject].children);
        for (let key in termsYearsStructureKeys) {
            let tid = termsYearsStructureKeys[key];
            let term = Object.assign({}, this.props.termsByTid[tid]);
            term.questions = this.props.termsYearsStructure[subject].children[tid].questionsCount;
            universities.push(term);
        }
        return universities;
    };

    getYears = () => {
        const {subject, university} = this.state;
        const yearsData = this.props.termsYearsStructure[subject].children[university].children;
        let years = [];
        let termsYearsStructureKeys = Object.keys(this.props.termsYearsStructure[subject].children[university].children);
        for (let key in termsYearsStructureKeys) {
            let tid = termsYearsStructureKeys[key];
            // Create object because we don't have terms for this.
            let term = {
                questions: yearsData[tid].questionsCount,
                name: tid,
                tid: tid,
            }
            years.push(term);
        }
        return years;
    };

    getChapters = () => {
        const {subject, university, year} = this.state;
        const chaptersData = this.props.termsYearsStructure[subject].children[university].children[year].children;
        let chapters = [];
        let termsYearsStructureKeys = Object.keys(chaptersData);
        for (let key in termsYearsStructureKeys) {
            let tid = termsYearsStructureKeys[key];
            let term = Object.assign({}, this.props.termsByTid[tid]);
            term.questions = chaptersData[tid].questionsCount;
            chapters.push(term);
        }
        return chapters;
    };

    handleStartTest = () => {
        this.props.handleStart({
            type: 'prevYears',
            exam: 'medschool',
            ...this.state
        });
    };

    render() {
        const { classes } = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <div>
                <Dialog
                    fullScreen={isFull}
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialogTitle} id="form-dialog-title"/>
                    <DialogTitle id="form-dialog-title">Alege din anii precedenţi</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Alege un test dat în anii precedenţi, să vezi cum te-ai fi descurcat!
                        </DialogContentText>
                        <TextField
                            onChange={this.handleChange}
                            margin="dense"
                            name="name"
                            id="name"
                            label="Nume Test"
                            defaultValue={"Test " + this.props.nextTestId}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="subject">Materie</InputLabel>
                            <Select
                                value={this.state.subject}
                                onChange={this.handleChange}
                                label="Materie"
                                inputProps={{
                                    name: 'subject',
                                    id: 'subject',
                                }}
                            >
                                <MenuItem value="">
                                    <em>- Alege Materia -</em>
                                </MenuItem>
                                {Object.values(this.props.termsYearsStructure).length > 0 && this.getSubjects().map(subject => {
                                    return (
                                        <MenuItem key={subject.tid} value={subject.tid}>{subject.name} ({subject.questions})</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {
                            this.state.subject !== ''  &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="sub-category">Alege Universitatea</InputLabel>
                                <Select
                                    value={this.state.university}
                                    onChange={this.handleChange}
                                    label="Alege Universitatea"
                                    inputProps={{
                                        name: 'university',
                                        id: 'university',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>- Alege universitatea -</em>
                                    </MenuItem>
                                    {this.getUniversities().map(university => {
                                        return (
                                            <MenuItem key={university.tid} value={university.tid}>{university.name} ({university.questions})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.subject !== '' && this.state.university !== '' &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="sub-category">Alege Anul</InputLabel>
                                <Select
                                    value={this.state.year}
                                    onChange={this.handleChange}
                                    label="Alege Anul"
                                    inputProps={{
                                        name: 'year',
                                        id: 'year',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>- Toţi anii -</em>
                                    </MenuItem>
                                    {this.getYears().map(year => {
                                        return (
                                            <MenuItem key={year.tid} value={year.tid}>{year.name} ({year.questions})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.subject !== '' && this.state.university !== '' && this.state.year !== '' && this.getChapters().length > 0 &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="variant">Capitol</InputLabel>
                                <Select
                                    value={this.state.chapter}
                                    onChange={this.handleChange}
                                    label="Capitol"
                                    inputProps={{
                                        name: 'chapter',
                                        id: 'chapter',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>- Alege -</em>
                                    </MenuItem>
                                    {this.getChapters().map(chapter => {
                                        return (
                                            <MenuItem key={chapter.tid} value={chapter.tid}>{chapter.name} ({chapter.questions})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {/*TODO: Extract this into component for reuse.*/}
                        {
                            <>
                                <FormControl variant="outlined">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.timing}
                                                onChange={this.handleCheckboxChange}
                                                inputProps={{
                                                    name: 'timing',
                                                    id: 'timing',
                                                }}
                                            />
                                        }
                                        label="Contratimp"
                                    />

                                </FormControl>
                                {
                                    this.state.timing &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="timeLimit">Vei avea un timp limitat pentru a rezolva toate întrebarile testului.</InputLabel>
                                        <Select
                                            value={this.state.timeLimit}
                                            onChange={this.handleChange}
                                            label="Vei avea un timp limitat pentru a rezolva toate întrebarile testului."
                                            inputProps={{
                                                name: 'timeLimit',
                                                id: 'timeLimit',
                                            }}
                                        >
                                            <MenuItem value={30}>30 de minute</MenuItem>
                                            <MenuItem value={60}>O oră</MenuItem>
                                            <MenuItem value={90}>90 de minute</MenuItem>
                                            <MenuItem value={120}>Două ore</MenuItem>
                                            <MenuItem value={180}>Trei ore</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                        {
                            ((this.state.subject !== '' && this.state.university !== '' && this.state.year !== '' && this.getChapters().length === 0)
                            || (this.state.subject !== '' && this.state.university !== '' && this.state.year !== '' && this.state.chapter !== '' )) &&
                            <Button variant="contained" onClick={this.handleStartTest} color="primary">
                                Începe Test
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(ConfigureYearsTestDialog));