import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    userBugs: localStorage.getItem('userBugs') ? JSON.parse(localStorage.getItem('userBugs')) : [],
    error: false,
    errorMessage: '',
    loading: false,
});


export default function reduce(state = initialState, action = {}) {
    let error = '';

    switch (action.type) {
        case types.USER_BUGS_LOAD_FAILURE:
        case types.USER_BUGS_REPORT_FAILURE:
            error = action.errorMessage;
            console.error(error.message);
            return state.merge({
                error: true,
                errorMessage: error.message,
                loading: false,
            });

        case types.DISMISS_MESSAGES:
            return state.merge({
                error: false,
            });

        case types.SET_MESSAGE:
            return state.merge({
                error: true,
                errorMessage: action.errorMessage,
                messageType: action.messageType,

            });

        case types.LOADING_START:
            return state.merge({
                loading: true,
            });
        case types.LOADING_STOP:
            return state.merge({
                loading: false,
            });

        case types.USER_BUGS_LOAD_SUCCESS:
            localStorage.setItem('userBugs', JSON.stringify(action.userBugs));
            return state.merge({userBugs: action.userBugs, error: false, loading: false});

        default:
            return state;
    }
}

export function getUserBugs(state) {
    return state.bugs.userBugs;
}

export function getError(state) {
    return [state.bugs.error, state.tests.errorMessage, state.tests.messageType];
}

export function getLoading(state) {
    return state.bugs.loading;
}