export const PAYMENT_ADDRESSES_LOAD_SUCCESS = 'payment.PAYMENT_ADDRESSES_LOAD_SUCCESS';
export const PAYMENT_ADDRESSES_LOAD_FAIL = 'payment.PAYMENT_ADDRESSES_LOAD_FAIL';
export const PAYMENT_ADDRESS_ADD_SUCCESS = 'payment.PAYMENT_ADDRESS_ADD_SUCCESS';
export const PAYMENT_ADDRESS_ADD_FAIL = 'payment.PAYMENT_ADDRESS_ADD_FAIL';
export const PAYMENT_ADDRESS_DELETE_SUCCESS = 'payment.PAYMENT_ADDRESS_DELETE_SUCCESS';
export const PAYMENT_ADDRESS_DELETE_FAIL = 'payment.PAYMENT_ADDRESS_DELETE_FAIL';
export const PAYMENT_ORDERS_LOAD_SUCCESS = 'payment.PAYMENT_ORDERS_LOAD_SUCCESS';
export const PAYMENT_ORDERS_LOAD_FAIL = 'payment.PAYMENT_ORDERS_LOAD_FAIL';
export const PAYMENT_ORDER_CREATE_SUCCESS = 'payment.PAYMENT_ORDER_CREATE_SUCCESS';
export const PAYMENT_ORDER_CREATE_FAIL = 'payment.PAYMENT_ORDER_CREATE_FAIL';
export const PAYMENT_PRODUCTS_LOAD_SUCCESS = 'payment.PAYMENT_PRODUCTS_LOAD_SUCCESS';
export const PAYMENT_PRODUCTS_LOAD_FAIL = 'payment.PAYMENT_PRODUCTS_LOAD_FAIL';
export const PAYMENT_SETTING_SET = 'payment.PAYMENT_SETTING_SET';