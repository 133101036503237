import React from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TestCard from "../TestCard";
import ConfigureYearsTestDialog from "../dialogs/medschool/ConfigureYearsTestDialog";
import ConfigureChaptersTestDialog from "../dialogs/medschool/ConfigureChaptersTestDialog";
import chaptersImage from "../../images/testStart/chapters-1.jpg"
import previousYearsImage from "../../images/testStart/previous-years.jpg"
import checkKnowledge from "../../images/testStart/check-knowledge.jpg"
import examSimulation from "../../images/testStart/exam-simulation.jpg"
import ConfigureCheckKnowledgeDialog from "../dialogs/medschool/ConfigureCheckKnowledgeDialog";
import ConfigureSimulateTestDialog from "../dialogs/medschool/ConfigureSimulateTestDialog";

const styles = theme => ({

    card: {
        height: '100%',
        // maxWidth: 700,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    control: {
        padding: theme.spacing(2),
    },
    media: {
        height: 0,
        paddingTop: '7%', // 16:9
        [theme.breakpoints.up('md')]: {
            paddingTop: '30%',
        },
    },
    actions: {
        display: 'flex',
    },
});

class NewTestBox extends React.Component {

    state = {
        configureChaptersDialogOpen: false,
        configureYearsDialogOpen: false,
        checkKnowledgeDialogOpen: false,
        simulateTestDialogOpen: false,
        questionsCountDialogOpen: false,
        testType: '',
        currentParams: {},
    };

    handleClick = (event, params = false, questionsCount = 10) => {
        this.props.handleClick(event, params, questionsCount);
    };

    handleOpenConfigYearsDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            configureYearsDialogOpen: true,
        });
    };

    handleOpenConfigChaptersDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            configureChaptersDialogOpen: true,
        });
    };

    handleOpenConfigCheckKnowledgeDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            checkKnowledgeDialogOpen: true,
        });
    };

    handleOpenConfigSimulateDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            simulateTestDialogOpen: true,
        });
    };

    handleStartTest = (questionsCount) => {
        this.handleClick(this.state.testType, this.state.currentParams, questionsCount);
        this.setState({
            questionsCountDialogOpen: false,
        });
    };

    handleCloseConfigDialog = () => {
        this.setState({
            configureDialogOpen: false,
            configureChaptersDialogOpen: false,
            configureYearsDialogOpen: false,
            simulateTestDialogOpen: false,
            checkKnowledgeDialogOpen: false,
            questionsCountDialogOpen: false,
            randomDialogOpen: false,
        });
    };

    checkTestInProgress = () => {
        if (this.props.testInProgress) {
            this.props.finishTestInProgress();
            return true;
        }
        return false;
    };

    handleStartConfiguredTest = (configurations) => {
        this.handleClick(configurations.type, configurations);
        this.setState({
            configureDialogOpen: false,
            configureChaptersDialogOpen: false,
            configureYearsDialogOpen: false,
            simulateTestDialogOpen: false,
            checkKnowledgeDialogOpen: false,
        });
    };

    render() {
        const {termsChaptersStructure, termsYearsStructure, termsByTid} = this.props;
        return (
            <div className="new-test-box">
                <Grid item>
                    <TestCard
                        testType="Pe capitole"
                        testTypeDescription="Testează-ţi cunoştinţele la capitolele selectate"
                        onClick={this.handleOpenConfigChaptersDialog}
                        image={chaptersImage}
                    />
                    <TestCard
                        testType="Ani precedenţi"
                        testTypeDescription="Testează-ţi cunoştinţele pornind de la examenele din anii anteriori"
                        onClick={this.handleOpenConfigYearsDialog}
                        image={previousYearsImage}
                    />
                    <TestCard
                        testType="Verifică-ţi cunoştinţele"
                        testTypeDescription="Generator de teste dinamice în funcţie de opţiunile tale"
                        onClick={this.handleOpenConfigCheckKnowledgeDialog}
                        image={checkKnowledge}
                    />
                    <TestCard
                        testType="Simulează Examen"
                        testTypeDescription="Teste finale, pregătitoare pentru examen"
                        onClick={this.handleOpenConfigSimulateDialog}
                        image={examSimulation}
                    />
                    <>
                        <ConfigureYearsTestDialog
                            testsTerms={this.props.testsTerms}
                            open={this.state.configureYearsDialogOpen}
                            handleClose={this.handleCloseConfigDialog}
                            handleStart={this.handleStartConfiguredTest}
                            termsByTid={termsByTid}
                            termsYearsStructure={termsYearsStructure}
                            nextTestId={this.props.nextTestId}
                        />
                        <ConfigureChaptersTestDialog
                            testsTerms={this.props.testsTerms}
                            open={this.state.configureChaptersDialogOpen}
                            handleClose={this.handleCloseConfigDialog}
                            handleStart={this.handleStartConfiguredTest}
                            termsByTid={termsByTid}
                            termsChaptersStructure={termsChaptersStructure}
                            nextTestId={this.props.nextTestId}
                        />
                        <ConfigureCheckKnowledgeDialog
                            testsTerms={this.props.testsTerms}
                            open={this.state.checkKnowledgeDialogOpen}
                            handleClose={this.handleCloseConfigDialog}
                            handleStart={this.handleStartConfiguredTest}
                            termsByTid={termsByTid}
                            termsChaptersStructure={termsChaptersStructure}
                            nextTestId={this.props.nextTestId}
                        />
                        <ConfigureSimulateTestDialog
                            testsTerms={this.props.testsTerms}
                            open={this.state.simulateTestDialogOpen}
                            handleClose={this.handleCloseConfigDialog}
                            handleStart={this.handleStartConfiguredTest}
                            termsByTid={termsByTid}
                            termsYearsStructure={termsYearsStructure}
                            nextTestId={this.props.nextTestId}
                        />
                    </>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(NewTestBox);