import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class PremiumDialog extends React.Component {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Informaţii despre statistici
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Punctele reprezintă rezultatul înmulţirii între media ta generală şi numărul de întrebări la care ai răspuns.</Typography>
                        <Typography>Clasamentul pe bază de puncte este mai relevant datorită faptului că ia în calcul şi numărul de întrebări la care ai răspuns.</Typography>
                        <Typography>Vei face parte din clasamentul pe bază de note în momentul în care ai răspuns la cel puţin 100 de întrebări.</Typography>
                        <Typography>Notă: Statisticile generale se actualizează o dată pe oră. Daca ai facut un test şi nu ţi s-a schimbat poziţia în clasament, încearcă mai târziu.</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(PremiumDialog);