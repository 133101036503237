const API_URL = process.env.REACT_APP_API_URL;


class BugsService {
    async reportBug(token, bug) {
        const url = '/grile_api/bug_reports/?_format=json';
        const method = 'POST';
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        };
        const body = bug;
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: body,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async getUserBugs(token) {
        const url = '/grile_api/bug_reports/?_format=json';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new BugsService();