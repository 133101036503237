import React from 'react';
import {connect} from 'react-redux';
import {Card, Grid, withStyles} from "@material-ui/core";
import * as paymentSelectors from '../../store/payment/reducer';
import * as paymentActions from '../../store/payment/actions';
import Product from "../../components/payment/Product";
import Address from "../../components/payment/Address";
import Fab from "@material-ui/core/Fab";
import PlaceOrderIcon from "@material-ui/icons/ShoppingCart";
import Helmet from "react-helmet";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

const styles = theme => ({
    root: {
        position: "relative",
    },
    center: {
        margin: "auto",
        position: "absolute",
        left: "50%",
        transform: "translateX(-50%)",
    },
    emptyGrid: {
        padding: "0 !important",
    },
});

class OrderSummary extends React.Component {

    state = {
        acceptTerms: false,
    }

    constructor(props) {
        super(props);
        this.orderForm = React.createRef();
    }

    componentDidMount() {
        this.props.dispatch(paymentActions.getUserAddresses());
        this.props.dispatch(paymentActions.getProducts());
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.orderCreation !== undefined && this.props.orderCreation !== {}) {
            console.log('Order can be placed.');
            this.orderForm.current.submit();
        }
    }

    editSelection = page => {
        this.props.history.push(page);
    }

    placeOrder = () => {
        this.props.dispatch(paymentActions.createOrder(this.props.selectedProduct.id, this.props.selectedAddressIndex));
    }

    handleCheckbox = (event, value) => {
        this.setState({
            acceptTerms: value,
        })
    }

    openTermsConditions = () => {
        window.open("https://grile-examene.ro/termeni-conditii", '_blank');
    }

    render() {
        const {classes, selectedProduct, selectedAddress, orderCreation} = this.props;
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                <Helmet>
                    <title>Cumpără - Grile Politie</title>
                </Helmet>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Sumarul comenzii tale
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid className={classes.emptyGrid} item xs={12}/>
                {selectedProduct !== false &&
                <Product productInfo={selectedProduct} editSelectionPage={this.editSelection} />
                }
                {selectedAddress !== false &&
                <Address addressData={selectedAddress} editSelectionPage={this.editSelection} />
                }
                <Grid className={classes.emptyGrid} item xs={12}/>
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.acceptTerms}
                                        onChange={this.handleCheckbox}
                                        inputProps={{ 'name': 'acceptTerms' }}
                                    />
                                }
                                label={<span>Accept Termenii şi condiţiile <Button onClick={this.openTermsConditions}>Vezi Documentul</Button></span>}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.center}>
                    <Fab aria-label="Add" variant="extended"  color="primary" onClick={this.placeOrder} disabled={!this.state.acceptTerms}>
                        <PlaceOrderIcon />
                        Plasează
                    </Fab>
                    </div>
                </Grid>
                {orderCreation !== undefined && orderCreation !== {} &&
                    <form action={orderCreation.paymentUrl} method="POST" ref={this.orderForm}>
                        <input type="text" name="env_key" defaultValue={orderCreation.env_key} />
                        <input type="text" name="data" defaultValue={orderCreation.data} />
                        <input type="submit" />
                    </form>
                }
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        selectedAddress: paymentSelectors.getSelectedAddress(state),
        selectedAddressIndex: paymentSelectors.getSelectedAddressIndex(state),
        selectedProduct: paymentSelectors.getSelectedProduct(state),
        orderCreation: paymentSelectors.getOrderCreation(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OrderSummary));