import React, {Component} from 'react';
import {
    Button,
    Card,
    Grid,
    CardContent,
    withStyles, CardActions, LinearProgress, Divider, Typography
} from "@material-ui/core";
import PropTypes from "prop-types";
import { teal } from '@material-ui/core/colors';
import QuestionBody from './QuestionBody';
import ReportQuestionBugForm from "../containers/dialogs/ReportQuestionBugForm";
import Timer from "../containers/Timer";

const styles = theme => ({

    card: {
        height: '100%',
        maxWidth: 800,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(),
    },
    choice: {
        borderRadius: 4,
    },
    questionText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        border: 'solid 1px ' + teal[500],
        borderRadius: 4,
    },
    choices: {
        margin: theme.spacing(2),
    },
    control: {
        padding: theme.spacing(2),
    },
    progress: {
        height: theme.spacing(),
    },
    progressText: {
        float: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    rightIcons: {
        marginLeft: 'auto',
    },
    choiceCorrect: {
        backgroundColor: '#43a047',
    },
    choiceIncorrect: {
        backgroundColor: '#d32f2f',
    },
    choiceNotImportant: {
        // backgroundColor: '#fafafa',
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
});

const letterIndex = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

class QuestionBox extends Component {

    state = {
        completed: this.props.questionNo * 100 / this.props.questionsCount,
        choices: this.getChoices(),
        correctAnswers: this.getCorrectAnswers(),
        answered: false,
        selectedChoice: this.props.multipleChoice,
        reloading: false,
    };

    getChoices() {
        let choices = {};
        if (this.props.question === undefined) {
            return choices;
        }
        for (let i = 0; i < this.props.question.field_answer.length; i++) {
            choices[letterIndex[i]] = false;
        }
        return choices;
    }

    getCorrectAnswers() {
        let correctAnswers = this.getChoices();
        if (this.props.question === undefined) {
            return correctAnswers;
        }
        for (let i = 0; i < this.props.question.field_correct_answer.length; i++) {
            correctAnswers[this.props.question.field_correct_answer[i].toUpperCase()] = true;
        }
        return correctAnswers;
    }

    resetData() {
        this.setState({
            completed: (this.props.questionNo + 1) * 100 / this.props.questionsCount,
            choices: this.getChoices(),
            correctAnswers: this.getChoices(),
            selectedChoice: this.props.multipleChoice,
            answered: false,
            reloading: false,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.question !== undefined && this.props.question !== undefined) {
            if (JSON.stringify(prevProps.question.field_answer) !== JSON.stringify(this.props.question.field_answer)) {
                this.resetData();
            }
        }
    }


    setReloading() {
        this.setState({
            reloading: true,
        });
    }

    nextQuestion = () => {
        this.setReloading();
        this.props.nextQuestion();
    };

    skipQuestion = () => {
        this.setReloading();
        this.props.skipQuestion();
    };

    calculateCorrectAnswers(choices, correctAnswers) {
        const choicesCount = Object.keys(choices).length;
        let grade = 0;
        for (let i in choices) {
            if (choices[i] === correctAnswers[i]) {
                grade += 1/choicesCount;
            }
        }
        return grade;
    }

    handleAnswer = () => {
        let correctAnswers = this.state.correctAnswers;
        this.props.question.field_correct_answer.map((text, index) => {
            correctAnswers[text.toUpperCase()] = true;
            return true;
        });
        this.setState({correctAnswers: correctAnswers, answered: true});
        let correct;
        correct = JSON.stringify(this.state.choices) === JSON.stringify(this.state.correctAnswers);
        if (this.props.multipleChoice) {
            correct = this.calculateCorrectAnswers(this.state.choices, this.state.correctAnswers);
        }
        else {
            correct = JSON.stringify(this.state.choices) === JSON.stringify(this.state.correctAnswers);
        }
        this.props.handleAnswer(this.props.question.nid , this.state.choices, correct);
    };

    handleChange = index => event => {
        if (this.props.multipleChoice) {
            let newState = this.state.choices;
            newState[letterIndex[index]] = event.target.checked;
            this.setState({choices: newState});
        } else {
            let newState = this.getChoices();
            newState[letterIndex[index]] = event.target.checked;
            // Check if the user submitted any answer. If not do not allow submit.
            let selectedChoice = false;
            Object.values(newState).map((value) => {
                selectedChoice = selectedChoice || value;
                return value;
            });
            this.setState({ choices: newState, selectedChoice: selectedChoice });
        }
    };

    handleClick = event => {
        this.props.handleClick(event);
    };

    render() {
        const {classes, question} = this.props;
        return(
            <Grid item>
                <Card
                    className={classes.card}
                >
                    <CardContent>
                        <Typography component="p" variant="h4"><Timer history={this.props.history}>Timp Rămas: </Timer></Typography>
                        <Divider className={classes.divider}/>
                        <LinearProgress variant="determinate" value={this.state.completed} className={classes.progress} />
                        <span className={classes.progressText}> {this.props.questionNo + 1} / {this.props.questionsCount}</span>
                        {!this.state.reloading &&
                        <QuestionBody
                            question={question}
                            choices={this.state.choices}
                            answered={this.state.answered}
                            correctAnswers={this.state.correctAnswers}
                            choiceResult={this.choiceResult}
                            handleChange={this.handleChange}
                            multipleChoice={this.props.multipleChoice}
                        />
                        }
                    </CardContent>
                    <CardActions>
                        {!this.state.answered &&
                        <>
                            <Button color="primary" className={classes.button} disabled={!this.state.selectedChoice} onClick={this.handleAnswer}>
                                Răspunde
                            </Button>
                            <Button color="primary" className={classes.button} onClick={this.skipQuestion}>
                                Răspunde mai târziu
                            </Button>
                        </>
                        }
                        {this.state.answered &&
                        <Button color="primary" className={classes.button} onClick={this.nextQuestion}>
                            Următoarea întrebare
                        </Button>
                        }
                        <ReportQuestionBugForm question={question} />
                    </CardActions>
                </Card>
            </Grid>
        );
    }
}

QuestionBox.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(QuestionBox));