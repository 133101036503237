import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import {Edit as EditIcon, DeleteForever} from '@material-ui/icons'
import {Typography, Divider} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = theme => ({
    paper: {
        padding: theme.spacing(1, 1),
    },
    title: {
        padding: theme.spacing(0, 1),
    },
    table: {
        fontFamily: theme.typography.fontFamily,
    },
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    tableRow: {
        cursor: 'pointer',
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.primary[100],
        },
    },
    tableCell: {
        flex: 1,
    },
    noClick: {
        cursor: 'initial',
    },
});

class MuiVirtualizedTable extends React.PureComponent {
    getRowClassName = ({ index }) => {
        const { classes, rowClassName, onRowClick } = this.props;

        return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
            [classes.tableRowHover]: index !== -1 && onRowClick != null,
        });
    };

    cellRenderer = ({ cellData, columnIndex = null }) => {
        const { columns, classes, rowHeight, onRowClick } = this.props;
        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, {
                    [classes.noClick]: onRowClick == null,
                })}
                variant="body"
                style={{ height: rowHeight }}
                align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
            >
                {cellData}
            </TableCell>
        );
    };

    headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
        const { headerHeight, columns, classes, sort } = this.props;
        const direction = {
            [SortDirection.ASC]: 'asc',
            [SortDirection.DESC]: 'desc',
        };

        const inner =
            !columns[columnIndex].disableSort && sort != null ? (
                <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
                    {label}
                </TableSortLabel>
            ) : (
                label
            );

        return (
            <TableCell
                component="div"
                className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
                variant="head"
                style={{ height: headerHeight }}
                align={columns[columnIndex].numeric || false ? 'right' : 'left'}
            >
                {inner}
            </TableCell>
        );
    };

    render() {
        const { classes, columns, ...tableProps } = this.props;
        return (
            <AutoSizer>
                {({ height, width }) => (
                    <Table
                        className={classes.table}
                        height={height}
                        width={width}
                        {...tableProps}
                        rowClassName={this.getRowClassName}
                    >
                        {columns.map(({ cellContentRenderer = null, className, dataKey, ...other }, index) => {
                            let renderer;
                            if (cellContentRenderer != null) {
                                renderer = cellRendererProps =>
                                    this.cellRenderer({
                                        cellData: cellContentRenderer(cellRendererProps),
                                        columnIndex: index,
                                    });
                            } else {
                                renderer = this.cellRenderer;
                            }

                            return (
                                <Column
                                    key={dataKey}
                                    headerRenderer={headerProps =>
                                        this.headerRenderer({
                                            ...headerProps,
                                            columnIndex: index,
                                        })
                                    }
                                    className={classNames(classes.flexContainer, className)}
                                    cellRenderer={renderer}
                                    dataKey={dataKey}
                                    {...other}
                                />
                            );
                        })}
                    </Table>
                )}
            </AutoSizer>
        );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            cellContentRenderer: PropTypes.func,
            dataKey: PropTypes.string.isRequired,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowClassName: PropTypes.string,
    rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
    sort: PropTypes.func,
};

MuiVirtualizedTable.defaultProps = {
    headerHeight: 56,
    rowHeight: 56,
};

const WrappedVirtualizedTable = withStyles(styles)(MuiVirtualizedTable);

class UserTests extends React.Component {

    rows = [];
    nodata = true;

    actionButtons = id => {
        return(
            <span>
                <EditIcon key={'edit-' + id}/>
                <DeleteForever key={'delete-' + id}/>
            </span>
        );
    };

    createData() {
        let data = Object.keys(this.props.testsData).map((key) => {
            let test = this.props.testsData[key];
            let date = new Date(test.timestamp);
            let data = {
                id: test.testId,
                test: test.testName,
                date: date.toLocaleString("ro-RO"),
                grade: Math.round(test.correctAnswers * 100 / test.questions.length) + '%',
            }
            if (!this.props.multipleChoice) {
                data.questions = test.correctAnswers + '/' + test.questions.length;
            }
            return data;
        });
        if (data.length > 0) {
            this.rows = data;
            this.nodata = false;
        }
        else {
            this.rows = [{id: 'notest', test: 'Încă nu ai făcut niciun test.'}]
            this.nodata = true;
        }
    }

    rowClick = (event) => {
        this.props.handleTestDetails(event.rowData);
    };

    render() {
        this.createData();
        let height = this.rows.length <= 5 ? (this.rows.length) * 56 + 116 + 23 : 400 + 56;
        let columns = [
            {
                width: this.nodata ? 1500 : 120,
                flexGrow: 1.0,
                label: 'Test',
                dataKey: 'test',
            },
            {
                width: 250,
                label: 'Dată',
                dataKey: 'date',
                numeric: true,
            },
            {
                width: 100,
                label: 'Notă',
                dataKey: 'grade',
                numeric: true,
            },
        ]
        if (!this.props.multipleChoice) {
            columns.push(
                {
                    width: 100,
                    label: 'Întrebări',
                    dataKey: 'questions',
                    numeric: true,
                });
        }

        // let height =  (this.rows.length) * 56 + 116 + 23;
        return (
            <Paper style={{ height: height, width: '100%'}} className={this.props.classes.paper}>
                <Typography variant="h4" component="h3" className={this.props.classes.title}>Testele tale</Typography>
                <Divider />
                <div style={{ height: height - 56, width: '100%'}} className={this.props.classes.paper}>
                {   this.props.testsLoading &&
                    <LinearProgress />
                }
                <WrappedVirtualizedTable
                    rowCount={this.rows.length}
                    rowGetter={({index}) => this.rows[index]}
                    onRowClick={event => this.rowClick(event)}
                    columns={columns}
                />
                </div>
            </Paper>
        );
    }
}

export default withStyles(styles)(UserTests);