import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Card, Grid, withStyles} from "@material-ui/core";
import * as utilsSelectors from "../store/utils/reducer";
import * as utilsActions from '../store/utils/actions';
import image from "../images/studying-ahead.jpg"
import Helmet from "react-helmet";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import FolderIcon from '@material-ui/icons/FolderOpen';
import CardMedia from "@material-ui/core/CardMedia";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import Paper from "@material-ui/core/Paper";
import MarkdownContentDialog from "../components/dialogs/MarkdownContentDialog";
import ShareDrawer from "../components/dialogs/ShareDrawer";
const API_URL = process.env.REACT_APP_API_URL;

const styles = theme => ({
    root: {
        paddingBottom: theme.spacing(1),
    },
    selected: {
        backgroundColor: 'rgba(125, 125, 125, 0.8)',
    },
    card: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: '35%',
    },
    centered: {
        textAlign: 'center',
    }
});

class Utils extends Component {

    state = {
        school: undefined,
        utilPageId: undefined,
        openUtilsDialog: false,
        loadingByUrl: false,
        changedUrl: true,
        openShareDrawer: false,
    }

    componentDidMount() {
        this.props.dispatch(utilsActions.getUtilsStructure());
        if (this.props.match.params.id !== undefined) {
            this.props.dispatch(utilsActions.getUtilsPage(this.props.match.params.id));
            this.setState({loadingByUrl: true});
        }
    }

    toggleShareDrawer = (open) => () => {
        console.log(open);
        this.setState({
            openShareDrawer: open,
        })
    }

    static getDerivedStateFromProps(props, state) {
        // Open the dialog when the util page is loaded.
        if (props.loadedUtilsPagesArrayKeys.length === 1 && state.loadingByUrl === true) {
            return {
                loadingByUrl: false,
                utilPageId: props.loadedUtilsPagesArrayKeys[0],
                openUtilsDialog: true,
            }
        }
        // Update url based on the clicked link, after the page is loaded, when we know the url.
        if (props.loadedUtilsPages[state.utilPageId] !== undefined && state.changedUrl === false) {
            props.history.push(props.loadedUtilsPages[state.utilPageId].alias);
            return {
                changedUrl: true,
            }
        }
        return null;
    }

    schoolClick = (id) => () => {
        this.setState({
            school: id,
            utilPageId: undefined,
        });
        this.scrollToElement();
    }

    scrollToElement = () => {
        this.pagesViewRef.scrollIntoView({ behavior: "smooth" });
    }

    utilPageClick = (id) => () => {
        this.setState({
            utilPageId: id,
            openUtilsDialog: true,
            changedUrl: false,
        })
        if (this.props.loadedUtilsPages[id] === undefined) {
            this.props.dispatch(utilsActions.getUtilsPage(id));
        }
    }

    closeUtilsDialog = () => {
        this.props.history.push('/utils');
        this.setState({
            utilPageId: undefined,
            openUtilsDialog: false,
            changedUrl: false,
        })
    }


    getSchools() {
        const { classes, termsData } = this.props;
        return Object.keys(this.props.utilsStructure).map(key => {
            if (key !== 'general') {
                return (
                    <Grid item key={key} xs={12} md={6} >
                        <Card className={key === this.state.school ? classes.selected : ''}>
                            <CardActionArea onClick={this.schoolClick(key)}>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="recipe" className={classes.avatar}>
                                            <FolderIcon/>
                                        </Avatar>
                                    }
                                    title={<Typography variant="h5">{termsData[key]}</Typography>}
                                />
                            </CardActionArea>
                        </Card>
                    </Grid>
                );
            }
            return false;
        });
    }

    getGeneral() {
        const { classes } = this.props;
        return Object.keys(this.props.utilsStructure.general).map(key => {
            return (
                <Grid item key={key} xs={12} md={6}>
                    <Card className={classes.card}>
                        <CardActionArea onClick={this.utilPageClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FileIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="body1">{this.props.utilsStructure['general'][key]}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    getUtilsTitles() {
        const { classes } = this.props;
        return Object.keys(this.props.utilsStructure[this.state.school]).map(key => {
            return (
                <Grid item key={key} xs={12} md={6}>
                    <Card className={classes.card}>
                        <CardActionArea onClick={this.utilPageClick(key)}>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                        <FileIcon/>
                                    </Avatar>
                                }
                                title={<Typography variant="body1">{this.props.utilsStructure[this.state.school][key]}</Typography>}
                            />
                        </CardActionArea>
                    </Card>
                </Grid>
            );
        });
    }

    render() {
        const { classes, utilsStructure } = this.props;
        return(
            <>
                <Helmet>
                    <title>Utile - Grile Politie</title>
                    <meta property="og:title" content={"Informaţii Utile - Grile Poliţie"} />
                    <meta property="og:description" content={this.props.loadedUtilsPages[this.state.utilPageId] !== undefined ? this.props.loadedUtilsPages[this.state.utilPageId].title : "Informaţii utile grupate pe şcoli"} />
                </Helmet>
                <Grid container justify="center" className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        <Card className={classes.card}>
                            <CardMedia
                                className={classes.cover}
                                image={image}
                                title="Imagine pagini utile"
                                onClick={this.props.onClick}
                            />
                            <CardActionArea onClick={this.props.onClick}>
                                <div className={classes.details}>
                                    <CardContent className={classes.content}>
                                        <Typography component="h5" variant="h5">
                                            Informaţii utile
                                        </Typography>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            Pe această pagină vei găsi informaţii utile în funcţie de şcoala la care vrei să dai admiterea.
                                        </Typography>
                                    </CardContent>
                                </div>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    {utilsStructure.length === 0 &&
                        <Grid item xs={12}>
                            <Paper className={classes.centered}><Typography variant="body1">Momentan nu sunt pagini cu informatii utile.</Typography></Paper>
                        </Grid>
                    }
                    {utilsStructure.general !== undefined &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Informaţii Generale</Typography></Paper>
                            </Grid>
                            {this.getGeneral()}
                        </>
                    }
                    {utilsStructure.length !== 0 &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Şcoli de poliţie</Typography></Paper>
                            </Grid>
                            {this.getSchools()}
                        </>
                    }
                    <Grid item xs={12}>
                    </Grid>
                    {this.state.school !== undefined &&
                        <>
                            <Grid item xs={12}>
                                <Paper className={classes.centered}><Typography variant="body1">Informaţii utile</Typography></Paper>
                            </Grid>
                            {this.getUtilsTitles()}
                        </>
                    }
                    <div ref={el => { this.pagesViewRef = el; }}/>
                </Grid>
                <MarkdownContentDialog
                    open={this.state.openUtilsDialog}
                    handleClose={this.closeUtilsDialog}
                    content={this.props.loadedUtilsPages[this.state.utilPageId]}
                    toggleShareDrawer={this.toggleShareDrawer}
                />
                {this.props.loadedUtilsPages[this.state.utilPageId] !== undefined &&
                <ShareDrawer
                    toggleDrawer={this.toggleShareDrawer}
                    open={this.state.openShareDrawer}
                    url={API_URL + this.props.loadedUtilsPages[this.state.utilPageId].alias}
                />
                }
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        utilsStructure: utilsSelectors.getUtilsStructure(state),
        termsData: utilsSelectors.getUtilsTerms(state),
        loadedUtilsPages: utilsSelectors.getLoadedUtilsPages(state),
        loadedUtilsPagesArrayKeys: utilsSelectors.loadedUtilsPagesArrayKeys(state),
    };
};

export default connect(mapStateToProps)(withStyles(styles)(Utils));