import React from 'react';
import {Card, Grid, Tooltip, withStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import defaultAddressImage from '../../images/payment/address-card.jpg'
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete"
import SelectIcon from "@material-ui/icons/Check"
import CardActions from "@material-ui/core/CardActions";

const styles = theme => ({
    root: {},
    card: {
        display: 'flex',
    },
    media: {
        height: 140,
    },
    discounted: {
        textDecoration: 'line-through',
    },
    actions: {
        position: 'relative',
    },
    right: {
        right: 0,
        position: 'absolute',
        marginRight: theme.spacing(),
    }
});

class Address extends React.Component {

    selectAddress = (id) => {
        if (this.props.selectAddress !== undefined) {
            this.props.selectAddress(id);
        }
        else {
            this.props.editSelectionPage('/addresses');
        }
    }

    render() {
        const {classes, addressData} = this.props;
        return (
            <Grid item xs={12} sm={6} md={3}>
                <Card className={classes.root}>
                    <CardActionArea onClick={() => this.selectAddress(addressData.id)}>
                        <CardMedia
                            className={classes.media}
                            image={defaultAddressImage}
                            title="Imagine Adresa"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {addressData.streetAddress}
                            </Typography>
                            <Typography variant="body2" component="p">
                                {addressData.city} <br />
                                {addressData.stateProvince} <br />
                                {addressData.country} <br />
                                {addressData.phoneNumber} <br />
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    {this.props.showActions &&
                        <CardActions className={classes.actions}>
                            <Tooltip title="Selectează adresa" placement="top">
                                <IconButton onClick={() => this.selectAddress(addressData.id)}>
                                    <SelectIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Şterge adresa" placement="top">
                                <IconButton className={classes.right} onClick={() => this.props.deleteAddress(addressData.id)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Tooltip>
                        </CardActions>
                    }
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(Address);