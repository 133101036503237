import React from 'react';
import {Card, Grid, withStyles} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

const styles = theme => ({
    root: {},
});

class Order extends React.Component {

    getOrderHistory(order) {
        return order.orderHistory.map(historyItem => {
            let date = new Date(historyItem.updated * 1000);
            return (
                <TableRow key={historyItem.updated}>
                    <TableCell>{historyItem.status}</TableCell>
                    <TableCell>{date.toLocaleString("ro-RO")}</TableCell>
                </TableRow>
            );
        })
    }

    render() {
        const {classes} = this.props;
        const order = this.props.order;
        let date = new Date(order.created * 1000);
        return(
            <Grid item xs={12} md={6} className={classes.root}>
                <Card>
                    <CardActionArea>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {order.status}
                            </Typography>
                            <Typography variant="body2" component="p">
                                Dată: {date.toLocaleString("ro-RO")} <br />
                                Card: {order.panMasked ? order.panMasked : '*********'} <br />
                                Suma: {order.amount} <br />
                                Produs: {order.productTitle} <br />
                            </Typography>
                            <hr />
                            <Typography gutterBottom variant="subtitle1" component="h2">
                                <strong>Istoric</strong>
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="tabel istoric">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Data</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.getOrderHistory(order)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </Grid>
        );
    }
}

export default withStyles(styles)(Order);