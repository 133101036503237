import * as types from './actionTypes';
import TestsService from '../../services/tests'

export function getFreeTest(token) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('freeTest') !== null) {
            data = JSON.parse(localStorage.getItem('freeTest'));
        }
        else {
            data = await TestsService.freeTest(token);
        }
        if (data.error) {
            dispatch({type: types.FREE_TEST_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.FREE_TEST_LOAD_SUCCESS, freeTest: data});
        }
    }
}

export function getRandomTest(token, newTest = false, parameters = [], append = false) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        if (newTest) {
            localStorage.removeItem('currentTest');
        }
        let data;
        if (localStorage.getItem('currentTest') !== null) {
            data = JSON.parse(localStorage.getItem('currentTest'));
        }
        else {
            data = await TestsService.randomTest(token);
        }
        if (data.error) {
            dispatch({type: types.PAID_TEST_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.PAID_TEST_LOAD_SUCCESS, test: data});
        }
    }
}

export function continueCurrentTest(token) {
    return async (dispatch, getState) => {
        let state = getState();
        dispatch(getParametersTest(token, true, state.tests.lastTestParams, true));
    }
}

export function getNewConfiguredTest(token, parameters, testId) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = {};
        try {
            data = await TestsService.newConfiguredTest(token, parameters);
        }
        catch (e) {
            console.error('fetchFail', e);
            data.error = true;
            data.rawData = {message: 'Am întâmpinat o eroare in aducerea întrebărilor.'};
        }
        if (data.error) {
            dispatch({type: types.PAID_TEST_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.SET_TEST_NAME, testName: parameters.name});
            dispatch({type: types.SET_LAST_TEST_PARAMS, params: parameters});
            dispatch({type: types.PAID_TEST_LOAD_SUCCESS, test: data});
            dispatch({type: types.START_TEST, test: testId, selectedExam: getState().settings.selectedExam });
        }
    }
}

export function getParametersTest(token, newTest = false, parameters = [], append = false) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        if (newTest) {
            localStorage.removeItem('currentTest');
        }
        let data;
        if (localStorage.getItem('currentTest') !== null) {
            data = JSON.parse(localStorage.getItem('currentTest'));
        }
        else {
            if (parameters.type === 'prevYears') {
                data = await TestsService.parametersPreviousYearsTest(token, parameters);
            }
            // Check new chapters type.
            else {
                data = await TestsService.parametersTest(token, parameters);
            }
        }
        if (data.error) {
            dispatch({type: types.PAID_TEST_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.SET_TEST_NAME, testName: parameters.name});
            dispatch({type: types.SET_LAST_TEST_PARAMS, params: parameters});
            if (append) {
                let state = getState();
                let mergedData = state.tests.test.concat(data);
                dispatch({type: types.PAID_TEST_LOAD_SUCCESS, test: mergedData});
            }
            else {
                dispatch({type: types.PAID_TEST_LOAD_SUCCESS, test: data});
            }
        }
    }
}

export function setQuestionsCount(questionsCount) {
    return async (dispatch, getState) => {
        dispatch({type: types.SET_QUESTIONS_COUNT, questionsCount: questionsCount});
    }
}

export function getTestsTerms(token = undefined) {
    return async (dispatch, getState) => {
        if (token === undefined) {
            token = getState().authentication.token;
        }
        dispatch({type: types.LOADING_START});
        let data = await TestsService.testsTerms(token);
        if (data.error) {
            dispatch({type: types.TESTS_TERMS_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.TESTS_TERMS_LOAD_SUCCESS, terms: data});
        }
    }
}

export function getTestsTermsStructure(token) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('testsTermsStructure') !== null) {
            data = JSON.parse(localStorage.getItem('testsTermsStructure'));
        }
        else {
            data = await TestsService.testsTermsStructure(token);
        }
        if (data.error) {
            dispatch({type: types.TESTS_TERMS_STRUCTURE_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.TESTS_TERMS_STRUCTURE_LOAD_SUCCESS, termsStructure: data});
        }
    }
}

export function getTestsTermsChaptersStructure(token) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('testsTermsChaptersStructure') !== null) {
            data = JSON.parse(localStorage.getItem('testsTermsChaptersStructure'));
        }
        else {
            data = await TestsService.testsTermsChaptersStructure(token, getState().settings.selectedExam);
        }
        if (data.error) {
            dispatch({type: types.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.TESTS_TERMS_CHAPTERS_STRUCTURE_LOAD_SUCCESS, termsStructure: data});
        }
    }
}

export function getTestsTermsYearsStructure(token) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('testsTermsYearsStructure') !== null) {
            data = JSON.parse(localStorage.getItem('testsTermsYearsStructure'));
        }
        else {
            data = await TestsService.testsTermsYearsStructure(token, getState().settings.selectedExam);
        }
        if (data.error) {
            dispatch({type: types.TESTS_TERMS_YEARS_STRUCTURE_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.TESTS_TERMS_YEARS_STRUCTURE_LOAD_SUCCESS, termsStructure: data});
        }
    }
}

export function getUserTests(forced = false) {
    return async (dispatch, getState) => {
        const token = getState().authentication.token;
        dispatch({type: types.USER_TESTS_LOADING_START});
        let data;
        if (!forced && localStorage.getItem('userTests') !== null && localStorage.getItem('userTestsLoaded') !== null && (parseInt(localStorage.getItem('userTestsLoaded')) + (6000 * 1000) > Date.now())) {
            data = JSON.parse(localStorage.getItem('userTests'));
            console.log('loading tests from local storage');
        }
        else {
            console.log('getting new tests');
            data = await TestsService.userTests(token, getState().settings.selectedExam);
            localStorage.setItem('userTestsLoaded', Date.now());
        }
        if (data.error) {
            dispatch({type: types.USER_TESTS_LOAD_FAILURE, errorMessage: data.rawData});
            dispatch({type: types.USER_TESTS_LOADING_STOP});
        }
        else {
            dispatch({type: types.USER_TESTS_LOAD_SUCCESS, userTests: data});
            dispatch({type: types.USER_TESTS_LOADING_STOP});
        }
    }
}

export function getUserTestsAndQuestionsById(id) {
    return async (dispatch, getState) => {
        let token = getState().authentication.token;
        dispatch({type: types.LOADING_START});
        let data;
        if (localStorage.getItem('userTests') !== null && localStorage.getItem('userTestsLoaded') !== null && (parseInt(localStorage.getItem('userTestsLoaded')) + (6000 * 1000) > Date.now())) {
            data = JSON.parse(localStorage.getItem('userTests'));
            console.log('loading tests from local storage');
        }
        else {
            console.log('getting new tests');
            data = await TestsService.userTests(token, getState().settings.selectedExam);
            localStorage.setItem('userTestsLoaded', Date.now());
        }
        if (data.error) {
            dispatch({type: types.USER_TESTS_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_TESTS_LOAD_SUCCESS, userTests: data});
            dispatch(getTestQuestions(token, id));
        }
    }
}

export function addUserTests(token, newTest) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await TestsService.addUserTest(token, newTest);
        if (data.error) {
            dispatch({type: types.USER_TESTS_ADD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_TESTS_LOAD_SUCCESS, userTests: data});
        }
    }
}

export function deleteUserTest(token, testId) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        let data = await TestsService.deleteUserTest(token, testId);
        if (data.error) {
            dispatch({type: types.USER_TESTS_DELETE_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_TESTS_LOAD_SUCCESS, userTests: data});
        }
    }
}

export function getTestQuestions(token, id) {
    return async (dispatch, getState) => {
        dispatch({type: types.LOADING_START});
        const nid = getState().tests.userTests[id].nid;
        let data = await TestsService.testQuestions(token, nid);
        if (data.error) {
            dispatch({type: types.USER_TEST_QUESTIONS_LOAD_FAILURE, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.USER_TEST_QUESTIONS_LOAD_SUCCESS, testQuestions: data, testNid: nid});
        }
    }
}

export function setMessage(type, message) {
    return async (dispatch, getState) => {
        dispatch({type: types.SET_MESSAGE, errorMessage: message, messageType: type, error: true});
    }
}

export function dismissMessages() {
    return async (dispatch, getState) => {
        dispatch({type: types.DISMISS_MESSAGES});
    }
}

export function newQuestionResponse(nid, responses, correct, test) {
    return async (dispatch, getState) => {
        dispatch({type: types.NEW_ANSWER, nid: nid, responses: responses, correct: correct, test: test})
    }
}

export function finishTest(test) {
    return async (dispatch, getState) => {
        dispatch({type: types.FINISH_TEST, test: test});
        dispatch(syncUserTests());
    }
}

export function skipQuestion(currentQuestion) {
    return async (dispatch, getState) => {
        dispatch({type: types.SKIP_QUESTION, questionPosition: currentQuestion});
    }
}

export function syncUserTests() {
    return async (dispatch, getState) => {
        const state = getState();
        let keys = Object.keys(state.tests.userTests);
        for (let i = 0; i < keys.length; i++) {
            let test = Object.assign({}, state.tests.userTests[keys[i]]);
            if (test.nid === undefined && test.finished) {
                if (i === keys.length - 1) {
                    test.configurationOptions = state.tests.lastTestParams;
                }
                dispatch(addUserTests(state.authentication.token, JSON.stringify(test)));
            }
        }
        dispatch({type: types.SYNC_USER_TESTS});
    }
}

export function timeIsUp() {
    return async (dispatch, getState) => {
        dispatch({type: types.TIME_IS_UP});
        dispatch({type: types.SET_MESSAGE, errorMessage: 'Timpul a expirat!', messageType: 'error', error: true});
        dispatch({type: types.FINISH_TEST, test: getState().tests.testInProgress.reactId});
        dispatch(syncUserTests());
    }
}

export function removeLocalTests() {
    return async (dispatch, getState) => {
        dispatch({type: types.REMOVE_LOCAL_TESTS});
    }
}

export function setCurrentTestAnswers(answers) {
    return async (dispatch, getState) => {
        dispatch({type: types.SET_CURRENT_TEST_ANSWERS, currentTestAnswers: answers})
    }
}