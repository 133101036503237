import * as types from './actionTypes';
import LessonsService from '../../services/lessons'

export function getLessonsStructure() {
    return async(dispatch, getState) => {
        // dispatch loading start
        let token = getState().authentication.token;
        let data = await LessonsService.getLessonsStructure(token);
        if (data.error) {
            dispatch({type: types.STRUCTURE_LOAD_FAIL, errorMessage: data.rawData})
        }
        else {
            dispatch({type: types.STRUCTURE_LOAD_SUCCESS, lessonsStructure: data.structure, termsData: data.termData });
        }
    }
}

export function getLesson(id) {
    return async(dispatch, getState) => {
        // dispatch loading start
        let token = getState().authentication.token;
        let data = await LessonsService.getLesson(token, id);
        if (data.error) {
            dispatch({type: types.LESSON_LOAD_FAIL, errorMessage: data.rawData})
        }
        else {
            dispatch({type: types.LESSON_LOAD_SUCCESS, lessonId: data.id, lessonData: data});
        }
    }
}