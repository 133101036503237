import URLSearchParams from '@ungap/url-search-params';
const API_URL = process.env.REACT_APP_API_URL;
const CLIENT_ID = process.env.REACT_APP_API_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_API_CLIENT_SECRET;

class AuthService {

    async login(credentials) {
        const url = '/oauth/token';
        const method = 'POST';
        const body = {
            grant_type: 'password',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            username: credentials.username,
            password: credentials.password,
            scope: 'administrator',
        };
        let params = new URLSearchParams();
        for (let key in body) {
            params.append(key, body[key]);
        }

        // return;
        const response = await fetch(API_URL + url, {
            method: method,
            // headers: headers,
            body: params,
        });
        let data = await response.json();
        return data;
    }

    async refreshToken(refreshToken) {
        const url = '/oauth/token';
        const method = 'POST';
        const body = {
            grant_type: 'refresh_token',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            refresh_token: refreshToken,
        };
        let params = new URLSearchParams();
        for (let key in body) {
            params.append(key, body[key]);
        }

        // return;
        const response = await fetch(API_URL + url, {
            method: method,
            // headers: headers,
            body: params,
        });
        let data = await response.json();
        return data;
    }

    async facebookLogin(credentials) {

        const url = '/oauth/token';
        const method = 'POST';
        const body = {
            grant_type: 'facebook',
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            facebook_token: credentials.fbToken,
            facebook_user_id: credentials.fbUserId,
        };
        let params = new URLSearchParams();
        for (let key in body) {
            params.append(key, body[key]);
        }

        // return;
        const response = await fetch(API_URL + url, {
            method: method,
            // headers: headers,
            body: params,
        });
        let data = await response.json();
        return data;
    }

    async register(credentials) {
        const url = '/user/register?_format=hal_json';
        const method = 'POST';
        const headers = {
            'Content-Type': 'application/hal+json',
        }
        const body = {
            _links: {type: {href: API_URL + "/rest/type/user/user"}},
            name: {
                value: credentials.username
            },
            mail: {
                value: credentials.username
            },
            pass: {
                value: credentials.password
            },
        };

        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: JSON.stringify(body),
        });
        let data = '';
        if (response.status === 200) {
            data = await this.login(credentials);
        }
        else if (response.status === 422) {
            // TODO: sort error messages as they differ on this same code.
            data = {
                error: true,
                message: 'Deja există un cont cu acest email.'
            }
        }
        else {
            data = {
                error: true,
                message: 'O eroare neprevazută a apărut. Reîncearcă sau contactează echipa de suport.'
            }
        }
        return data;
    }

    async userDetails(token) {
        const url = '/grile_api/user_resource';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            data = {
                error: true,
                status: response.status,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }

    async updateUserDetails(token, userData) {
        const url = '/grile_api/user_resource?_format=json';
        const method = 'PATCH';
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        }
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
            body: JSON.stringify(userData),
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            data = {
                error: true,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new AuthService();
