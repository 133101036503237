const API_URL = process.env.REACT_APP_API_URL;


class SettingsService {

    async getAppSettings(token) {
        const url = '/grile_api/settings?_format=json';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new SettingsService();