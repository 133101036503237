import React, {Component} from 'react';
import {connect} from 'react-redux';
import SettingsBox from "../components/SettingsBox";
import {Grid, withStyles} from "@material-ui/core";
import * as settingsActions from '../store/settings/actions';
import * as settingsSelectors from '../store/settings/reducer';
import * as authenticationActions from "../store/authentication/actions";
import * as authenticationSelectors from "../store/authentication/reducer";
import Messages from "../components/messages/Messages";
import Helmet from "react-helmet";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
});

class Settings extends Component {

    toggleDarkMode = value => {
        this.props.dispatch(settingsActions.setDarkTheme(!this.props.darkMode))
    };

    updateUser = values => {
        this.props.dispatch(authenticationActions.updateUser(values));
    };

    handleErrorClose = () => {
        this.props.dispatch(authenticationActions.dismissMessages());
    };

    render() {
        const { classes, darkMode, userData } = this.props;
        return(
            <div>
                <Helmet>
                    <title>Setari - Grile Politie</title>
                </Helmet>
                <Grid container justify="center" className={classes.root}>
                    <Grid item>
                        <SettingsBox darkMode={darkMode} userData={userData} updateUser={this.updateUser} toggleDarkMode={this.toggleDarkMode}/>
                    </Grid>
                </Grid>
                {this.props.error &&
                <Messages
                    variant={this.props.errorType}
                    message={this.props.errorMessage}
                    onClose={this.handleErrorClose}
                    error={this.props.error}
                />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    const {error, errorMessage, errorType} = authenticationSelectors.getError(state);
    return {
        darkMode: settingsSelectors.getDarkTheme(state),
        userData: authenticationSelectors.getUserData(state),
        error: error,
        errorMessage: errorMessage,
        errorType: errorType
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Settings));