import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    lessonsStructure: {},
    termsData: {},
    loadedLessons: {},
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.STRUCTURE_LOAD_SUCCESS:
            return state.merge({
                lessonsStructure: action.lessonsStructure,
                termsData: action.termsData,
            })

        case types.LESSON_LOAD_SUCCESS:
            let loadedLessons = Object.assign({}, state.loadedLessons);
            loadedLessons[action.lessonId] = action.lessonData;
            return state.merge({
                loadedLessons: loadedLessons,
            });

        case types.STRUCTURE_LOAD_FAIL:
        case types.LESSON_LOAD_FAIL:
            return state;

        default:
            return state;
    }
}

export function getLessonsTerms(state) {
    return state.lessons.termsData;
}

export function getLessonsStructure(state) {
    return state.lessons.lessonsStructure;
}

export function getLoadedLessons(state) {
    return state.lessons.loadedLessons;
}