import encryption from "../helpers/encryption";

const API_URL = process.env.REACT_APP_API_URL;


class LessonsService {
    async getLessonsStructure(token) {
        return this.getLesson(token, 'structure');
    }

    async getLesson(token, id) {
        const url = '/grile_api/lessons/' + id + '?_format=json';
        const method = 'GET';
        const headers = {
            'Authorization': 'Bearer ' + token,
        };
        let data = [];
        const response = await fetch(API_URL + url, {
            method: method,
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
            if (typeof data === "string") {
                let decrypted = encryption.decrypt(data, 'n0T53cUr3');
                data = JSON.parse(decrypted);
            }
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new LessonsService();