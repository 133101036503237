import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class DeleteAddressDialog extends React.Component {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Ştergere adresă
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Eşti sigur că vrei să ştergi această adresă?</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.deleteAddress} color="primary">
                            Şterge
                        </Button>
                        <Button onClick={this.props.handleClose} color="primary">
                            Anulează
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(DeleteAddressDialog);