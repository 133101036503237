import React from 'react';
import {connect} from 'react-redux';
import {Card, withStyles} from "@material-ui/core";
import * as paymentActions from '../../store/payment/actions';
import * as paymentSelectors from '../../store/payment/reducer';
import Grid from "@material-ui/core/Grid";
import Order from "../../components/payment/dialogs/Order";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {},
});

class Orders extends React.Component {

    componentDidMount() {
        this.props.dispatch(paymentActions.getUserOrders());
    }

    render() {
        const {classes, orders} = this.props;
        if (orders.length === 0) {
            return(
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <Typography variant="h5" component="p">
                                Nu ai nicio comandă.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            )
        }
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                {Object.keys(orders).reverse().map(key => {
                    return <Order key={key} order={orders[key]} />
                })}
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        orders: paymentSelectors.getUserOrders(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Orders));