import * as types from './actionTypes';
import ReviewsService from '../../services/reviews'

export function getUserReviews() {
    return async (dispatch, getState) => {
        let token = getState().authentication.token;
        let data = await ReviewsService.getUserReviews(token);
        if (data.error) {
            dispatch({type: types.REVIEW_LOAD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.REVIEW_LOAD_SUCCESS, userReviews: data});
        }
    }
}

export function submitReview(reviewData) {
    return async (dispatch, getState) => {
        let token = getState().authentication.token;
        let data = await ReviewsService.addReview(token, reviewData);
        if (data.error) {
            dispatch({type: types.REVIEW_ADD_FAIL, errorMessage: data.rawData});
        }
        else {
            dispatch({type: types.REVIEW_ADD_SUCCESS, userReviews: data});
        }
    }
}