import React from 'react';
import {connect} from 'react-redux';
import * as testsSelectors from "../store/tests/reducer";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    withStyles
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
    expansion: {

    }
});

class TestDetails extends React.Component {

    getDetails = () => {
        const {configurationOptions} = this.props;
        let details = {
            questionsCount: configurationOptions.questionsCount,
            timing: configurationOptions.timing,
            timeLimit: configurationOptions.timeLimit,
            selections: false,
        };
        switch (configurationOptions.type) {
            case 'simulate':
                details.type = 'Simulare examen';
                let secondary = configurationOptions.secondary ? 'Istorie, ' : '';
                details.subject = this.props.testsTerms[configurationOptions.primary] + ', ' + secondary + this.props.testsTerms[configurationOptions.subject];
                break;

            case 'prevYears':
                details.type = 'Din anii precedenţi';
                details.subject = this.props.testsTerms[configurationOptions.subject];
                details.school = this.props.testsTerms[configurationOptions.school];
                details.subCategory = this.props.testsTerms[configurationOptions.subCategory];
                details.variant = this.props.testsTerms[configurationOptions.variant];
                break;

            case 'chapters':
                details.type =  'Testare pe capitole';
                details.subject = this.props.testsTerms[configurationOptions.subject];
                details.selections = true;
                break;

            case 'checkKnowledge':
                details.type = 'Verificare cunoştinţe';
                details.selections = true;
                break;

            default:
                return '';
        }

        return details;
    };

    render() {
        let details = this.getDetails();
        const { classes, configurationOptions } = this.props;
        return (
            <div className="test-details">
                <Accordion className={classes.expansion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Tip Test: {details.type}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            {details.subject !== undefined && <Typography>Materie: {details.subject.name}</Typography>}
                            <Typography>Număr întrebări: {details.questionsCount}</Typography>
                            <Typography>Contratimp: {details.timing ? 'Da' : 'Nu'}</Typography>
                            {details.timing && <Typography>Timp selectat: {details.timeLimit} de minute</Typography>}
                            {details.selections &&
                                <>
                                    <Typography>Materii si capitole selectate:</Typography>
                                    <ul>
                                    {Object.values(configurationOptions.selections).map(selection => <li key={selection.key}>{selection.label}</li>)}
                                    </ul>
                                </>
                            }
                            {details.school && <Typography>Şcoala: {details.school}</Typography>}
                            {details.subCategory && <Typography>Anul: {details.subCategory}</Typography>}
                            {details.variant && <Typography>{details.variant}</Typography>}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        testsTerms: testsSelectors.getTermsKeyedWithTids(state),
    }
}

export default connect(mapStateToProps)(withStyles(styles)(TestDetails));