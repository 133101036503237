import React from 'react';
import {withStyles} from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
    WhatsappIcon, WhatsappShareButton,
    FacebookIcon, FacebookShareButton,
    FacebookMessengerIcon, FacebookMessengerShareButton,
    EmailIcon, EmailShareButton,
} from "react-share";
import {
    isMobile
} from "react-device-detect";
import ListSubheader from "@material-ui/core/ListSubheader";
import Messages from "../messages/Messages";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";

const styles = theme => ({
    root: {},
});

class ShareDrawer extends React.Component {

    state = {
        messageDisplay: false,
    }

    handleMessageClose = () => {
        this.setState({
            messageDisplay: false,
        });
    }

    copyLink = () => {
        this.setState({
            messageDisplay: true,
        });
    }

    getFacebookListItem() {
        return(
            <ListItem button>
                <ListItemIcon><FacebookShareButton url={this.props.url}><FacebookIcon size={32} round={true}/></FacebookShareButton></ListItemIcon>
                <ListItemText primary={<FacebookShareButton url={this.props.url}>Facebook</FacebookShareButton>}/>
            </ListItem>
        );
    }

    getWhatsappListItem() {
        return(
            <ListItem button>
                <ListItemIcon><WhatsappShareButton url={this.props.url}><WhatsappIcon size={32} round={true} /></WhatsappShareButton></ListItemIcon>
                <ListItemText primary={<WhatsappShareButton url={this.props.url}>WhatsApp</WhatsappShareButton>}/>
            </ListItem>
        );
    }

    getCopyToClipboardItem() {
        return(
            <CopyToClipboard text={this.props.url} onCopy={this.copyLink}>
                <ListItem button>
                    <ListItemIcon><FileCopyIcon /></ListItemIcon>
                    <ListItemText primary="Copiază link-ul" />
                </ListItem>
            </CopyToClipboard>
        );
    }

    getFacebookMessengerListItem() {
        if (isMobile) {
            return(
                <ListItem component="a" button href={"fb-messenger://share/?link=" + this.props.url + "&app_id=2412884222079741"}>
                    <ListItemIcon><FacebookMessengerIcon size={32} round={true}/></ListItemIcon>
                    <ListItemText primary={"Messenger"}/>
                </ListItem>
            );
        }
        return(
            <ListItem button>
                <ListItemIcon><FacebookMessengerShareButton appId="2412884222079741" url={this.props.url}><FacebookMessengerIcon size={32} round={true}/></FacebookMessengerShareButton></ListItemIcon>
                <ListItemText primary={<FacebookMessengerShareButton appId="2412884222079741" url={this.props.url}>Messenger</FacebookMessengerShareButton>}/>
            </ListItem>
        );
    }

    getEmailListItem() {
        let subject = "Informaţii interesante de pe grile-examene.ro";
        let body = "Am găsit aceste informaţii şi cred că te-ar interesa!\n Accesează linkul:";
        return(
            <ListItem button>
                <ListItemIcon><EmailShareButton subject={subject} body={body} url={this.props.url}><EmailIcon size={32} round={true} /></EmailShareButton></ListItemIcon>
                <ListItemText primary={<EmailShareButton subject={subject} body={body} url={this.props.url}>Email</EmailShareButton>}/>
            </ListItem>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <Drawer className={classes.root} anchor="bottom" open={this.props.open} onClose={this.props.toggleDrawer(false)}>
                <List
                    subheader={<ListSubheader component="div">Distribuie Articolul</ListSubheader>}
                >
                    {this.getWhatsappListItem()}
                    {this.getFacebookMessengerListItem()}
                    {this.getFacebookListItem()}
                    {this.getCopyToClipboardItem()}
                    {this.getEmailListItem()}
                </List>
                <Messages
                    variant="info"
                    message="Linkul a fost copiat"
                    onClose={this.handleMessageClose}
                    error={this.state.messageDisplay}
                />
            </Drawer>
        );
    }
}

export default withStyles(styles)(ShareDrawer);