import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as staticPagesSelectors from "../store/staticPages/reducer";
import {connect} from "react-redux";
import * as staticPagesActions from "../store/staticPages/actions";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Button from "@material-ui/core/Button";
import PaginationDots from "../components/navigation/PaginationDots"
import LinkButton from "../components/linkComponents/LinkButton";
import Helmet from "react-helmet";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const styles = theme => ({
    card: {
        position: 'relative',
        width: '100%',
    },
    media: {
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: 0,
        paddingTop: '90vh'
    },
    overlay: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // background: 'rgb(60, 60, 60)',
        background: 'rgba(60, 60, 60, 0.7)'
    },
    text: {
        color: 'white',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    button: {
        // margin: theme.spacing.unit,
        width: '100%',
    },
});

class Homepage extends React.Component {

    state = {
        activeStep: 0,
    };

    componentDidMount() {
        this.props.dispatch(staticPagesActions.getStaticPage('Homepage Politie'));
    }

    handleNext() {
        this.setActiveStep(prevActiveStep => prevActiveStep + 1);
    }

    handleBack() {
        this.setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    handleStepChange = (step) => {
        this.setActiveStep(step);
    };

    setActiveStep(step) {
        this.setState({
            activeStep: step,
        });
    }

    render() {
        const {classes, staticPages} = this.props;
        const pageData = staticPages['Homepage Politie'];
        const hasPageContent = pageData !== undefined;
        return (
            <div className={classes.card}>
                <Helmet>
                    <title>Grile Politie</title>
                </Helmet>
                {pageData !== undefined && hasPageContent &&
                <AutoPlaySwipeableViews
                    axis={'x'}
                    index={this.state.activeStep}
                    onChangeIndex={this.handleStepChange}

                    interval={5000}
                    enableMouseEvents
                >
                    {pageData.map((pageSection, index) => (
                        <div key={pageSection.id}>
                            {Math.abs(this.state.activeStep - index) <= 2 ? (
                                <div className={classes.card} key={pageSection.id}>

                                        <div style={{backgroundImage: `url(${pageSection.background[0].url})`}}
                                             className={classes.media}
                                        />
                                        <div className={classes.overlay}>
                                            {pageSection.title != null &&
                                            <Typography className={classes.text} align="center" variant="h3" component="div">
                                                {pageSection.title}
                                            </Typography>
                                            }
                                            <Typography
                                                className={classes.text}
                                                variant="body1"
                                                component="div"
                                                dangerouslySetInnerHTML={{ __html: pageSection.body }}
                                            />
                                            <LinkButton className={classes.button} variant="contained" size="large" color="primary" to="/login">Logare / Înregistrare</LinkButton>
                                        </div>
                                </div>
                            ) : null}
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                }
                { pageData !== undefined && hasPageContent &&
                    <PaginationDots dots={pageData.length} index={this.state.activeStep} onChangeIndex={this.handleStepChange} />
                }

                {pageData !== undefined && !hasPageContent &&
                <div className={classes.card} >

                    <div className={classes.media} />
                    <div className={classes.overlay}>
                        <Button className={classes.button} variant="contained" size="large" color="primary" href={"/login"}>Logare / Înregistrare</Button>
                    </div>
                </div>
                }
            </div>
        );
    }
}

Homepage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const [error, errorMessage, messageType]  = staticPagesSelectors.getError(state);
    return {
        staticPages: staticPagesSelectors.getStaticPages(state),
        error: error,
        errorMessage: errorMessage,
        messageType: messageType,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Homepage));