import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        cursor: "pointer",
    },
});

const LinkGeneric = (props) => {
    const {
        history,
        location,
        match,
        staticContext,
        to,
        onClick,
        classes,
        // ⬆ filtering out props that `button` doesn’t know what to do with.
        ...rest
    } = props;
    return (
        <div className={classes.root}
            {...rest} // `children` is just another prop!
            onClick={(event) => {
                onClick && onClick(event)
                history.push(to)
            }}
        />
    )
};

LinkGeneric.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default withRouter(withStyles(styles)(LinkGeneric));