import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import {Snackbar} from "@material-ui/core";
import Button from "@material-ui/core/Button";

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});

class Messages extends React.Component {
    createButton() {
        if (this.props.button !== undefined) {
            return(
                <Button key="undo" color="inherit" size="small" onClick={this.props.onClose}>
                    {this.props.button}
                </Button>
            )
        }
        else {
            return(<div key="empty"></div>);
        }
    }

    render() {
        const {classes, error, message, onClose, variant, button, ...other} = this.props;
        const Icon = variantIcon[variant];

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={error}
                // autoHideDuration={6000}
                onClose={onClose}
            >
                <SnackbarContent
                    className={classNames(classes[variant])}
                    aria-describedby="client-snackbar"
                    message={
                        <span id="client-snackbar" className={classes.message}>
              <Icon className={classNames(classes.icon, classes.iconVariant)}/>
                            {message}
            </span>
                    }
                    action={[
                        this.createButton(),
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={onClose}
                        >
                            <CloseIcon className={classes.icon}/>
                        </IconButton>,
                    ]}
                    {...other}
                />
            </Snackbar>

        );
    }
}

Messages.propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    error: PropTypes.bool,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

export default withStyles(styles1)(Messages);
