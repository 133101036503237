import React from 'react';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth/index';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import MonthSelector from "../pageParts/MonthSelector";

const styles = theme => ({
    container: {
        padding: theme.spacing(),
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    exitButton: {
        position: 'absolute',
        right: 0,
    },
    currentUser: {
        backgroundColor: theme.palette.primary.A400,
    },
});

class StatisticsTop10Dialog extends React.Component {

    state = {
        tab: 0,
        month: undefined,
        year: 2020,
    }

    changeTab = (event, tabsValue) => {
        this.setState({
            tab: tabsValue,
        })
    }

    handleMonthChange = (newDate) => {
        this.setState(newDate);
    };

    getEmptyTableRow(key) {
        return <TableRow key={key}><TableCell colSpan={5}>...</TableCell></TableRow>;
    }

    buildMonth() {
        return (this.state.year + '/' + this.state.month);
    }

    getTableBody = () => {
        if (this.state.month === undefined) {
            return;
        }
        const {classes, statistics} = this.props;
        const statisticsMonth = this.buildMonth();
        let ranking;
        let currentPosition;
        if (this.state.tab === 0) {
            // The selected tab is points, which means we show top10 based on points.
            ranking = statistics.general_statistics.usersTop[statisticsMonth].topPoints;
            currentPosition = statistics.user_statistics.position[statisticsMonth].points;
        } else {
            ranking = statistics.general_statistics.usersTop[statisticsMonth].topGrades;
            currentPosition = statistics.user_statistics.position[statisticsMonth].grades;
        }
        let userIsInTop = false;
        let rankingTableRows = ranking.map((row, rank) => {
            let rowClass = undefined;
            if (rank + 1 === currentPosition) {
                rowClass = classes.currentUser;
                userIsInTop = true;
            }
            return (
                <TableRow key={rank} className={rowClass}>
                    <TableCell component="th" scope="row">{rank + 1}</TableCell>
                    <TableCell>{row.name}</TableCell>
                    <TableCell align="right">{Math.round(row.grade)}</TableCell>
                    <TableCell align="right">{Math.round(row.points)}</TableCell>
                    <TableCell align="right">{row.all}</TableCell>
                </TableRow>
            );
        });
        // Add current user in list if it is not in top 10.
        if (!userIsInTop) {
            const userResults = (statistics.user_statistics.userResults[this.buildMonth()] !== undefined) ? statistics.user_statistics.userResults[this.buildMonth()] : {correct: 0, all: 0};
            let userRow = (
                <TableRow key={currentPosition} className={classes.currentUser}>
                    <TableCell component="th" scope="row">{currentPosition}</TableCell>
                    <TableCell>{statistics.user_statistics.name}</TableCell>
                    <TableCell align="right">{(userResults.all) ? Math.round(userResults.correct * 100 / userResults.all) : "0"}</TableCell>
                    <TableCell align="right">{userResults.correct * 100}</TableCell>
                    <TableCell align="right">{userResults.all}</TableCell>
                </TableRow>
            );
            let newRanking = [rankingTableRows, this.getEmptyTableRow(11), userRow, this.getEmptyTableRow(currentPosition+1)];
            return newRanking;
        }
        return [rankingTableRows, this.getEmptyTableRow(11)];
    }

    render() {
        const {statistics, classes} = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <>
                {statistics !== undefined &&
                <Dialog
                    fullScreen={isFull}
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <AppBar className={classes.appBar}>

                        <Tabs value={this.state.tab} onChange={this.changeTab}>
                            <Tab label="Puncte"/>
                            <Tab label="Note"/>
                        </Tabs>
                        <IconButton className={classes.exitButton} edge="start" color="inherit"
                                    onClick={this.props.handleClose} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                    </AppBar>
                    <DialogContent className={classes.container}>
                        <MonthSelector handleMonthChange={this.handleMonthChange}/>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Poziţie</TableCell>
                                    <TableCell align="right">Nume</TableCell>
                                    <TableCell align="right">Medie</TableCell>
                                    <TableCell align="right">Punctaj</TableCell>
                                    <TableCell align="right">Întrebări</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.getTableBody()}
                                {/*    {rows.map((row) => (*/}
                                {/*        <TableRow key={row.name}>*/}
                                {/*            <TableCell component="th" scope="row">*/}
                                {/*                {row.name}*/}
                                {/*            </TableCell>*/}
                                {/*            <TableCell align="right">{row.calories}</TableCell>*/}
                                {/*            <TableCell align="right">{row.fat}</TableCell>*/}
                                {/*            <TableCell align="right">{row.carbs}</TableCell>*/}
                                {/*            <TableCell align="right">{row.protein}</TableCell>*/}
                                {/*        </TableRow>*/}
                                {/*    ))}*/}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </>
        );
    }
}

export default withWidth()(withStyles(styles)(StatisticsTop10Dialog));