import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class QuestionsNumberDialog extends React.Component {

    state = {
        questionsCount: 10,
    };

    handleChange = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    handleStartTest = () => {
        this.props.handleStart(this.state.questionsCount);
    };

    render() {
        const {classes} = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Alege numărul de întrebări</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Câte întrebari doreşti sa primeşti in acest test?
                        </DialogContentText>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="chapter">Număr întrebări</InputLabel>
                            <Select
                                value={this.state.questionsCount}
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'questionsCount',
                                    id: 'questionsCount',
                                }}
                            >
                                <MenuItem value={10}>10</MenuItem>
                                <MenuItem value={20}>20</MenuItem>
                                <MenuItem value={30}>30</MenuItem>
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleStartTest} color="primary">
                            Începe Test
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(QuestionsNumberDialog);