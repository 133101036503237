import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import coverImage from "../../../images/testStart/check-knowledge.jpg";
import Divider from "@material-ui/core/Divider";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import Chip from "@material-ui/core/Chip";
import QuestionsCountField from "../../form/QuestionsCountField";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
        marginTop: theme.spacing(1),
    },
    dialogTitle: {
        backgroundImage: "url(" + coverImage + ")",
        backgroundSize: 'cover',
        height: '25vh',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    actions: {
        margin: theme.spacing(2),
    },
});

class ConfigureCheckKnowledgeDialog extends React.Component {

    state = {
        name: '',
        questionsCount: 30,
        timeLimit: 30,
        timing: false,
        subject: '',
        selections: [],
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleNumberChange = number => {
        this.setState({questionsCount: number});
    };

    getSubjects = () => {
        let subjects = [];
        let termsStructureKeys = Object.keys(this.props.termsChaptersStructure);
        for (let key in termsStructureKeys) {
            let tid = termsStructureKeys[key];
            let term = Object.assign({}, this.props.termsByTid[tid]);
            term.questions = this.props.termsChaptersStructure[tid].questionsCount;
            subjects.push(term);
        }
        return subjects;
    };

    handleDelete = chipToDelete => () => {
        this.setState({
            selections: this.state.selections.filter(chip => chip.key !== chipToDelete.key)
        });
    };

    getSelectedTerms() {
        let terms = [];
        this.state.selections.map((term) => {
            terms.push(term.key);
            return true;
        });
        return terms;
    }

    handleAdd = () => {
        let term;
        if (this.state.subject !== '') {
            term = this.state.subject;
        }
        if (!this.getSelectedTerms().includes(term)) {
            this.setState({
                selections: [...this.state.selections, {
                    key: term,
                    label: this.props.termsByTid[term].name.substring(0, 35) + (this.props.termsByTid[term].name.length > 36 ? '...' : ''),
                }],
                subject: '',
            });
        }
    };

    handleStartTest = () => {
        this.props.handleStart({
            type: 'chckKnowledge',
            exam: 'medschool',
            ...this.state
        });
    };

    render() {
        const { classes } = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return (
            <div>
                <Dialog
                    fullScreen={isFull}
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialogTitle} id="form-dialog-title"/>
                    <DialogTitle id="form-dialog-title">Verifică-ţi cunoştinţele</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Configurează teste după preferinţe, din mai multe materii şi capitole
                        </DialogContentText>
                        <TextField
                            onChange={this.handleChange}
                            margin="dense"
                            name="name"
                            id="name"
                            label="Nume Test"
                            defaultValue={"Test " + this.props.nextTestId}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="subject">Materie</InputLabel>
                            <Select
                                value={this.state.subject}
                                onChange={this.handleChange}
                                label="Materie"
                                inputProps={{
                                    name: 'subject',
                                    id: 'subject',
                                }}
                            >
                                <MenuItem value="">
                                    <em>- Alege Materia -</em>
                                </MenuItem>
                                {Object.values(this.props.termsChaptersStructure).length > 0 && this.getSubjects().map(subject => {
                                    return (
                                        <MenuItem key={subject.tid} value={subject.tid}>{subject.name} ({subject.questions})</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {
                            this.state.subject !== "" &&
                            <>
                                <Divider className={classes.divider} />
                                <Button variant="contained" onClick={this.handleAdd} color="primary">
                                    Adaugă selecţia
                                </Button>
                            </>
                        }
                        {
                            this.state.selections.length > 0 &&
                            <>
                                <Divider className={classes.divider} />
                                <DialogContentText>
                                    <strong>Mai poţi adăuga materii</strong>, sau poţi începe testul cu selecţia curentă:
                                </DialogContentText>
                            </>
                        }
                        {this.state.selections.map(data => {
                            return <Chip
                                key={data.key}
                                label={data.label}
                                onDelete={this.handleDelete(data)}
                                className={classes.chip}
                            />
                        })
                        }
                        {/*TODO: Extract this into component for reuse.*/}
                        {
                            <>
                                <Divider className={classes.divider} />
                                <QuestionsCountField
                                    handleChange={this.handleNumberChange}
                                    default={this.state.questionsCount}
                                    questionCountOptions={[30, 50, 100, 200]}
                                    limit="200"
                                    variant="outlined"
                                />
                                <FormControl variant="outlined">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.timing}
                                                onChange={this.handleCheckboxChange}
                                                inputProps={{
                                                    name: 'timing',
                                                    id: 'timing',
                                                }}
                                            />
                                        }
                                        label="Contratimp"
                                    />

                                </FormControl>
                                {
                                    this.state.timing &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="timeLimit">Vei avea un timp limitat pentru a rezolva toate întrebarile testului.</InputLabel>
                                        <Select
                                            value={this.state.timeLimit}
                                            onChange={this.handleChange}
                                            label="Vei avea un timp limitat pentru a rezolva toate întrebarile testului."
                                            inputProps={{
                                                name: 'timeLimit',
                                                id: 'timeLimit',
                                            }}
                                        >
                                            <MenuItem value={30}>30 de minute</MenuItem>
                                            <MenuItem value={60}>O oră</MenuItem>
                                            <MenuItem value={90}>90 de minute</MenuItem>
                                            <MenuItem value={120}>Două ore</MenuItem>
                                            <MenuItem value={180}>Trei ore</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                        {
                            this.state.selections.length > 0 &&
                            <Button variant="contained" onClick={this.handleStartTest} color="primary">
                                Începe Test
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(ConfigureCheckKnowledgeDialog));