import React from 'react';
import Button from '@material-ui/core/Button/index';
import TextField from '@material-ui/core/TextField/index';
import Dialog from '@material-ui/core/Dialog/index';
import DialogActions from '@material-ui/core/DialogActions/index';
import DialogContent from '@material-ui/core/DialogContent/index';
import DialogContentText from '@material-ui/core/DialogContentText/index';
import DialogTitle from '@material-ui/core/DialogTitle/index';
import {IconButton, Tooltip, withStyles} from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import PropTypes from "prop-types";
import * as testsSelectors from "../../store/tests/reducer";
import * as authenticationSelectors from "../../store/authentication/reducer";
import * as bugsSelectors from "../../store/bugs/reducer";
import * as bugsActions from "../../store/bugs/actions";
import * as testsActions from "../../store/tests/actions";
import {connect} from "react-redux";


const styles = theme => ({
    root: {
        marginLeft: 'auto'
    },
    rightIcons: {
        // marginLeft: 'auto',
    },
});

class ReportQuestionBugForm extends React.Component {
    state = {
        open: false,
        bugDescription: ''
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSubmit = () => {
        const bugData = {
            "questionReported": this.props.question.nid,
            "bugReportText": this.state.bugDescription,
        };
        this.props.dispatch(bugsActions.reportBug(this.props.token, JSON.stringify(bugData)));
        this.props.dispatch(testsActions.setMessage('success', 'Problema a fost semnalată cu succes!'));
        this.setState({
            open: false,
        })
    };

    render() {
        const { classes, question } = this.props;
        return (
            <span className={classes.root}>
                <Tooltip title="Raportează un Bug" placement="left">
                    <IconButton aria-label="BugReport" className={classes.rightIcons} onClick={this.handleClickOpen}>
                        <BugReportIcon />
                    </IconButton>
                </Tooltip>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">Raportează un bug</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Aici poţi raporta o problemă legată de întrebarea curentă.
                        </DialogContentText>
                        <TextField
                            marin="dense"
                            id="question-id"
                            label="#ID întrebare"
                            type="text"
                            defaultValue={question !== undefined ? question.nid : ''}
                            disabled
                            fullWidth
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Descrie aici problema detectată"
                            type="text"
                            onChange={this.handleChange}
                            multiline
                            inputProps={{
                                name: 'bugDescription',
                                id: 'bugDescription',
                            }}
                            rows={2}
                            rowsMax={7}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Renunţă
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Raportează
                        </Button>
                    </DialogActions>
                </Dialog>
            </span>
        );
    }
}

ReportQuestionBugForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    const [error, errorMessage, messageType]  = testsSelectors.getError(state);
    return {
        userBugs: bugsSelectors.getUserBugs(state),
        token: authenticationSelectors.getAccessToken(state),
        error: error,
        errorMessage: errorMessage,
        messageType: messageType,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(ReportQuestionBugForm));
