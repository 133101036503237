import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    darkTheme: localStorage.getItem('darkTheme') === 'true',
    blackFriday: localStorage.getItem('blackFriday') === 'true',
    windowSize: {width: 0, height: 0},
    selectedExam: localStorage.getItem('selectedExam') !== null ? localStorage.getItem('selectedExam') : 'notSelected',
});


export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.DARK_THEME_CHANGE:
            localStorage.setItem('darkTheme', action.darkTheme);
            return state.merge({darkTheme: action.darkTheme});

        case types.SETTINGS_LOAD_SUCCESS:
            localStorage.setItem('blackFriday', action.settings.blackFriday);
            return state.merge({
                blackFriday: action.settings.blackFriday,
                activePromotion: action.settings.activePromotion,
            });

        case types.UPDATE_WINDOW_SIZE:
            return state.merge({windowSize: {width: action.width, height: action.height}});

        case types.SETTINGS_LOAD_FAIL:
            return state;

        case types.SELECT_EXAM:
            localStorage.setItem('selectedExam', action.selectedExam)
            return state.merge({selectedExam: action.selectedExam});

        default:
            return state;
    }
}

export function getDarkTheme(state) {
    return state.settings.darkTheme;
}

export function activePromotion(state) {
    return state.settings.activePromotion;
}

export function getWindowSize(state) {
    return state.settings.windowSize;
}

export function getSelectedExam(state) {
    return state.settings.selectedExam;
}