import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem/index'
import { withStyles } from '@material-ui/core/styles/index';
import ListItemIcon from '@material-ui/core/ListItemIcon/index';
import Typography from '@material-ui/core/Typography/index'
import Menu from '@material-ui/core/Menu/index';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton/index";
import * as testsActions from "../../store/tests/actions";
import * as authenticationSelectors from "../../store/authentication/reducer";
import DeleteTestDialog from "../../components/dialogs/DeleteTestDialog";

const styles = {
    root: {
        display: 'inline',
    },

    rightButton: {
        float: 'right',
    }
};

class TestResultsDialogMenu extends React.Component {
    state = {
        anchorEl: null,
        deleteDialogOpen: false,
    };

    handleMenuOpen = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDelete = () => {

    };

    handleClick = (event) => {
        switch (event) {
            case 'delete':
                if (this.state.deleteDialogOpen) {
                    this.props.dispatch(testsActions.deleteUserTest(this.props.token, this.props.testId));
                    this.setState({deleteDialogOpen: false});
                    this.props.closeDialog();
                }
                else {
                    this.setState({deleteDialogOpen: true});
                }
                return;
            case 'edit':
                this.props.dispatch(testsActions.setMessage('warning', 'În curând va fi diponibilă această funcţie'));
                return;
            default:
                this.handleClose();
                return;
        }
    };

    render() {
        const { anchorEl } = this.state;
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <IconButton
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                    className={classes.rightButton}
                >
                    <MoreVertIcon/>
                </IconButton>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => this.handleClick('edit')}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" name="edit">Editează</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => this.handleClick('delete')}>
                        <ListItemIcon>
                            <DeleteIcon />
                        </ListItemIcon>
                        <Typography variant="inherit" name="delete">Şterge</Typography>
                    </MenuItem>
                </Menu>
                <DeleteTestDialog
                    open={this.state.deleteDialogOpen}
                    deleteTest={() => this.handleClick('delete')}
                    handleClose={() => this.setState({deleteDialogOpen: false})}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        token: authenticationSelectors.getAccessToken(state),
    };
}

TestResultsDialogMenu.propTypes = {
    classes: PropTypes.object.isRequired,
};


export default connect(mapStateToProps)(withStyles(styles)(TestResultsDialogMenu));