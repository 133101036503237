import React from 'react';
import {connect} from 'react-redux';
import * as testsActions from '../store/tests/actions'
import * as testsSelectors from '../store/tests/reducer';

class Timer extends React.Component {

    state = {
        minutes: 60,
        seconds: 0,
        started: false,
    };

    componentDidMount() {
        this.startCountDown();

    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    timeIsUp = () => {
        let testId = this.props.testInProgress.reactId;
        this.props.dispatch(testsActions.timeIsUp());
        if (this.props.history !== undefined) {
            this.props.history.push('/tests/' + testId + '/results');
        }
    };

    getRemainingTime = () => {
        if (!this.props.startTime) {
            return this.props.limit;
        }
        let elapsedTime = (Date.now() - this.props.startTime) / 1000;
        return this.props.limit * 60 - elapsedTime;
    };

    startCountDown() {
        this.myInterval = setInterval(() => {
            if (!this.props.isCountdown) {
                clearInterval(this.myInterval);
                return;
            }
            let time = this.getRemainingTime();
            if (time < 0) {
                this.timeIsUp();
            }
            let minutes = Math.floor(time / 60);
            let seconds = Math.floor(time % 60);
            this.setState({
                minutes: minutes,
                seconds: seconds,
                started: true,
            });
            if (seconds === 0 && minutes === 0) {
                clearInterval(this.myInterval);
                this.timeIsUp();
            }
        }, 1000)
    }

    render() {
        const {minutes, seconds} = this.state;
        const {isCountdown} = this.props;
        if (isCountdown && this.state.started) {
            return (
                <>
                    {this.props.children}
                    {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </>
            );
        }
        return (<></>);
    }
}

function mapStateToProps(state) {
    const {isCountdown, startTime, limit} = testsSelectors.getTimeData(state);
    return {
        isCountdown: isCountdown,
        startTime: startTime,
        limit: limit,
        testInProgress: testsSelectors.getUnfinishedTest(state),
    }
}

export default connect(mapStateToProps)(Timer);