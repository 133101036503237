import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/CheckCircle';
import CommentIcon from '@material-ui/icons/Comment';
import PendingIcon from '@material-ui/icons/HourglassFull';
import {Tooltip} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const styles = theme => ({
    card: {
        margin: theme.spacing(),
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    actions: {
        display: 'flex',
    },
    expand: {
        transform: 'rotate(0deg)',
        // marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        // transform: 'rotate(180deg)',
    },
    paper: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
    },
});

class BugItem extends React.Component {
    state = { expanded: false };

    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };

    render() {
        const { classes, bugInfo } = this.props;
        let createdDate = new Date(bugInfo.created * 1000);
        return (
            <Card className={classes.card}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="Recipe" className={classes.avatar}>
                            #{this.props.itemNo}
                        </Avatar>
                    }
                    title={"Raportare Problema #" + bugInfo.nid}
                    subheader={createdDate.toLocaleDateString() + ' ' + createdDate.toLocaleTimeString()}
                />
                <CardContent>
                    <Typography variant="h6">
                        Ai raportat urmatoarea problema:
                    </Typography>
                    <Typography variant="body1"
                                dangerouslySetInnerHTML={{ __html: bugInfo.bugDescription}}
                    />
                </CardContent>
                <CardActions className={classes.actions}>
                    { !bugInfo.closed &&
                        <Tooltip title="În procesare">
                            <IconButton aria-label="In procesare">
                                <PendingIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {  bugInfo.closed &&
                        <Tooltip title={"Rezolvat"}>
                            <IconButton aria-label="Rezolvat">
                                <CheckIcon/>
                            </IconButton>
                        </Tooltip>
                    }

                    {
                        bugInfo.comments.length > 0 &&
                        <Tooltip title={"Ai primit răspuns la problema raportată"}>
                            <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: this.state.expanded,
                                })}
                                onClick={this.handleExpandClick}
                                aria-expanded={this.state.expanded}
                                aria-label="Arata mai mult"
                            >
                                <CommentIcon />
                            </IconButton>
                        </Tooltip>
                    }

                </CardActions>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        { bugInfo.comments.map((comment) => {
                            let created = new Date(comment.created * 1000);
                            return (
                                <Paper className={classes.paper} key={comment.cid}>
                                    <Typography
                                        paragraph
                                        dangerouslySetInnerHTML={{ __html: comment.body}}
                                    />
                                    <Typography variant="caption">
                                        {created.toLocaleDateString() + ' ' + created.toLocaleTimeString()}
                                    </Typography>
                                </Paper>
                            );
                        })
                        }
                    </CardContent>
                </Collapse>
            </Card>
        );
    }
}

BugItem.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BugItem);