import React from 'react';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
        marginTop: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});


class QuestionsCountField extends React.Component {

    state = {
        customNumber: this.props.default !== undefined ? this.props.default : 30,
        questionsCount: this.props.default !== undefined ? this.props.default : 30,
        custom: false,
        limit: this.props.limit !== undefined ? this.props.limit : 100,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.limit !== this.props.limit) {
            this.setState({
                limit: this.props.limit,
                customNumber: this.state.customNumber < this.props.limit ? this.state.customNumber : this.props.limit,
            });
        }
    }

    handleChange = event => {
        switch (event.target.name) {
            case 'questionsCount':
                if (event.target.value === 'other') {
                    this.setState({
                        custom: true,
                        questionsCount: 'other'
                    });
                }
                else {
                    this.setState({
                        custom: false,
                        [event.target.name]: event.target.value,
                        customNumber: event.target.value,
                    });
                    this.props.handleChange(event.target.value);
                }
                break;

            case 'customNumber':
                let number = event.target.value;
                if (number > this.state.limit) {
                    number = this.state.limit;
                }
                if (number < 0) {
                    number = 0;
                }
                this.setState({ [event.target.name]: number });
                this.props.handleChange(number);
                break;

            default:
                break;
        }
    };

    render() {
        const { classes, questionCountOptions } = this.props;
        return (
            <>
                <FormControl variant={this.props.variant} className={classes.formControl}>
                    <InputLabel id="questionsCount">Număr întrebări</InputLabel>
                    <Select
                        value={this.state.questionsCount}
                        label="Număr întrebări"
                        onChange={this.handleChange}
                        inputProps={{
                            name: 'questionsCount',
                            id: 'questionsCount',
                        }}
                    >
                        {questionCountOptions.map(option => {
                            if (this.state.limit >= option) {
                                return <MenuItem key={option} value={option}>{option}</MenuItem>
                            }
                            return false;
                        })}
                        <MenuItem value={'other'}>Alt număr</MenuItem>
                    </Select>
                </FormControl>
                {this.state.custom &&
                <FormControl className={classes.formControl}>
                    <TextField
                        variant={this.props.variant}
                        autoFocus
                        onChange={this.handleChange}
                        margin="dense"
                        name="customNumber"
                        id="customNumber"
                        label="Alege număr"
                        type="number"
                        value={this.state.customNumber}
                    />
                </FormControl>
                }
            </>
        );
    }
}

export default withStyles(styles)(QuestionsCountField);