import React, {Component} from 'react';
import {
    Grid,
    withStyles
} from "@material-ui/core";
import PropTypes from "prop-types";
import QuestionsNumberDialog from "../dialogs/QuestionsNumberDialog";
import ConfigureChaptersTestDialog from "../dialogs/police/ConfigureChaptersTestDialog";
import TestCard from "../TestCard";
import chaptersImage from "../../images/testStart/chapters-1.jpg"
import previousYearsImage from "../../images/testStart/previous-years.jpg"
import checkKnowledge from "../../images/testStart/check-knowledge.jpg"
import examSimulation from "../../images/testStart/exam-simulation.jpg"
import lessonsImage from "../../images/studying-ahead.jpg"
import ConfigureYearsTestDialog from "../dialogs/police/ConfigureYearsTestDialog";
import CheckKnowledgeTestDialog from "../dialogs/police/CheckKnowledgeTestDialog";
import SimulateTestDialog from "../dialogs/police/SimulateTestDialog";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";

const styles = theme => ({

    card: {
        height: '100%',
        // maxWidth: 700,
        color: theme.palette.text.secondary,
    },
    button: {
        margin: theme.spacing(1),
    },
    control: {
        padding: theme.spacing(2),
    },
    media: {
        height: 0,
        paddingTop: '7%', // 16:9
        [theme.breakpoints.up('md')]: {
            paddingTop: '30%',
        },
    },
    actions: {
        display: 'flex',
    },
});


class NewTestBox extends Component {

    state = {
        configureDialogOpen: false,
        configureChaptersDialogOpen: false,
        configureYearsDialogOpen: false,
        checkKnowledgeDialogOpen: false,
        simulateTestDialogOpen: false,
        randomDialogOpen: false,
        questionsCountDialogOpen: false,
        testType: 'freeTest',
        currentParams: {},
    };

    handleClick = (event, params = false, questionsCount = 10) => {
        this.props.handleClick(event, params, questionsCount);
    };

    handleOpenConfigChaptersDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            configureChaptersDialogOpen: true,
        });
    };

    handleOpenConfigYearsDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            configureYearsDialogOpen: true,
        });
    };

    handleOpenCheckKnowledgeDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            checkKnowledgeDialogOpen: true,
        });
    };

    handleOpenSimulateTestDialog = () => {
        if (this.checkTestInProgress()) {
            return false;
        }
        this.setState({
            simulateTestDialogOpen: true,
        });
    };

    handleOpenConfigDialog = () => {
        this.setState({
            configureDialogOpen: true,
        });
    };
    handleOpenRandomDialog = () => {
        this.setState({
            randomDialogOpen: true,
        });
    };
    handleOpenQuestionsCountDialog = (testType, currentParams) => {
        this.setState({
            questionsCountDialogOpen: true,
            testType: testType,
            currentParams: currentParams,
        });
    };

    handleStartTest = (questionsCount) => {
        this.handleClick(this.state.testType, this.state.currentParams, questionsCount);
        this.setState({
            questionsCountDialogOpen: false,
        });
    };

    handleCloseConfigDialog = () => {
        this.setState({
            configureDialogOpen: false,
            configureChaptersDialogOpen: false,
            configureYearsDialogOpen: false,
            simulateTestDialogOpen: false,
            checkKnowledgeDialogOpen: false,
            questionsCountDialogOpen: false,
            randomDialogOpen: false,
        });
    };

    checkTestInProgress = () => {
        if (this.props.testInProgress) {
            this.props.finishTestInProgress();
            return true;
        }
        return false;
    };

    handleStartConfiguredTest = (configurations) => {
        this.handleClick(configurations.type, configurations);
        this.setState({
            configureDialogOpen: false,
            configureChaptersDialogOpen: false,
            configureYearsDialogOpen: false,
            simulateTestDialogOpen: false,
            checkKnowledgeDialogOpen: false,
        });
    };

    render() {
        const {termsStructure, termsChaptersStructure, termsYearsStructure, termsByTid, questionsCount} = this.props;
        const isFull = !(isWidthUp('md', this.props.width));
        return(
            <Grid item>
                <TestCard
                    testType={"Pe capitole"}
                    testTypeDescription={"Testează-ţi cunoştinţele pe capitolele selectate"}
                    questionsCount={questionsCount['chapters']}
                    image={chaptersImage}
                    onClick={this.handleOpenConfigChaptersDialog}
                />
                <TestCard
                    testType={"Ani precedenţi"}
                    testTypeDescription={"Testează-ţi cunoştinţele pornind de la examenele din anii anteriori"}
                    questionsCount={questionsCount['years']}
                    image={previousYearsImage}
                    onClick={this.handleOpenConfigYearsDialog}
                />
                <TestCard
                    testType={"Verifică-ţi cunoştinţele"}
                    testTypeDescription={"Generator de teste dinamice în funcţie de opţiunile tale"}
                    questionsCount={questionsCount['checkKnowledge']}
                    image={checkKnowledge}
                    onClick={this.handleOpenCheckKnowledgeDialog}
                />
                <TestCard
                    testType={"Simulează Examen"}
                    testTypeDescription={"Teste finale, pregătitoare pentru examen"}
                    questionsCount={questionsCount['simulate']}
                    image={examSimulation}
                    onClick={this.handleOpenSimulateTestDialog}
                />
                <TestCard
                    testType={"Lecţii"}
                    testTypeDescription={"Acumulează cunoștințe aprofundând materiile pentru examen"}
                    image={lessonsImage}
                    onClick={this.props.handleLessonsClick}
                />
                <>
                    <ConfigureChaptersTestDialog
                        testsTerms={this.props.testsTerms}
                        open={this.state.configureChaptersDialogOpen}
                        handleClose={this.handleCloseConfigDialog}
                        handleStart={this.handleStartConfiguredTest}
                        termsByTid={termsByTid}
                        termsChaptersStructure={termsChaptersStructure}
                        nextTestId={this.props.nextTestId}
                        isFull={isFull}
                    />
                    <CheckKnowledgeTestDialog
                        testsTerms={this.props.testsTerms}
                        open={this.state.checkKnowledgeDialogOpen}
                        handleClose={this.handleCloseConfigDialog}
                        handleStart={this.handleStartConfiguredTest}
                        termsByTid={termsByTid}
                        termsChaptersStructure={termsChaptersStructure}
                        nextTestId={this.props.nextTestId}
                        isFull={isFull}
                    />
                    <ConfigureYearsTestDialog
                        testsTerms={this.props.testsTerms}
                        open={this.state.configureYearsDialogOpen}
                        handleClose={this.handleCloseConfigDialog}
                        handleStart={this.handleStartConfiguredTest}
                        termsByTid={termsByTid}
                        termsStructure={termsStructure}
                        termsYearsStructure={termsYearsStructure}
                        nextTestId={this.props.nextTestId}
                        isFull={isFull}
                    />
                    <SimulateTestDialog
                        testsTerms={this.props.testsTerms}
                        open={this.state.simulateTestDialogOpen}
                        handleClose={this.handleCloseConfigDialog}
                        handleStart={this.handleStartConfiguredTest}
                        termsByTid={termsByTid}
                        termsStructure={termsStructure}
                        nextTestId={this.props.nextTestId}
                        isFull={isFull}
                    />
                    <QuestionsNumberDialog
                        open={this.state.questionsCountDialogOpen}
                        handleClose={this.handleCloseConfigDialog}
                        handleStart={this.handleStartTest}
                    />
                </>
            </Grid>
        );
    }
}

NewTestBox.propTypes = {
    classes: PropTypes.object.isRequired,
    subjects: PropTypes.array.isRequired,
    testsTerms: PropTypes.array.isRequired,
    termsByTid: PropTypes.array.isRequired,
    nextTestId: PropTypes.number.isRequired,
    // termsStructure: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles)(NewTestBox));