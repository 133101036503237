import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class EmailConfirmationDialog extends React.Component {

    mailConfirmed = () => {
        window.location.reload();
    }

    render() {
        const { generated } = this.props;
        const now = Math.floor(Date.now() / 1000);
        let remaining = (7 - Math.floor((now - generated) / 24 / 3600));
        let date = remaining !== 1 ? remaining + ' zile rămase' : 'o zi rămasă';
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Confirmare adresă de e-mail
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Încă nu ţi-ai confirmat adresa de email.</Typography>
                        <Typography>Mai ai doar {date} pentru a face asta.</Typography>
                        <Typography>Pentru a nu risca să îţi fie dezactivat contul, confirmă-ţi adresa.</Typography>
                        <Typography>Poţi face asta accesând adresa de mail cu care te-ai înregistrat şi apăsând pe linkul de confirmare primit.</Typography>
                        <Typography>În cazul în care nu găseşti mailul, verifică-ţi şi folderul "Spam".</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.mailConfirmed} color="primary">
                            Am confirmat
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(EmailConfirmationDialog);