const API_URL = process.env.REACT_APP_API_URL;


class StaticPagesService {
    async getStaticPage(page) {
        const url = '/grile_api/static_pages_resource/?_format=json';
        const method = 'POST';
        let data = [];
        const body = {
            page: page,
        };
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await fetch(API_URL + url, {
            method: method,
            body: JSON.stringify(body),
            headers: headers,
        });
        if (response.status === 200) {
            data = await response.json();
        }
        else {
            let errorResponse = await response.json();
            data = {
                error: true,
                rawData: errorResponse,
            };
            console.error('Fetch error - code ' + response.status);
        }
        return data;
    }
}

export default new StaticPagesService();