import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
    },
    box: {
        margin: theme.spacing(10),
    }
});

function CircularLoaderContent(props) {
    const { classes, small } = props;
    if (small) {
        return <CircularProgress className={classes.progress} />;
    }
    return (
        <div className={classes.box}>
            <CircularProgress className={classes.progress} />
        </div>
    );
}

CircularLoaderContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CircularLoaderContent);