import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Grid, withStyles} from "@material-ui/core";
import * as statisticsActions from '../store/statistics/actions';
import * as statisticsSelectors from "../store/statistics/reducer";

import Helmet from "react-helmet";
import ChartWrapper from "../components/charts/ChartWrapper";
import StatisticsTop from "../components/StatisticsTop";

const styles = theme => ({
    chartPaper: {
        width: '100%',
    },
});

class Statistics extends Component {

    componentDidMount() {
        this.props.dispatch(statisticsActions.loadTermsData());
        this.props.dispatch(statisticsActions.getStatistics());
    }

    render() {
        const {classes, statistics, termsData} = this.props;
        return (
            <>
                <Helmet>
                    <title>Statistici - Grile Politie</title>
                </Helmet>
                <Grid container justify="center" className={classes.root} spacing={2}>
                    <Grid item xs={12} className={classes.chartPaper}>
                        <StatisticsTop statistics={statistics} />
                    </Grid>
                    <Grid item md={6} className={classes.chartPaper}>
                        <ChartWrapper type="questionsCountEvolution" title="Număr întrebări" statistics={statistics}
                                      termsData={termsData}/>
                    </Grid>
                    <Grid item md={6} className={classes.chartPaper}>
                        <ChartWrapper type="gradesEvolution" title="Evoluţia notelor" statistics={statistics}
                                      termsData={termsData}/>
                    </Grid>
                    <Grid item md={6} className={classes.chartPaper}>
                        <ChartWrapper type="questionsCountEvolution" title="Număr întrebări - Filtrare"
                                      statistics={statistics} termsData={termsData} withFilter/>
                    </Grid>
                    <Grid item md={6} className={classes.chartPaper}>
                        <ChartWrapper type="gradesEvolution" title="Evoluţia notelor - Filtrare" statistics={statistics}
                                      termsData={termsData} withFilter/>
                    </Grid>
                </Grid>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        statistics: statisticsSelectors.getStatistics(state),
        termsData: statisticsSelectors.getTermsKeyedWithTids(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Statistics));