import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    statistics: {},
    termsData: localStorage.getItem('testsTerms') ? JSON.parse(localStorage.getItem('testsTerms')) : [],
});

export default function reduce(state = initialState, action = {}) {
    switch (action.type) {
        case types.STATISTICS_LOAD_SUCCESS:
            return state.merge({
                statistics: action.statisticsData,
            });

        case types.TERMS_LOAD_SUCCESS:
            return state.merge({
                termsData: action.termsData,
            })

        case types.TERMS_LOAD_FAIL:
        case types.STATISTICS_LOAD_FAIL:
            return state;

        default:
            return state;
    }
}

export function getStatistics(state) {
    return state.statistics.statistics;
}

export function getTermsKeyedWithTids(state) {
    let terms = [];
    for (let key = 0; key < state.statistics.termsData.length; key++) {
        terms[state.statistics.termsData[key].tid] = state.statistics.termsData[key].name;
    }
    return terms;
}