import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import AppBar from '@material-ui/core/AppBar/index';
import Toolbar from '@material-ui/core/Toolbar/index';
import Typography from '@material-ui/core/Typography/index';
import IconButton from '@material-ui/core/IconButton/index';
import MenuIcon from '@material-ui/icons/Menu';
import CardIcon from '@material-ui/icons/Payment';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import MyAccountIcon from '@material-ui/icons/Person';
import MenuItem from '@material-ui/core/MenuItem/index';
import Menu from '@material-ui/core/Menu/index';
import Avatar from "@material-ui/core/Avatar/index";
import MiniDrawer from "./MiniDrawer";
import CssBaseline from "@material-ui/core/CssBaseline/index";
import classNames from "classnames";
import logo from '../../images/logo.png';
import {ClickAwayListener, LinearProgress} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText/index";
import ListItemIcon from "@material-ui/core/ListItemIcon/index";
import LinkIconButton from "../linkComponents/LinkIconButton";
import LinkMenuItem from "../linkComponents/LinkMenuItem";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from "@material-ui/core/Badge";
import EmailConfirmationDialog from "../dialogs/EmailConfirmationDialog";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import InfoIcon from '@material-ui/icons/Info';
import TimerInfoDialog from "../dialogs/TimerInfoDialog";
import {SwapHorizontalCircle} from "@material-ui/icons";

const drawerWidth = 200;

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginLeft: 4,
        marginRight: 0,
        [theme.breakpoints.up('sm')]: {
            marginRight: 36,
        },
    },
    hide: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
});

class MenuAppBar extends React.Component {
    state = {
        anchorEl: null,
        drawerOpen: false,
        emailConfirmationDialogOpen: false,
        timerInfoDialogOpen: false,
        topBarTitle: isWidthUp('sm', this.props.width) ? 'Grile Examene' : 'Grile',
        showTimer: false,
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleDialogClose = () => {
        this.setState({
            timerInfoDialogOpen: false,
            emailConfirmationDialogOpen: false,
        });
    };

    openEmailConfirmationDialog = () => {
        this.setState({emailConfirmationDialogOpen: true})
    };

    openTimerInfoDialog = () => {
        this.setState({timerInfoDialogOpen: true})
    };

    goPremiumAction = () => {
        window.location.href = '/products';
    }

    handleLogout = () => {
        this.setState({ anchorEl: null });
        this.props.logout();
    };

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ drawerOpen: false });
    };

    getRemainingTime = () => {
        if (this.props.userData.subscription === 'free') {
            let remainingTime = this.props.userData.todayAccess + 900 - (Date.now() / 1000);
            if (remainingTime > 0) {
                if (this.myInterval === undefined) {
                    this.myInterval = setInterval(() => {
                        let remainingTime = this.props.userData.todayAccess + 900 - (Date.now() / 1000);
                        if (remainingTime < 0) {
                            clearInterval(this.myInterval);
                            this.setState({
                                topBarTitle: isWidthUp('sm', this.props.width) ? 'Grile Examene' : 'Grile',
                                showTimer: false,
                            });
                            window.location.reload();
                        } else {
                            let minutes = Math.floor(remainingTime / 60);
                            let seconds = Math.round(remainingTime % 60);
                            this.setState({
                                topBarTitle: minutes + ":" + (seconds < 10 ? "0" + seconds : seconds),
                                showTimer: true,
                            });
                        }
                    }, 1000);
                }
            }
        }
    }

    render() {
        const { classes, isAuthenticated } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        this.getRemainingTime();
        return (
            <div className={classes.root}>
                <CssBaseline />
                <ClickAwayListener onClickAway={this.handleDrawerClose}>
                    <div>
                        <AppBar
                            position="fixed"
                            className={classNames(classes.appBar, {
                                [classes.appBarShift]: this.state.drawerOpen,
                            })}
                        >
                            <Toolbar disableGutters={!this.state.drawerOpen}>
                                <IconButton
                                    color="inherit"
                                    aria-label="Open drawer"
                                    onClick={this.handleDrawerOpen}
                                    className={classNames(classes.menuButton, {
                                        [classes.hide]: this.state.drawerOpen,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <Typography variant="h6" color="inherit" className={classes.grow}>
                                    <LinkIconButton to="/">
                                        <Avatar aria-label="SiteLogo" className={classes.avatar} src={logo}>
                                            GR
                                        </Avatar>
                                    </LinkIconButton> {this.state.drawerOpen ? '' : this.state.topBarTitle}
                                {this.state.showTimer &&
                                    <IconButton onClick={this.openTimerInfoDialog} color="inherit"><InfoIcon/></IconButton>
                                }
                                </Typography>
                                {
                                    isAuthenticated && this.props.userData.mailConfirmationPending &&
                                    <IconButton
                                        aria-label="show new notifications"
                                        color="inherit"
                                        onClick={this.openEmailConfirmationDialog}
                                    >
                                        <Badge badgeContent={1} color="secondary">
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>
                                }
                                {isAuthenticated && (
                                    <div>
                                        <LinkIconButton to="/exam"><SwapHorizontalCircle /></LinkIconButton>
                                        <IconButton
                                            aria-owns={open ? 'menu-appbar' : undefined}
                                            aria-haspopup="true"
                                            onClick={this.handleMenu}
                                            color="inherit"
                                        >
                                            {

                                                ( this.props.userData.photo &&
                                                    <Avatar alt="Profile Picture" src={this.props.userData.photo} className={classes.avatar} />
                                                ) ||
                                                <Avatar className={classes.avatar}>{this.props.userData.letter}</Avatar>
                                            }
                                        </IconButton>
                                        <Menu
                                            id="menu-appbar"
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                            open={open}
                                            onClose={this.handleClose}
                                        >
                                            <MenuItem onClick={this.handleClose}>{this.props.userData.firstName !== '' && this.props.userData.lastName !== '' ? this.props.userData.firstName + ' ' + this.props.userData.lastName : this.props.userData.mail}</MenuItem>
                                            <LinkMenuItem className={classes.menuItem} onClick={this.handleClose} to="settings">
                                                <ListItemIcon className={classes.icon}>
                                                    <MyAccountIcon />
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} primary="Contul Meu" />
                                            </LinkMenuItem>
                                            { this.props.userData.subscription === 'pro' &&
                                                <LinkMenuItem className={classes.menuItem} onClick={this.handleClose} to="settings">
                                                    <ListItemIcon className={classes.icon}>
                                                        <CardIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText classes={{primary: classes.primary}} primary="Premium"/>
                                                </LinkMenuItem>
                                            }
                                            { this.props.userData.subscription === 'free' &&
                                                <LinkMenuItem className={classes.menuItem} onClick={this.handleClose} to="settings">
                                                    <ListItemIcon className={classes.icon}>
                                                        <CardIcon/>
                                                    </ListItemIcon>
                                                    <ListItemText classes={{primary: classes.primary}} primary="Fii Premium!"/>
                                                </LinkMenuItem>
                                            }
                                            <MenuItem className={classes.menuItem} onClick={this.handleLogout}>
                                                <ListItemIcon className={classes.icon}>
                                                    <LogoutIcon />
                                                </ListItemIcon>
                                                <ListItemText classes={{ primary: classes.primary }} primary="Logout" />
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                )}
                            </Toolbar>
                            { this.props.loading && <LinearProgress /> }
                        </AppBar>
                        <MiniDrawer open={this.state.drawerOpen} isAuthenticated={isAuthenticated} handleDrawerClose={this.handleDrawerClose}
                                    unfinishedTest={this.props.unfinishedTest}/>
                    </div>
                </ClickAwayListener>
                <EmailConfirmationDialog
                    open={isAuthenticated && !!this.props.userData.mailConfirmationPending}
                    generated={this.props.userData.mailConfirmationPending}
                    handleClose={this.handleDialogClose}
                />
                <TimerInfoDialog
                    open={this.state.timerInfoDialogOpen}
                    handleClose={this.handleDialogClose}
                    goPremium={this.goPremiumAction}
                />
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withWidth()(withStyles(styles, {withTheme: true})(MenuAppBar));