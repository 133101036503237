import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import lockedImage from '../images/locked-content.jpg'
import LinkButton from "./linkComponents/LinkButton";

const useStyles = makeStyles({
    card: {
        maxWidth: 700,
    },
    media: {
        height: 350,
    },
});

export default function MediaCard() {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardMedia
                className={classes.media}
                image={lockedImage}
                title="Imagine lacăt"
            />
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Nu ai acces
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Timpul tău a expirat. Te aşteptăm mâine pentru încă 15 minute de acces complet.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    Trebuie să ai cont premium pentru a putea continua!
                </Typography>
                {/*<Typography variant="body2" color="textSecondary" component="p">*/}
                {/*    <strong>Oferta!</strong> Oferă-ne un review şi noi îţi dăm o lună de cont premium gratis! Click pe butonul de mai jos pentru merge la pagina de review.*/}
                {/*</Typography>*/}
            </CardContent>
            <CardActions>
                <LinkButton to="/products">Fii Premium!</LinkButton>
            </CardActions>
        </Card>
    );
}