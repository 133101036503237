import * as types from './actionTypes';
import UtilsService from '../../services/utils'

export function getUtilsStructure() {
    return async(dispatch, getState) => {
        // dispatch loading start
        let token = getState().authentication.token;
        let data = await UtilsService.getUtilsStructure(token);
        if (data.error) {
            dispatch({type: types.STRUCTURE_LOAD_FAIL, errorMessage: data.rawData})
        }
        else {
            dispatch({type: types.STRUCTURE_LOAD_SUCCESS, utilsStructure: data.structure, termsData: data.termData });
        }
    }
}

export function getUtilsPage(id) {
    return async(dispatch, getState) => {
        // dispatch loading start
        let token = getState().authentication.token;
        let data = await UtilsService.getUtilsPage(token, id);
        if (data.error) {
            dispatch({type: types.UTILS_PAGE_LOAD_FAIL, errorMessage: data.rawData})
        }
        else {
            dispatch({type: types.UTILS_PAGE_LOAD_SUCCESS, utilsPageId: data.id, utilsPageData: data});
        }
    }
}