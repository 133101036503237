import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as authenticationActions from '../store/authentication/actions';
import * as authenticationSelectors from '../store/authentication/reducer';
import * as settingsSelectors from '../store/settings/reducer';
import LoginBox from "../components/LoginBox";
import RegisterBox from "../components/RegisterBox";
import Helmet from "react-helmet";
import Grid from "@material-ui/core/Grid";


class Login extends Component {

    state = {
        view: 'login',
    };

    submitHandler = (username, password) => {
        this.props.dispatch(authenticationActions.login({username: username, password: password}));
    };

    facebookLogin = (facebookData) => {
        this.props.dispatch(authenticationActions.facebookLogin({
            fbToken: facebookData.accessToken,
            fbUserId: facebookData.id,
        }));
    };

    registerHandler = (username, password) => {
        this.props.dispatch(authenticationActions.register({username: username, password: password}));
    };

    dismissMessages = () => {
        this.props.dispatch(authenticationActions.dismissMessages());
    };

    /**
     * Switches between login and register views.
     * @param view
     *   A string containing the view that needs to be displayed 'login'/'register';
     */
    handleSwitchView = (view) => {
        this.setState({view: view});
    }

    render() {
        const {formHasError, formErrorMessage, loginFailedAttempts} = this.props;
        return(
            <Grid
                container
                alignItems="center"
                justify="center"
                direction="row"
                style={{height: this.props.windowSize.height - 96, paddingTop: 10}}
            >
                <Helmet>
                    <title>Login - Grile Politie</title>
                </Helmet>
                <Grid item>
                {this.state.view === 'login' &&
                    <LoginBox
                        userLogin={this.submitHandler}
                        facebookLogin={this.facebookLogin}
                        error={formHasError}
                        errorMessage={formErrorMessage}
                        failedAttempts={loginFailedAttempts}
                        switchView={this.handleSwitchView}
                        dismissMessages={this.dismissMessages}
                    />
                }
                {this.state.view === 'register' &&
                    <RegisterBox
                        userRegister={this.registerHandler}
                        facebookLogin={this.facebookLogin}
                        error={formHasError}
                        errorMessage={formErrorMessage}
                        failedAttempts={loginFailedAttempts}
                        switchView={this.handleSwitchView}
                        dismissMessages={this.dismissMessages}
                    />
                }
                </Grid>
            </Grid>
        );
    }
}


function mapStateToProps(state) {
    const {error, errorMessage, failedAttempts} = authenticationSelectors.getError(state);
    return {
        formHasError: error,
        formErrorMessage: errorMessage,
        loginFailedAttempts: failedAttempts,
        windowSize: settingsSelectors.getWindowSize(state),
    };
}

export default connect(mapStateToProps)(Login);