import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import {QUESTION_COUNT_OPTIONS} from "../../Constants";
import QuestionsCountField from "../../form/QuestionsCountField";
import coverImage from "../../../images/testStart/chapters-1.jpg";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
        marginTop: theme.spacing(1),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    actions: {
        margin: theme.spacing(2),
    },
    dialogTitle: {
        backgroundImage: "url(" + coverImage + ")",
        backgroundSize: 'cover',
        height: '25vh',
    },
});

class ConfigureChaptersTestDialog extends React.Component {

    state = {
        name: '',
        questionsCount: 30,
        timeLimit: 30,
        subject: '',
        chapter: '',
        subChapter: '',
        subSubChapter: '',
        timing: false,
        selections: [],
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'subject') {
            this.setState({
                chapter: '',
                subChapter: '',
            });
        }
    };

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleNumberChange = number => {
        this.setState({questionsCount: number});
    };

    getSubjects = () => {
        let subjects = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'subject') {
                if (this.getChapters(this.props.testsTerms[key].tid).length > 0) {
                    subjects.push(this.props.testsTerms[key]);
                }
            }
        }
        return subjects;
    };

    getChapters = (subject = false) => {
        let chapters = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'chapter' && this.props.testsTerms[key].parent_id === '' && this.props.testsTerms[key].field_subject === (subject === false ? this.state.subject : subject)) {
                chapters.push(this.props.testsTerms[key]);
            }
        }
        return chapters;
    };

    getQuestionsCountForSubject = (subject = false) => {
        let count = 0;
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'chapter' && this.props.testsTerms[key].field_subject === subject) {
                count += parseInt(this.props.testsTerms[key].questions);
            }
        }
        return count;
    };

    getSubChapters = () => {
        let subChapters = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.state.chapter !== '' && this.props.testsTerms[key].parent_id === this.state.chapter) {
                subChapters.push(this.props.testsTerms[key]);
            }
        }
        return subChapters;
    };

    getSubSubChapters = () => {
        let subSubChapters = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.state.subChapter !== '' && this.props.testsTerms[key].parent_id === this.state.subChapter) {
                subSubChapters.push(this.props.testsTerms[key]);
            }
        }
        return subSubChapters;
    };

    handleDelete = chipToDelete => () => {
        this.setState({
            selections: this.state.selections.filter(chip => chip.key !== chipToDelete.key)
        });
    };

    getSelectedChapters() {
        let chapters = [];
        this.state.selections.map((chapter) => {
            chapters.push(chapter.key);
            return true;
        });
        return chapters;
    }

    handleAdd = () => {
        let chapter;
        if (this.state.chapter !== '') {
            if (this.state.subSubChapter !== '') {
                chapter = this.state.subSubChapter;
            } else if (this.state.subChapter !== '') {
                chapter = this.state.subChapter;

            } else {
                chapter = this.state.chapter;
            }

            if (!this.getSelectedChapters().includes(chapter)) {
                this.setState({
                    selections: [...this.state.selections, {
                        key: chapter,
                        label: this.props.termsByTid[chapter].name.substring(0, 35) + (this.props.termsByTid[chapter].name.length > 36 ? '...' : ''),
                    }],
                    subSubChapter: '',
                    subChapter: '',
                    chapter: '',
                });
            }
        }
    };

    handleStartTest = () => {
        // Start test with selected terms.
        this.props.handleStart({
            type: 'chapters',
            ...this.state
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen={this.props.isFull}
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialogTitle} id="form-dialog-title"/>
                    <DialogTitle id="form-dialog-title">Configurează pe Capitole</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Configurează-ți propriul test, pe Capitole
                        </DialogContentText>
                        <TextField
                            onChange={this.handleChange}
                            margin="dense"
                            name="name"
                            id="name"
                            label="Nume Test"
                            defaultValue={"Test " + this.props.nextTestId}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <QuestionsCountField
                            handleChange={this.handleNumberChange}
                            variant="outlined"
                            default={this.state.questionsCount}
                            questionCountOptions={QUESTION_COUNT_OPTIONS}
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="subject">Materie</InputLabel>
                            <Select
                                value={this.state.subject}
                                label="Materie"
                                onChange={this.handleChange}
                                inputProps={{
                                    name: 'subject',
                                    id: 'subject',
                                }}
                            >
                                <MenuItem value="">
                                    <em>Toate</em>
                                </MenuItem>
                                {this.getSubjects().map(subject => {
                                    return (
                                        <MenuItem key={subject.tid} value={subject.tid}>{subject.name} ({this.getQuestionsCountForSubject(subject.tid)})</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {
                            this.getChapters().length > 0 &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="chapter">Capitol</InputLabel>
                                <Select
                                    value={this.state.chapter}
                                    label="Capitol"
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'chapter',
                                        id: 'chapter',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Toate</em>
                                    </MenuItem>
                                    {this.getChapters().map(chapter => {
                                        return (
                                            <MenuItem key={chapter.tid} value={chapter.tid}>{chapter.name} ({chapter.questions > 0 ? chapter.questions : "vezi sub-capitole"})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.getSubChapters().length > 0 &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="chapter">Sub-Capitol</InputLabel>
                                <Select
                                    value={this.state.subChapter}
                                    label="Sub-Capitol"
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'subChapter',
                                        id: 'subChapter',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Toate</em>
                                    </MenuItem>
                                    {this.getSubChapters().map(subChapter => {
                                        return (
                                            <MenuItem key={subChapter.tid} value={subChapter.tid}>{subChapter.name} ({subChapter.questions > 0 ? subChapter.questions : "vezi sub-capitole"})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.getSubSubChapters().length > 0 &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="chapter">Sub-Sub-Capitol</InputLabel>
                                <Select
                                    value={this.state.subSubChapter}
                                    label="Sub-Sub-Capitol"
                                    onChange={this.handleChange}
                                    inputProps={{
                                        name: 'subSubChapter',
                                        id: 'subSubChapter',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Toate</em>
                                    </MenuItem>
                                    {this.getSubSubChapters().map(subChapter => {
                                        return (
                                            <MenuItem key={subChapter.tid} value={subChapter.tid}>{subChapter.name} ({subChapter.questions})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.selections.length > 0 &&
                            <>
                                <Divider className={classes.divider} />
                                <DialogContentText>
                                    Mai poţi adăuga capitole, sau poţi începe testul cu selecţia curentă:
                                </DialogContentText>
                            </>
                        }
                        {this.state.selections.map(data => {
                            return <Chip
                                key={data.key}
                                label={data.label}
                                onDelete={this.handleDelete(data)}
                                className={classes.chip}
                            />
                        })
                        }
                        {
                            this.state.selections.length > 0 &&
                            <>
                                <Divider className={classes.divider} />
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.timing}
                                                onChange={this.handleCheckboxChange}
                                                inputProps={{
                                                    name: 'timing',
                                                    id: 'timing',
                                                }}
                                            />
                                        }
                                        label="Contratimp"
                                    />

                                </FormControl>
                                {
                                    this.state.timing &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel htmlFor="timeLimit">Vei avea un timp limitat pentru a rezolva toate întrebarile testului.</InputLabel>
                                        <Select
                                            value={this.state.timeLimit}
                                            label="Vei avea un timp limitat pentru a rezolva toate întrebarile testului."
                                            onChange={this.handleChange}
                                            inputProps={{
                                                name: 'timeLimit',
                                                id: 'timeLimit',
                                            }}
                                        >
                                            <MenuItem value={30}>30 de minute</MenuItem>
                                            <MenuItem value={60}>O oră</MenuItem>
                                            <MenuItem value={90}>90 de minute</MenuItem>
                                            <MenuItem value={120}>Două ore</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                        {
                            this.state.chapter !== "" &&
                            <Button variant="contained" onClick={this.handleAdd} color="primary">
                                Adaugă selecţia
                            </Button>
                        }
                        {
                            this.state.selections.length > 0 &&
                            <Button variant="contained" onClick={this.handleStartTest} color="primary">
                                Începe Test
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ConfigureChaptersTestDialog);