import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Checkbox, FormControlLabel, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {QUESTION_COUNT_OPTIONS} from "../../Constants";
import QuestionsCountField from "../../form/QuestionsCountField";
import coverImage from "../../../images/testStart/previous-years.jpg"

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
        marginTop: theme.spacing(1),
    },
    dialogTitle: {
        backgroundImage: "url(" + coverImage + ")",
        backgroundSize: 'cover',
        height: '25vh',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    actions: {
        margin: theme.spacing(2),
    },
});

class ConfigureYearsTestDialog extends React.Component {

    state = {
        name: '',
        questionsCount: 30,
        timeLimit: 30,
        timing: false,
        subject: '',
        school: '',
        subCategory: '',
        variant: '',
    };

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        switch (event.target.name) {
            case 'subject':
                this.setState({
                    school: '',
                    subCategory: '',
                    variant: '',
                });
                break;

            case 'school':
                this.setState({
                    subCategory: '',
                    variant: '',
                });
                break;

            case 'subCategory':
                this.setState({
                    variant: '',
                });
                break;

            default:
                break;
        }

    };

    handleCheckboxChange = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    handleNumberChange = number => {
        this.setState({questionsCount: number});
    };

    getSubjects = () => {
        let subjects = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'subject') {
                subjects.push(this.props.testsTerms[key]);
            }
        }
        return subjects;
    };

    getSchools = () => {
        let subject = this.state.subject;
        let schools = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'scoli'
                && this.props.termsYearsStructure[subject] !== undefined
                && this.props.termsYearsStructure[subject]['children'][this.props.testsTerms[key].tid]) {
                schools.push(this.props.testsTerms[key]);
            }
        }
        return schools;
    };

    getSubCategories = () => {
        let subCategories = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'sub_category'
                && this.props.termsYearsStructure[this.state.subject] !== undefined
                && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school] !== undefined
                && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.props.testsTerms[key].tid] !== undefined) {
                subCategories.push(this.props.testsTerms[key]);
            }
        }
        return subCategories;
    };

    getVariants = () => {
        let variants = [];
        for (let key = 0; key < this.props.testsTerms.length; key++) {
            if (this.props.testsTerms[key].vid === 'exam_variant'
                && this.props.termsYearsStructure[this.state.subject] !== undefined
                && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school] !== undefined
                && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory] !== undefined) {
                if (this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory]['children'][this.props.testsTerms[key].tid] !== undefined) {
                    variants.push(this.props.testsTerms[key]);
                }
            }
        }
        return variants;
    };

    getNoVariantQuestionsCount = () => {
        if (this.props.termsYearsStructure[this.state.subject] !== undefined
            && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school] !== undefined
            && this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory] !== undefined) {
            if (this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory]['children']['no-variant'] !== undefined) {
                return this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory]['children']['no-variant'];
            }
        }
        return 0;
    };

    handleStartTest = () => {
        this.props.handleStart({
            type: 'prevYears',
            ...this.state
        });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                <Dialog
                    fullScreen={this.props.isFull}
                    open={this.props.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle className={classes.dialogTitle} id="form-dialog-title"/>
                    <DialogTitle id="form-dialog-title">Alege din anii precedenţi</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Alege un test dat în anii precedenţi, să vezi cum te-ai fi descurcat!
                        </DialogContentText>
                        <TextField
                            onChange={this.handleChange}
                            margin="dense"
                            name="name"
                            id="name"
                            label="Nume Test"
                            defaultValue={"Test " + this.props.nextTestId}
                            type="text"
                            variant="outlined"
                            fullWidth
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel htmlFor="subject">Materie</InputLabel>
                            <Select
                                value={this.state.subject}
                                onChange={this.handleChange}
                                label="Materie"
                                inputProps={{
                                    name: 'subject',
                                    id: 'subject',
                                }}
                            >
                                <MenuItem value="">
                                    <em>- Alege Materia -</em>
                                </MenuItem>
                                {Object.values(this.props.termsYearsStructure).length > 0 && this.getSubjects().map(subject => {
                                    return (
                                        <MenuItem key={subject.tid} value={subject.tid}>{subject.name} ({this.props.termsYearsStructure[subject.tid]['questionsCount']})</MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        {
                            this.state.subject !== ''  &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="sub-category">Alege Şcoala</InputLabel>
                                <Select
                                    value={this.state.school}
                                    onChange={this.handleChange}
                                    label="Alege Şcoala"
                                    inputProps={{
                                        name: 'school',
                                        id: 'school',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>- Alege şcoala -</em>
                                    </MenuItem>
                                    {this.getSchools().map(school => {
                                        return (
                                            <MenuItem key={school.tid} value={school.tid}>{school.name} ({this.props.termsYearsStructure[this.state.subject]['children'][school.tid]['questionsCount']})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.subject !== '' && this.state.school !== '' &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="sub-category">Alege Anul</InputLabel>
                                <Select
                                    value={this.state.subCategory}
                                    onChange={this.handleChange}
                                    label="Alege Anul"
                                    inputProps={{
                                        name: 'subCategory',
                                        id: 'sub-category',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>Toate</em>
                                    </MenuItem>
                                    {this.getSubCategories().map(subCategory => {
                                        return (
                                            <MenuItem key={subCategory.tid} value={subCategory.tid}>{subCategory.name} ({this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][subCategory.tid]['questionsCount']})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.subject !== '' && this.state.school !== '' && this.state.subCategory !== '' && this.getVariants().length > 0 &&
                            <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="variant">Varianta</InputLabel>
                                <Select
                                    value={this.state.variant}
                                    onChange={this.handleChange}
                                    label="Varianta"
                                    inputProps={{
                                        name: 'variant',
                                        id: 'variant',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>- Alege -</em>
                                    </MenuItem>
                                    {this.getVariants().map(variant => {
                                        return (
                                            <MenuItem key={variant.tid} value={variant.tid}>{variant.name} ({this.props.termsYearsStructure[this.state.subject]['children'][this.state.school]['children'][this.state.subCategory]['children'][variant.tid]})</MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        }
                        {
                            this.state.subject !== '' && this.state.school !== '' && this.state.subCategory !== '' && this.getVariants().length === 0 &&
                                <>
                                    <Divider className={classes.divider}/>
                                    <Typography>Există {this.getNoVariantQuestionsCount()} întrebări din anul ales de tine. Ele nu sunt împărţite pe variante.</Typography>
                                    <QuestionsCountField
                                        handleChange={this.handleNumberChange}
                                        default={this.state.questionsCount}
                                        questionCountOptions={QUESTION_COUNT_OPTIONS}
                                        limit={this.getNoVariantQuestionsCount()}
                                    />
                                </>
                        }
                        {
                            <>
                                <FormControl variant="outlined">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.timing}
                                                onChange={this.handleCheckboxChange}
                                                inputProps={{
                                                    name: 'timing',
                                                    id: 'timing',
                                                }}
                                            />
                                        }
                                        label="Contratimp"
                                    />

                                </FormControl>
                                {
                                    this.state.timing &&
                                    <FormControl variant="outlined" className={classes.formControl}>
                                        <InputLabel id="timeLimit">Vei avea un timp limitat pentru a rezolva toate întrebarile testului.</InputLabel>
                                        <Select
                                            value={this.state.timeLimit}
                                            onChange={this.handleChange}
                                            label="Vei avea un timp limitat pentru a rezolva toate întrebarile testului."
                                            inputProps={{
                                                name: 'timeLimit',
                                                id: 'timeLimit',
                                            }}
                                        >
                                            <MenuItem value={30}>30 de minute</MenuItem>
                                            <MenuItem value={60}>O oră</MenuItem>
                                            <MenuItem value={90}>90 de minute</MenuItem>
                                            <MenuItem value={120}>Două ore</MenuItem>
                                        </Select>
                                    </FormControl>
                                }
                            </>
                        }
                    </DialogContent>
                    <DialogActions className={classes.actions}>
                        <Button variant="contained" onClick={this.props.handleClose} color="primary">
                            Închide
                        </Button>
                        {
                            ((this.state.subject !== '' && this.state.school !== '' && this.state.subCategory !== '' && this.getVariants().length === 0)
                            || (this.state.subject !== '' && this.state.school !== '' && this.state.subCategory !== '' && this.state.variant !== '' )) &&
                            <Button variant="contained" onClick={this.handleStartTest} color="primary">
                                Începe Test
                            </Button>
                        }
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(ConfigureYearsTestDialog);