import * as types from './actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    userReviews: [],
});


export default function reduce(state = initialState, action = {}) {

    switch (action.type) {
        case types.REVIEW_ADD_FAIL:
        case types.REVIEW_LOAD_FAIL:
            return state;

        case types.REVIEW_ADD_SUCCESS:
        case types.REVIEW_LOAD_SUCCESS:
            return state.merge({userReviews: action.userReviews});

        default:
            return state;
    }
}

export function getUserReviews(state) {
    return state.reviews.userReviews;
}