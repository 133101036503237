import React from 'react';
import {Grid, withStyles} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {MONTH_NAMES} from "../Constants";

const styles = theme => ({
    monthChanger: {
        width: '10px',
    },
});

class MonthSelector extends React.Component {

    state = {
        month: 4,
        year: 2020,
    }

    componentDidMount() {
        this.handleMonthChange('current');
    }

    checkDisabled = (direction) => {
        const d = new Date();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        if (direction === 'next') {
            if (this.state.month === month && this.state.year === year) {
                return true;
            }
        }
        else {
            if (this.state.month === 4 && this.state.year === 2020) {
                return true;
            }
        }
        return false;
    }

    handleMonthChange = (direction) => {
        let newDate = {
            month: this.state.month,
            year: this.state.year,
        };
        switch (direction) {
            case 'next':
                if (this.state.month === 12) {
                    newDate = {
                        month: 1,
                        year: this.state.year + 1,
                    };
                } else {
                    newDate = {
                        ...newDate,
                        month: this.state.month + 1,
                    };
                }
                break;

            case 'prev':
                if (this.state.month === 1) {
                    newDate = {
                        month: 12,
                        year: this.state.year - 1,
                    };
                } else {
                    newDate = {
                        ...newDate,
                        month: this.state.month - 1,
                    };
                }
                break;

            default:
                const d = new Date();
                newDate = {
                    month: d.getMonth() + 1,
                    year: d.getFullYear(),
                };
                break;
        }
        this.setState({
            month: newDate.month,
            year: newDate.year,
        });
        newDate.month = newDate.month < 10 ? "0" + newDate.month : newDate.month
        this.props.handleMonthChange(newDate);
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid item xs={12}>
                <ButtonGroup fullWidth className={classes.monthSelector}>
                    <Button className={classes.monthChanger} disabled={this.checkDisabled('prev')} onClick={() => this.handleMonthChange('prev')}>{'<'}</Button>
                    <Button>{MONTH_NAMES[this.state.month]} {this.state.year}</Button>
                    <Button className={classes.monthChanger} disabled={this.checkDisabled('next')} onClick={() => this.handleMonthChange('next')}>{'>'}</Button>
                </ButtonGroup>
            </Grid>
        );
    }
}

export default withStyles(styles)(MonthSelector);