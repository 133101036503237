import React from 'react';
import {CardHeader, Grid, Typography, withStyles} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import TotalChart from "./TotalChart";
import Card from "@material-ui/core/Card";
import CountChart from "./CountChart";
import CircularLoaderContent from "../CircularLoaderContent";
import ChartFilter from "./ChartFilter";
import LinkButton from "../linkComponents/LinkButton";
import MonthSelector from "../pageParts/MonthSelector";
import {STATISTICS_UNAVAILABLE} from "../Constants";

const styles = theme => ({
    monthSelector: {
        marginBottom: 16,
    },
    media: {
        height: 350,
    },
});

class ChartWrapper extends React.Component {
    state = {
        month: "01",
        year: 2020,
        subject: '',
        chapter: '',
    }

    handleMonthChange = (newDate) => {
        this.setState(newDate);
    };

    getDatesData() {
        const d = new Date();
        const year = this.state.year;
        const month = this.state.month;
        let day;
        if (d.getMonth() + 1 === this.state.month) {
            // Current month selected, so last day in graph should be today.
            day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
        }
        else {
            // Another month selected so last day of month needs to be returned.
            const date = new Date(year, month, 0);
            day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        }
        return {
            year: year,
            month: month,
            day: day,
        }
    }

    getFilteredStatistics(type) {
        const { subject, chapter } = this.state;
        const { statistics } = this.props;
        if (subject !== '') {
            if (chapter !== '') {
                return statistics[type].subjects[subject].chapters[chapter];
            }
            return statistics[type].subjects[subject];
        }
        return statistics[type];
    }

    calculateGrade(timeline, startDate, endDate) {
        let correct = 0;
        let all = 0
        const endDateDate = new Date(endDate);
        const startDateDate = new Date(startDate);
        for (let day in timeline) {
            const dayDate = new Date(day);
            if (dayDate < startDateDate) {
                continue;
            }
            if (dayDate > endDateDate) {
                break;
            }
            correct += timeline[day].correct;
            all += timeline[day].all;
            if (day === endDate) {
                break;
            }
        }
        return Math.round((correct * 100 / all) * 100) / 100;
    }

    getChartData(type, chartType = 'gradesChart') {
        const statistics = this.getFilteredStatistics(type);
        const {year, month, day} = this.getDatesData();
        let data = [];
        for (let i = 1; i <= day; i++) {
            let chartDay = i < 10 ? "0" + i : i;
            let currentDayStats = statistics.timeline[year + "/" + month + "/" + chartDay];
            if (chartType === 'user_questions_count') {
                if (currentDayStats !== undefined) {
                    data.push(currentDayStats.all);
                } else {
                    data.push(0);
                }
            }
            else {
                const grade = this.calculateGrade(statistics.timeline, year + "/" + month + "/01", year + "/" + month + "/" + chartDay);
                data.push(grade);
            }
        }
        return data;
    }

    getUserData() {
        return this.getChartData("user_statistics");
    }

    getGeneralData() {
        return this.getChartData("general_statistics");
    }

    getQuestionsCountData() {
        return this.getChartData("user_statistics", "user_questions_count");
    }

    handleFilterChange = (data) => {
        this.setState(data);
    }

    render() {
        const { classes, type, withFilter, withReference } = this.props;
        let loading = !this.props.statistics.user_statistics;
        return (
                <Card className={classes.card}>
                    {this.props.title !== undefined &&
                        <CardHeader
                            title={this.props.title}
                        />
                    }
                    {STATISTICS_UNAVAILABLE &&
                        <CardContent>
                            <Typography>Ne cerem scuze, statisticile nu funcţionează momentan. Lucrăm la remedierea acestei situaţii.</Typography>
                        </CardContent>
                    }
                    {loading && !STATISTICS_UNAVAILABLE &&
                        <CircularLoaderContent/>
                    }
                    {!loading && !STATISTICS_UNAVAILABLE &&
                    <CardContent>
                            <Grid container spacing={2}>
                                <MonthSelector handleMonthChange={this.handleMonthChange} />
                                {withReference &&
                                    <Grid item>
                                        <LinkButton variant="contained" to="/statistics">Detalii</LinkButton>
                                    </Grid>
                                }
                                {withFilter &&
                                    <Grid item>
                                        <ChartFilter statistics={this.props.statistics}
                                                     termsData={this.props.termsData}
                                                     handleChange={this.handleFilterChange}/>
                                    </Grid>
                                }
                            </Grid>
                            {type === 'gradesEvolution' &&
                            <TotalChart
                                userData={this.getUserData()}
                                generalData={this.getGeneralData()}
                                month={this.state.month}
                                year={this.state.year}
                            />
                            }
                            {type === 'questionsCountEvolution' &&
                            <CountChart
                                userData={this.getQuestionsCountData()}
                                month={this.state.month}
                                year={this.state.year}
                            />
                            }
                        </CardContent>
                    }
                </Card>
        );
    }
}

export default withStyles(styles)(ChartWrapper);