import React from 'react';
import { makeStyles } from '@material-ui/core/styles/index';
import Card from '@material-ui/core/Card/index';
import CardActionArea from '@material-ui/core/CardActionArea/index';
import CardContent from '@material-ui/core/CardContent/index';
import CardMedia from '@material-ui/core/CardMedia/index';
import Typography from '@material-ui/core/Typography/index';
import privacyPolicyImage from '../../images/legal/privacy-policy.jpg';

const API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
    card: {
        // maxWidth: 700,
        // minWidth: 500,
    },
    media: {
        height: 350,
    },
});

export default function MediaCard() {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea href={`${API_URL}/privacy-policy`}>
                <CardMedia
                    className={classes.media}
                    image={privacyPolicyImage}
                    title="Privacy Policy"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        Politica de confidenţialitate
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        Citeşte politica de confidenţialitate, mergând pe site-ul aplicaţiei şi accesând pagina destinată.
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}