import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as authenticationActions from '../store/authentication/actions';
import * as authenticationSelectors from "../store/authentication/reducer";
import * as settingsSelectors from "../store/settings/reducer";
import * as testsSelectors from "../store/tests/reducer";
import * as bugsSelectors from "../store/bugs/reducer";
import MenuAppBar from "../components/navigation/MenuAppBar";


class AppNavigation extends Component {

    constructor(props) {
        super(props);
        if (this.props.isAuthenticated) {
            this.props.dispatch(authenticationActions.getUserDetails(this.props.token));
        }
    }

    logout = () => {
        this.props.dispatch(authenticationActions.logout());
    };

    render() {
        const {isAuthenticated, userData, loading, unfinishedTest, selectedExam} = this.props;
        return (
            <div>
                <MenuAppBar
                    isAuthenticated={isAuthenticated}
                    userData={userData}
                    logout={this.logout}
                    loading={loading}
                    unfinishedTest={unfinishedTest}
                    selectedExam={selectedExam}
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isAuthenticated: authenticationSelectors.getIsAuthenticated(state),
        token: authenticationSelectors.getAccessToken(state),
        userData: authenticationSelectors.getUserData(state),
        loading: authenticationSelectors.getLoading(state) || testsSelectors.getLoading(state) ||bugsSelectors.getLoading(state),
        unfinishedTest: testsSelectors.getUnfinishedTest(state),
        selectedExam: settingsSelectors.getSelectedExam(state),
    }
}

export default connect(mapStateToProps)(AppNavigation);