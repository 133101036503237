import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        // margin: theme.spacing.unit,
        minWidth: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    actions: {
        margin: theme.spacing(2),
    },
});

class TimerInfoDialog extends React.Component {

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={this.props.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Timpul tău este limitat
                    </DialogTitle>
                    <DialogContent>
                        <Typography>Încă nu ţi-ai făcut cont premium.</Typography>
                        <Typography>Activează contul premium pentru acces nelimitat!</Typography>

                    </DialogContent>
                    <DialogActions className={this.props.classes.actions}>
                        <Button variant="contained" onClick={this.props.goPremium} color="primary">
                            Fii premium!
                        </Button>
                        <Button variant="contained" onClick={this.props.handleClose} color="default">
                            Închide
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(styles)(TimerInfoDialog);