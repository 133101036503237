import React from 'react';
import {withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import csc from "country-state-city";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    formControl: {
        width: '100%',
    },
});

class NewAddressDialog extends React.Component {

    state = {
        county: '',
        city: '',
        phone: '',
        streetAddress: '',
    };

    handleClose = () => {
        this.props.closeDialog();
    }

    validatePhone = phone => {
        let re = /^[0-9]*$/;
        return re.test(phone);

    }

    handleChange = event => {
        if (event.target.name === 'phone' && !this.validatePhone(event.target.value)) {
            return;
        }
        this.setState({[event.target.name]: event.target.value});
        if (event.target.name === 'county') {
            this.setState({city: ''});
        }
    }

    handleSubmit = () => {
        const stateProvince = csc.getStateById(this.state.county);
        const city = csc.getCityById(this.state.city);
        this.props.saveAddress({
            country: "România",
            stateProvince: stateProvince.name,
            city: city.name,
            streetAddress: this.state.streetAddress,
            phoneNumber: this.state.phone,
        });
    }

    validForm = () => {
        return !(this.state.county === '' || this.state.city === '' || this.state.phone.length < 10 || this.state.streetAddress === '');
    }

    getCounties = () => {
        const country = csc.getCountryByCode('RO');
        return csc.getStatesOfCountry(country.id);
    }

    getCities = () => {
        return csc.getCitiesOfState(this.state.county);
    }

    render() {
        const {classes} = this.props;
        return (
            <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adaguare Adresă</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Adaugă o nouă adresă de facturare.
                    </DialogContentText>
                    <FormControl className={classes.formControl}>
                        <TextField
                            margin="dense"
                            id="country"
                            label="Ţara"
                            type="text"
                            value="România"
                            disabled
                            fullWidth
                        />
                    </FormControl>
                    <FormControl required className={classes.formControl}>
                        <InputLabel id="county">Judeţ</InputLabel>
                        <Select
                            value={this.state.county}
                            onChange={this.handleChange}
                            labelId="county"
                            inputProps={{
                                name: 'county',
                                id: 'county',
                            }}
                        >
                            {this.getCounties().map(county => {
                                return <MenuItem key={county.id} value={county.id}>{county.name}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    {
                        this.state.county !== '' &&
                        <FormControl className={classes.formControl}>
                            <InputLabel id="city">Oraş</InputLabel>
                            <Select
                                value={this.state.city}
                                onChange={this.handleChange}
                                labelId="city"
                                inputProps={{
                                    name: 'city',
                                    id: 'city',
                                }}
                            >
                                {this.getCities().map(city => {
                                    return <MenuItem key={city.id} value={city.id}>{city.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    }
                    <FormControl required className={classes.formControl}>
                        <TextField
                            margin="dense"
                            inputProps={{
                                name: 'streetAddress',
                                id: 'streetAddress',
                            }}
                            id="country"
                            required
                            label="Adresă (stradă, număr, bloc, apartament)"
                            type="text"
                            value={this.state.streetAddress}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            margin="dense"
                            id="phone"
                            required
                            inputProps={{
                                name: 'phone',
                                id: 'phone',
                            }}
                            label="Telefon"
                            type="text"
                            value={this.state.phone}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary">
                        Renunţă
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" disabled={!this.validForm()}>
                        Salvează
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(styles)(NewAddressDialog);