import React from 'react';
import {connect} from 'react-redux';
import * as paymentSelectors from '../../store/payment/reducer';
import * as paymentActions from '../../store/payment/actions';
import { Grid, withStyles} from "@material-ui/core";
import queryString from 'query-string';
import Typography from "@material-ui/core/Typography";
import Helmet from "react-helmet";
import Paper from "@material-ui/core/Paper";
import Order from "../../components/payment/dialogs/Order";

const styles = theme => ({
    root: {},
});

class OrderFinished extends React.Component {

    componentDidMount() {
        this.props.dispatch(paymentActions.getUserOrders());
    }

    render() {
        const {orders, classes} = this.props;
        const params = queryString.parse(this.props.location.search);
        let order = orders[params.orderId];
        return (
            <Grid container justify="center" className={classes.root} spacing={2}>
                <Helmet>
                    <title>Comanda Confirmata - Grile Politie</title>
                </Helmet>
                { orders.length === 0 &&
                    <Grid item xs={12}>
                        <Paper className={classes.centered}><Typography variant="body1">Nu ai nicio comandă.</Typography></Paper>
                    </Grid>
                }
                { orders.length !== 0 &&
                    <Order order={order} />
                }
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        orders: paymentSelectors.getUserOrders(state),
    };
}

export default connect(mapStateToProps)(withStyles(styles)(OrderFinished));