import React from 'react';
import {Route, Switch} from "react-router-dom";
import InfoPage from "./InfoPage";
import Utils from "../containers/Utils";
import Homepage from "../containers/Homepage";
import Login from "../containers/Login";
import RegistrationStepTwo from "../containers/RegistrationStepTwo";
import Bugs from "../containers/Bugs";
import Settings from "../containers/Settings";
import Orders from "../containers/payment/Orders";
import ExamSelection from "../containers/ExamSelection";
import Review from "../containers/Review";
import Products from "../containers/payment/Products";
import Addresses from "../containers/payment/Addresses";
import OrderSummary from "../containers/payment/OrderSummary";
import OrderFinished from "../containers/payment/OrderFinished";
import Tests from "../containers/Tests";
import Test from "../containers/Test";
import Statistics from "../containers/Statistics";
import Lessons from "../containers/Lessons";
import NoAccess from "./NoAccess";

class CustomRouter extends React.Component {

    hasAccess() {
        const { proAccess, selectedExam, subscriptions, isUserPremium } = this.props;
        if (!isUserPremium && proAccess) {
            return true;
        }
        return subscriptions.includes(selectedExam) && proAccess;
    }

    render() {
        const { isAuthenticated, userHasName, selectedExam } = this.props;
        let routes = [
            { path: '/contact', component: InfoPage },
            { path: '/utils', component: Utils, exact: true },
            { path: '/utils/:id', component: Utils },
            { path: '/homepage', component: Homepage },
        ];
        if (!isAuthenticated) {
            routes = routes.concat([
                { path: '/login', component: Login, exact: true, },
                { path: '/', component: Homepage },
            ]);
        }
        if (isAuthenticated && !userHasName) {
            routes = routes.concat([
                { path: '/', component: RegistrationStepTwo },
            ]);
        }
        if (isAuthenticated && userHasName && selectedExam === 'notSelected') {
            routes = routes.concat([{ path: '/', component: ExamSelection }])
        }
        if (isAuthenticated && userHasName && selectedExam !== 'notSelected') {

            routes = routes.concat([
                { path: '/bugs', component: Bugs, exact: true },
                { path: '/settings/orders', component: Orders, exact: true },
                { path: '/settings', component: Settings },
                { path: '/exam', component: ExamSelection },
                { path: '/review', component: Review },
                { path: '/products', component: Products },
                { path: '/addresses', component: Addresses },
                { path: '/order', component: OrderSummary },
                { path: '/order-finished', component: OrderFinished },
            ]);
            if (isAuthenticated && userHasName && this.hasAccess()) {
                routes = routes.concat([
                    { path: '/tests', component: Tests, exact: true },
                    { path: '/tests/:type/:view/:id', component: Test },
                    { path: '/tests/:type/:view', component: Test },
                    { path: '/tests/:type/:view', component: Test },
                    { path: '/statistics', component: Statistics },
                    { path: '/lessons', component: Lessons, exact: true },
                    { path: '/', component: Tests },
                ]);
            }
            if (isAuthenticated && userHasName && !this.hasAccess()) {
                routes = routes.concat([
                    { path: '/tests', component: NoAccess },
                    { path: '/statistics', component: NoAccess },
                    { path: '/lessons', component: NoAccess },
                    { path: '/', component: NoAccess },
                ]);
            }
        }
        return (
            <Switch>
                {routes.map(route => {
                    return <Route key={route.path} path={route.path} component={route.component} exact={route.exact} />;
                })}
            </Switch>
        );
    }
}

export default CustomRouter;