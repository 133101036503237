import React from 'react';
import {Bar} from 'react-chartjs-2';

const options = {
    responsive: true,
    tooltips: {
        mode: 'label'
    },
    scales: {
        yAxes: [{
            ticks: {
                suggestedMin: 0,
                suggestedMax: 100,
            }
        }],
        xAxes: [{
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, values) {
                    return index + 1;
                }
            }
        }],
    }
};

class TotalChart extends React.Component {

    dateLabel(i) {
        const month = this.props.month < 10 ? "0" + this.props.month : this.props.month;
        const day = i < 10 ? "0" + i : i;
        return day + "/" + month + "/" + this.props.year;
    }

    render() {
        const data = {
            labels: [ ...Array(this.props.userData.length).keys() ].map( i => this.dateLabel(i+1)),
            datasets: [
                {
                    label: 'Număr întrebări',
                    backgroundColor: 'rgba(75,192,192,0.2)',
                    borderColor: 'rgb(75,192,192)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(75,118,192,0.4)',
                    hoverBorderColor: 'rgb(75,118,192)',
                    data: this.props.userData
                },
            ],
        };
        return (
            <Bar
                data={data}
                options={options}
            />
        );
    }
}

export default TotalChart;